import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './ExpandedCalender.scss';

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DAYS = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'];
function formatMonth(date) {
  return MONTHS[date.getMonth()];
}
function formatShortWeekday(date) {
  return DAYS[date.getDay()];
}
function formatMonthYear(date) {
  return `${formatMonth(date)} ${date.getFullYear()}`;
}

function ExpandedCalender({
  onChange,
  value,
  allowPartialRange = false,
  selectRange = false,
  returnValue = 'start',
  maxDate,
  ...rest
}) {
  return (
    < div className='row' {...rest}>
      <div className='col expandedCalender'>
        <Calendar
          onChange={onChange}
          value={value}
          formatMonth={(locale, date) => formatMonth(date)}
          formatMonthYear={(locale, date) => formatMonthYear(date)}
          maxDetail='decade'
          minDetail='decade'
          maxDate={maxDate}
          className={['c1', 'calendar-decade']}
          allowPartialRange={allowPartialRange}
          returnValue={returnValue}
          selectRange={selectRange}
        />
      </div>
      <div className='col expandedCalender'>
        <Calendar
          onChange={onChange}
          value={value}
          formatMonth={(locale, date) => formatMonth(date)}
          formatMonthYear={(locale, date) => formatMonthYear(date)}
          maxDetail='year'
          minDetail='year'
          maxDate={maxDate}
          className={['c1', 'c2']}
          allowPartialRange={allowPartialRange}
          returnValue={returnValue}
          selectRange={selectRange}
        />
      </div>
      <div className='col expandedCalender'>
        <Calendar
          onChange={onChange}
          value={value}
          formatMonth={(locale, date) => formatMonth(date)}
          formatMonthYear={(locale, date) => formatMonthYear(date)}
          formatShortWeekday={(locale, date) => formatShortWeekday(date)}
          maxDetail='month'
          minDetail='month'
          maxDate={maxDate}
          className={['c1', 'calendar-month']}
          allowPartialRange={allowPartialRange}
          returnValue={returnValue}
          selectRange={selectRange}
        />
      </div>
    </div >
  );
}

ExpandedCalender.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  allowPartialRange: PropTypes.bool,
  selectRange: PropTypes.bool,
  returnValue: PropTypes.string,
  maxDate: PropTypes.object,
};

export default memo(ExpandedCalender);
