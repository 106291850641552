import PropTypes from 'prop-types';

import React, { useMemo } from 'react';

import RatingOption from '../player-page/RatingOption';
import RatingTitle from '../player-page/RatingTitle';
import SelectableDropdown from './SelectableDropdown';

const ITEM_PROPS = {
  closeOnClick: false,
  className: 'no-deco',
};

export const DARK_TOGGLER = {
  className: 'btn btn-dark mr-2',
};

function RatingsDropdown({
  allRatings = [],
  title = RatingTitle,
  value,
  toggle,
  ...rest
}) {
  const toggleProps = useMemo(
    () => ({
      className: 'btn btn-light mr-2',
      style: { width: 165 },
      title: value.join(','),
      ...toggle,
    }),
    [value]
  );
  return (
    <SelectableDropdown
      title={title}
      renderItem={RatingOption}
      name='ratings'
      value={value}
      itemProps={ITEM_PROPS}
      options={allRatings}
      toggle={toggleProps}
      {...rest}
    />
  );
}

RatingsDropdown.propTypes = {
  allRatings: PropTypes.array,
  toggle: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.any,
};

export default React.memo(RatingsDropdown);
