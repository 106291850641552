import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { STATUSES, getActionType } from 'store/middlewares/asyncActions';
export default function reducer(
  state = initialState.resource,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.DOWNLOAD, STATUSES.REQUEST):
      return {
        ...state,
        download: {
          ...state.download,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.UPLOAD, STATUSES.REQUEST):
      return {
        ...state,
        upload: {
          ...state.upload,
          status: actionTypes.LOADING_STATUS,
        },
      };

    case getActionType(actionTypes.DOWNLOAD, STATUSES.SUCCESS):
      return {
        ...state,
        download: {
          ...state.download,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.UPLOAD, STATUSES.SUCCESS):
      return {
        ...state,
        upload: {
          ...state.upload,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };

    case getActionType(actionTypes.DOWNLOAD, STATUSES.ERROR):
      return {
        ...state,
        download: {
          ...state.download,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };
    case getActionType(actionTypes.UPLOAD, STATUSES.ERROR):
      return {
        ...state,
        upload: {
          ...state.upload,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    default:
      return state;
  }
}
