import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const registerBet = (bet) => (dispatch) => {
  return dispatch({
    type: actionTypes.SUBMIT_BET_REGISTRATION,
    xhr: {
      req: () => Axios.post('/api/v1/bets/', bet),
    },
  });
};

export const fetchBetsByCompetition = ({ from, to, competitionId }) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_BETS_BY_COMPETITION,
    xhr: {
      req: () => Axios.get(`/api/v1/bets/${competitionId}/${from}/${to || ''}`),
    },
  });
};
export const fetchUserBets = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_USER_BETS,
    xhr: {
      req: () => Axios.get('/api/v1/bets/'),
    },
  });
};
export const deleteUserBet = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_USER_BET,
    payload: id,
    xhr: {
      req: () => Axios.delete(`/api/v1/bets/${id}`),
      mapper: (res) => ({ data: res.data, id }),
    },
  });
};
