import React, { useMemo } from 'react';
import * as authClient from 'utils/auth';
import useAsync from 'hooks/use-async';
import { FullPageSpinner } from 'components/shared/';
const AuthContext = React.createContext();

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const [connectedUser, setConnectedUser] = React.useState(null);
  const { data: user, error, isError, isSettled, run } = useAsync();

  React.useEffect(() => {
    run(authClient.getUser());
  }, []);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
      if (!isError) setConnectedUser(user);
      else {
        setConnectedUser(null);
      }
    }
  }, [isSettled, isError, user]);

  const login = async (form) => {
    const user = await authClient.login(form);
    setConnectedUser(user);
    return user;
  };

  const refreshMe = async (user) => {
    const me = user || (await authClient.getUser());
    setConnectedUser(me);
    return me;
  };

  const register = (form) => authClient.register(form);
  const forget = (form) => authClient.forget(form);
  const reset = (form, token) => authClient.reset(form, token);
  const logout = () => {
    setConnectedUser(null);
    return authClient.logout();
  };

  const context = useMemo(
    () => ({
      user: connectedUser,
      login,
      logout,
      reset,
      forget,
      register,
      refreshMe,
    }),
    [connectedUser]
  );

  if (!firstAttemptFinished) {
    if (!isSettled || !connectedUser) {
      return <FullPageSpinner />;
    }
    if (isError) {
      return (
        <div style={{ color: 'red' }}>
          <p>Uh oh... There is a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  return <AuthContext.Provider value={context} {...props} />;
}

/**
 * @returns {{user:Object, login:Function, logout:Function, register:Function}} context

 */
function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
