import Axios from 'axios';
import get from 'lodash-es/get';
// import qs from 'qs';

import storage from './storage';
import { ACCESS_TOKEN_KEY, BASE_API_URL, REMEMBER_ME_KEY } from 'constants/env';
import { refreshToken } from './auth';

export const AxiosRepo = (function () {
  let authInstance;
  let publicInstance;

  function createInstance(isPublic = false) {
    const axiosInstance = Axios.create({
      url: BASE_API_URL,
      // transformRequest: [
      //   (data) => (typeof data === 'string' ? data : qs.stringify(data)),
      // ],
      timeout: 30000,
    });

    if (!isPublic) {
      axiosInstance.interceptors.request.use((config) => {
        config = enhanceWithToken(config);
        return config;
      });

      axiosInstance.interceptors.response.use(null, (error) => {
        let { config } = error;

        if (get(error, 'response.status') === 404) {
          window.location.href = '/app/404';
        }

        if (
          storage.get(REMEMBER_ME_KEY) === true &&
          config &&
          get(error, 'response.status') === 401
        ) {
          return refreshToken().then(() => {
            config = enhanceWithToken(config);
            return axiosInstance.request(config);
          });
        }

        // without remember me
        if (get(error, 'response.status') === 401) {
          //redirect to login
          window.location.href = `/login?redirectTo=${window.location.pathname}${window.location.search}`;
        }

        return Promise.reject(error);
      });
    }

    return axiosInstance;
  }
  return {
    /**
     * @returns {import('axios').AxiosInstance}
     */
    getInstance: function () {
      if (!authInstance) {
        authInstance = createInstance();
      }
      return authInstance;
    },
    /**
     * @returns {import('axios').AxiosInstance}
     */
    getPublicInstance: function () {
      if (!publicInstance) {
        publicInstance = createInstance(true);
      }
      return publicInstance;
    },
  };
})();

function enhanceWithToken(config) {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${storage.get(ACCESS_TOKEN_KEY)}`,
    },
  };
}
