import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchFormations = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_FORMATIONS,
    xhr: {
      req: () => Axios.get('/api/v1/formations/'),
    },
  });
};
