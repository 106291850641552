import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.gbePointsCalculator,
  { type, error, payload, playerId }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_GBE_POINT_RESULT, STATUSES.REQUEST):
      return {
        ...state,
        [playerId]: {
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_GBE_POINT_RESULT, STATUSES.SUCCESS):
      return {
        ...state,
        [playerId]: {
          data: payload,
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_GBE_POINT_RESULT, STATUSES.ERROR):
      return {
        ...state,
        [playerId]: {
          error: error,
          status: actionTypes.ERROR_STATUS,
        },
      };

    case getActionType(actionTypes.SUBMIT_GBE_POINT_FORM, STATUSES.REQUEST):
      return {
        ...state,
        pdf: {
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.SUBMIT_GBE_POINT_FORM, STATUSES.SUCCESS):
      return {
        ...state,
        pdf: {
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.SUBMIT_GBE_POINT_FORM, STATUSES.ERROR):
      return {
        ...state,
        pdf: {
          error: error,
          status: actionTypes.ERROR_STATUS,
        },
      };

    default:
      return state;
  }
}
