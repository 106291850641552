import React, { memo } from 'react';
import PropTypes from 'prop-types';
const RadioInput = ({ id, label, ...rest }) => {
  return (
    <div className='radioButton'>
      <input id={id} label={label} type='radio' {...rest} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

RadioInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default memo(RadioInput);
