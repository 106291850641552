import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.formations,
  { type, error, payload }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_FORMATIONS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_FORMATIONS, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: payload,
        normalized: payload.reduce((byFormation, formation) => {
          byFormation[formation._id] = formation.positions;
          return byFormation;
        }, {}),
        normalizedPositions: payload.reduce((byFormation, formation) => {
          byFormation[formation._id] = formation.positions.reduce(
            (byPosition, position) => ({
              ...byPosition,
              [position.name]: position,
            }),
            {}
          );
          return byFormation;
        }, {}),
      };
    case getActionType(actionTypes.FETCH_FORMATIONS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    default:
      return state;
  }
}
