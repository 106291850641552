import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import { calculatePosition } from './utils';
import { PORTAL_EL } from 'constants/env';

function AbsoluteContainer({
  trigger,
  container,
  children,
  visible,
  style = {},
}) {
  return createPortal(
    <div
      style={{
        position: 'absolute',
        ...(visible && trigger && container
          ? calculatePosition(
              trigger.getBoundingClientRect(),
              container.getBoundingClientRect()
            )
          : { display: 'none' }),
        ...style,
      }}
    >
      {children}
    </div>,
    PORTAL_EL
  );
}

export default memo(AbsoluteContainer);
