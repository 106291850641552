import React from 'react';
import * as ReactDOM from 'react-dom';
import ErrorBoundary from 'pages/ErrorBoundary';
import App from './App';
import AppProviders from 'context/AppProviders';
import './vendors.styles';
import './vendors.scripts';
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(ErrorBoundary, null,
        React.createElement(AppProviders, null,
            React.createElement(App, null)))), document.getElementById('root'));
