import React, { memo } from 'react';

function ExpandMore(props) {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'></path>
    </svg>
  );
}

export default memo(ExpandMore);
