import React, { memo } from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/colors';
import cx from 'classnames';

function Badge({ children, className, style }) {
  return (
    <span
      className={cx('badge badge-pill', className)}
      style={{
        backgroundColor: colors.secondary,
        color: colors.white,
        ...style,
      }}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default memo(Badge);
