import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.bets,
  { type, error, payload }
) {
  switch (type) {
    case getActionType(actionTypes.SUBMIT_BET_REGISTRATION, STATUSES.REQUEST):
      return {
        ...state,
        historyByCompetition: {
          ...state.historyByCompetition,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
        registration: {
          ...state.registration,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.SUBMIT_BET_REGISTRATION, STATUSES.SUCCESS):
      return {
        ...state,
        historyByCompetition: {
          ...state.historyByCompetition,
          status: actionTypes.SUCCESS_STATUS,
          data: [...state.historyByCompetition.data,
            payload],
          normalized: {
            ...state.historyByCompetition.normalized,
            [payload.gameId]: payload
          }
        },
        registration: {
          ...state.registration,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.SUBMIT_BET_REGISTRATION, STATUSES.ERROR):
      return {
        ...state,
        registration: {
          ...state.registration,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(actionTypes.FETCH_BETS_BY_COMPETITION, STATUSES.REQUEST):
      return {
        ...state,
        historyByCompetition: {
          ...state.historyByCompetition,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_BETS_BY_COMPETITION, STATUSES.SUCCESS):
      return {
        ...state,
        historyByCompetition: {
          ...state.historyByCompetition,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
          normalized: payload.reduce((betByTeam, bet) => {
            betByTeam[bet.gameId] = bet;
            return betByTeam;
          }, {}),
        },
      };
    case getActionType(actionTypes.FETCH_BETS_BY_COMPETITION, STATUSES.ERROR):
      return {
        ...state,
        historyByCompetition: {
          ...state.historyByCompetition,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(actionTypes.FETCH_USER_BETS, STATUSES.REQUEST):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_USER_BETS, STATUSES.SUCCESS):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          data: payload,
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_USER_BETS, STATUSES.ERROR):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };
    case getActionType(actionTypes.DELETE_USER_BET, STATUSES.REQUEST):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          error: null,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.DELETE_USER_BET, STATUSES.SUCCESS):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          data: state.userBets.data.filter((item) => item.id !== payload.id),
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.DELETE_USER_BET, STATUSES.ERROR):
      return {
        ...state,
        userBets: {
          ...state.userBets,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };
    default:
      return state;
  }
}
