import React from 'react';
import { MdAdd } from 'components/icons';

const RatingTitle = () => (
  <span className='ml-2'>
    <MdAdd /> Ratings
  </span>
);

export default RatingTitle;
