import React from 'react';

function AboutUs() {
  return (
    <section className={'aboutUs'}>
      <div className='container-fluid'>
        <h1 id='aboutUs'>
          <strong>About</strong> US
        </h1>
        <p>
          <strong>REAL Analytics</strong> is an Artificial Intelligence (“AI”) powered platform developed by
          prominent scientists in the football analytics industry. We are the only provider of football analytics
          today to offer both descriptive analytics and proven AI-powered predictive analytics technology integrated
          in the same platform.
        </p>
        <h1 id="thePlatform" className=' mb-5' style={{ marginTop: '7rem' }}>
          The <strong>REAL Analytics</strong> platform
        </h1>
        <p>
          Using the latest technologies in artificial intelligence, REAL Analytics
          has developed a web-based platform, containing a variety of features to aid
          decision making in scouting & recruitment, team selection, playing strategy
          and opposition analysis.
        </p>
        <p>
          The REAL Analytics platform offers a unique user experience by enabling the client to fully customize it to its
          needs, from both a performance analysis and a scouting & recruitment perspective.
        </p>
        <p>
          Unlike descriptive statistics available in the market, the REAL Analytics platform provides access to cutting edge
          analytics, as a proprietary algorithm rates every single action in a match based on how much it contributes to
          the team&apos;s performance.
        </p>
        <p>
          We work with the world&apos;s most forward thinking clubs in the world&apos;s biggest leagues.
        </p>
        <p>
          Our platform provides the following features:
        </p>
      </div>
    </section>
  );
}

export default React.memo(AboutUs);
