import styled from '@emotion/styled';
import { animated } from 'react-spring';
import colors from 'styles/colors';
import { Link } from 'react-router-dom';

// Tree Start
export const Frame = styled.div`
  color: red;
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  color: white;
  fill: white;
`;

export const RegularTitle = styled.h3`
  vertical-align: middle;
  cursor: pointer;
  font-size: 20px;
  &.first {
    font-size: 30px;
    font-weight: bold;
  }
  color: white;
  &:hover {
    color: ${colors.secondary};
  }
`;

export const TitleLink = styled(Link)`
  vertical-align: middle;
  text-decoration: none;
  font-size: 20px;
  &.first {
    font-size: 30px;
    font-weight: bold;
  }
  color: white;
  &:hover {
    text-decoration: none;
    color: ${colors.secondary};
  }
`;

export const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0px 0px 0px 14px;
  /*  border-left: 1px dashed rgba(255, 255, 255, 0.4);*/
  overflow: hidden;
`;

// Tree end

// Navigation Start

export const NavOverlay = styled(animated.div)`
  position: fixed;
  overflow: auto;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  /* width: 100%; */
  background-color: rgba(45, 51, 120, 0.66);
`;
export const SideMenu = styled(animated.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  background-color: #2d3378;
  display: flex;
  flex-direction: column;

  .close-nav {
    text-align: right;
    span {
      font-size: 21px;
      margin-bottom: 20px;
      cursor: pointer;
      .close-icon {
        font-family: 'SF UI Display Bold';
      }

      &:hover {
        color: ${colors.secondary};
      }
    }
  }
  @media (max-width: 425px) {
    width: 100% !important;
    .close-nav {
      text-align: center;
      margin-bottom: 20px;
      span {
        font-size: 40px;
        font-family: 'SF UI Display Ultralight';
        .close-icon {
          font-family: 'SF UI Display Ultralight';
        }
      }
    }
  }
  .header {
    padding: 25px 50px;
    flex: 1;
  }
  .footer {
    height: 85px;
    width: 100%;
    display: flex;
    background-color: #141d38;
    align-items: center;
    justify-content: space-around;
  }
  .social {
    font-family: 'SF UI Display Ultralight';
    font-size: 22px;
    strong {
      font-family: 'SF UI Display Bold';
    }
    img {
      width: 20px;
      height: 20px;
      margin: 5px;
      &:nth-of-type(2) {
        width: 15px;
      }
    }
  }
`;

// Navigation end

export const WorksContainer = styled.section`
  background-image: url('/media/assets/section-bg3.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (max-height: 400px) {
    min-height: 62em;
  }
  @media (max-width: 990px) {
    min-height: 55em;
  }
  @media (max-width: 454px) {
    min-height: 65em;
  }

  color: white;
  .works--row {
    margin-right: 0;
    margin-left: 0;
  }
  .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    .bg {
      position: relative;
      background-image: url('/media/assets/laptop.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 220px;
      width: 360px;
      @media (max-width: 768px) {
        width: 339px;
      }
      @media (max-width: 425px) {
        height: 144px;
        width: 211px;
      }
    }

    .slick-slide {
      width: 265px !important;
      @media (max-width: 425px) {
        width: 155px !important;
      }
    }
    .slick-slider {
      width: 265px !important;
      position: absolute;
      margin-left: 49px;
      padding-top: 18px;
      @media (max-width: 768px) {
        width: 249px !important;
        margin-left: 45px;
      }
      @media (max-width: 425px) {
        width: 155px !important;
        margin-left: 27px;
        padding-top: 11px;
      }
    }
    .slick-list {
      width: 265px !important;
      @media (max-width: 768px) {
        width: 249px !important;
      }
      @media (max-width: 425px) {
        width: 155px !important;
      }
    }
    .slick-dots {
      top: 235px !important;
      @media (max-width: 425px) {
        width: 190px !important;
        left: -18px !important;
        top: 181px !important;
      }
    }

    .slick-dots li button:before {
      font-size: 20px;
      color: transparent;
      border: 2px solid white;
      border-radius: 50%;
      opacity: 1;
    }
    .slick-dots li.slick-active button:before {
      color: white;
      border: none;
    }
  }
  .description {
    padding: 50px 0;
    h2 {
      color: white;
      text-transform: uppercase;
      font-size: 3rem;
      margin-top: 20px;
      margin-bottom: 40px;
      font-family: 'SF UI Display Ultralight';
      strong {
        font-family: 'SF UI Display Bold';
      }
    }
    p {
      font-family: 'SF UI Display Regular';
      text-align: justify;
    }
  }
  .custom-slide {
    height: 168px;
    color: black;
    @media (max-width: 425px) {
      height: 112px;
    }
  }
`;
