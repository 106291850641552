export const Actions = {
  INIT: 'INIT',
  TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
  PREV: 'PREV',
  NEXT: 'NEXT',
  SEARCH: 'SEARCH',
  SET_INDEX: 'SET_INDEX',
  SELECT_VALUE: 'SELECT_VALUE',
  CLEAR_TYPEAHEAD: 'CLEAR_TYPEAHEAD',
};

export function findOptionByValue(options, value) {
  const index = options.findIndex(
    (option) => !option.disabled && option.value === value
  );

  return index === -1 ? null : index;
}

export function findOptionFromTypeahead(options, string = '') {
  if (!string) return null;
  const index = options.findIndex(
    (option) =>
      !option.disabled &&
      option.label &&
      option.label.toLowerCase().startsWith(string.toLowerCase())
  );
  return index === -1 ? null : { index, option: options[index] };
}

export function checkInView(container, element, partial) {
  //Get container properties
  let cTop = container.scrollTop;
  let cBottom = cTop + container.clientHeight;

  //Get element properties
  let eTop = element.offsetTop;
  let eBottom = eTop + element.clientHeight;

  //Check if in view
  let isTotal = eTop >= cTop && eBottom <= cBottom;
  let isPartial =
    partial &&
    ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

  //Return outcome
  return isTotal || isPartial;
}

export function canUseDOM() {
  return (
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
  );
}

export function getOwnerDocument(element) {
  return element && element.ownerDocument
    ? element.ownerDocument
    : canUseDOM()
    ? document
    : null;
}

export function getOwnerWindow(element) {
  let doc = element ? getOwnerDocument(element) : null;
  return doc ? doc.defaultView || window : null;
}

export function getDocumentDimensions(element) {
  let doc = element ? getOwnerDocument(element) : document;
  let win = element ? getOwnerWindow(element) : window;
  return {
    width: doc.documentElement.clientWidth || win.innerWidth,
    height: doc.documentElement.clientHeight || win.innerHeight,
  };
}

const OFFSET = 2;

export const calculatePosition = (triggerRect, tooltipRect) => {
  let { width: windowWidth, height: windowHeight } = getDocumentDimensions();
  if (!triggerRect || !tooltipRect) {
    return {};
  }

  let collisions = {
    top: triggerRect.top - tooltipRect.height < 0,
    right: windowWidth < triggerRect.left + tooltipRect.width,
    bottom: windowHeight < triggerRect.bottom + tooltipRect.height + OFFSET,
    left: triggerRect.left - tooltipRect.width < 0,
  };

  let directionRight = collisions.right && !collisions.left;
  let directionUp = collisions.bottom && !collisions.top;
  return {
    // width: '100%',
    width: triggerRect.width,
    left: directionRight
      ? `${triggerRect.right - tooltipRect.width + window.pageXOffset}px`
      : `${triggerRect.left + window.pageXOffset}px`,
    top: directionUp
      ? `${
          triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset
        }px`
      : `${
          triggerRect.top + OFFSET + triggerRect.height + window.pageYOffset
        }px`,
  };
};
