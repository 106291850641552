import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Tooltip from '@reach/tooltip';

const variants = {
  dark: {
    label: css`
      background-color: #1c2a4e;
    `,
    info: css`
      background-color: #2b3a63;
    `,
  },
  light: {
    label: css`
      background-color: #ffffff;
      color: #191e33;
    `,
    info: css`
      background-color: #47c3c5;
      color: #191e33;
    `,
  },
  primary: {
    label: css`
      background-color: #131d38;
      color: #ffffff;
    `,
    info: css`
      background-color: #47c3c5;
      color: #ffffff;
    `,
  },
};
const sizes = {
  md: css`
    width: 50px;
    height: 50px;
    font-size: 25px;
  `,
  sm: css`
    width: 40px;
    height: 40px;
    font-size: 20px;
  `,
};

const Container = styled.div`
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
  ${(props) => variants[props.variant].label};
  /* height: 50px; */
`;

const Label = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  max-width: 125px;
`;

const Info = styled.div`
  padding: 5px;
  ${(props) => sizes[props.size]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  ${(props) => variants[props.variant].info};
`;

function InfoBox({ label, value, variant = 'dark', size = 'md', tooltip = '', ...rest }) {

  if (tooltip === '') {
    return (
      <Container variant={variant} size={size} {...rest}>
        <Label variant={variant} size={size}>
          {label}
        </Label>
        <Info variant={variant} size={size}>
          {value}
        </Info>
      </Container>
    );
  } else {
    return (
      <Tooltip label={tooltip}>
        <Container variant={variant} size={size} {...rest}>
          <Label variant={variant} size={size}>
            {label}
          </Label>
          <Info variant={variant} size={size}>
            {value}
          </Info>
        </Container>
      </Tooltip>
    );
  }
}

InfoBox.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  variant: PropTypes.string,
  size: PropTypes.string,
  tooltip: PropTypes.string,
};

export default memo(InfoBox);
