/* eslint-disable no-script-url */
import React from 'react';
import './Popover.scss';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import PropTypes from 'prop-types';

const animationClasses = {
  enter: 'enter',
  enterActive: 'enterActive',
  exit: 'exit',
  exitActive: 'exitActive',
};

class Popover extends React.PureComponent {
  state = {
    isVisible: false,
  };

  handler = (event) => {
    let isEscape = false;
    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    } else {
      isEscape = event.keyCode === 27;
    }

    if (isEscape) {
      this.toggleVisibility();
    }
  };

  componentDidMount() {
    if (this.state.isVisible)
      document.addEventListener('keydown', this.handler);
  }
  componentDidUpdate(_, prevState) {
    const isOpening =
      prevState.isVisible === false && this.state.isVisible === true;
    if (isOpening) document.addEventListener('keydown', this.handler);
    else document.removeEventListener('keydown', this.handler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handler);
  }

  /**
   * The button to toggle content visibility
   */
  static Toggle = ({
    title = '',
    children,
    _toggleVisibility,
    activeClassName,
    _isVisible,
    ...rest
  }) => {
    return (
      <div
        title={title}
        role='button'
        className={cx('toggler', {
          ['togglerActive']: _isVisible,
          [activeClassName]: _isVisible,
        })}
        onClick={() => setTimeout(_toggleVisibility, 10)}
        {...rest}
      >
        {children}
      </div>
    );
  };

  static Container = ({
    children,
    className = '',
    _toggleVisibility,
    _isVisible,
    ...rest
  }) => {
    return (
      <div
        id='headerMenu'
        className={cx('popover-menu', className, {
          show: _isVisible,
        })}
        {...rest}
      >
        {typeof children === 'function'
          ? children({
              toggle: _toggleVisibility,
              visible: _isVisible,
            })
          : children}
      </div>
    );
  };

  toggleVisibility = () => {
    const isOpening = this.state.isVisible === false;
    if (isOpening) {
      document.body.classList.add('popover-open');
    } else document.body.classList.remove('popover-open');
    this.setState((prev) => ({ isVisible: !prev.isVisible }));
  };

  render() {
    const { children } = this.props;
    const { isVisible } = this.state;

    const [togglerChild, menuChild] = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        _isVisible: isVisible,
        _toggleVisibility: this.toggleVisibility,
      })
    );

    return (
      <div className='c-popover'>
        {togglerChild}
        <CSSTransition
          in={isVisible}
          timeout={250}
          classNames={animationClasses}
          unmountOnExit
        >
          {menuChild}
        </CSSTransition>
        {isVisible && (
          <div
            id='menuOverlay'
            onClick={() => setTimeout(this.toggleVisibility, 10)}
            className={'overlay'}
          />
        )}
      </div>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default Popover;
