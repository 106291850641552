import initialState from 'store/initialState';
import { LOCATION_CHANGE } from 'connected-react-router';

export default function reducer(
  state = initialState.navigation,
  { type, payload }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        from: state.current || null,
        current: payload.location,
        isFirstRendering: payload.isFirstRendering,
      };
    default:
      return state;
  }
}
