import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';
import omit from 'lodash-es/omit';
import { ITEM_PER_PAGE } from 'constants/env';

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/users';

export const fetchUser = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_USER,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${id}`),
    },
  });
};

export const updateUser = (id, body) => (dispatch) =>
  dispatch({
    type: actionTypes.EDIT_USER,
    xhr: {
      req: () => Axios.put(`${BASE_URL}/${id}`, omit(body, ['avatar'])),
    },
  });

export const updatePassword = (body) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_USER,
    xhr: {
      req: () => Axios.patch(`${BASE_URL}/update-password`, body),
    },
  });
};

export const deleteUsers = (ids) => (dispatch) =>
  dispatch({
    type: actionTypes.DELETE_USERS,
    payload: ids,
    xhr: {
      req: () => Axios.delete(`${BASE_URL}/`, { data: ids }),
      mapper: (res) => ({ data: res.data, ids }),
    },
  });

export const fetchAllNotifications = (userID, page = 1, itemsPerPage = 10) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_NOTIFICATIONS,
    xhr: {
      req: () =>
        Axios.get(`/api/v1/notifications/${userID}/${page}/${itemsPerPage}`),
    },
  });
};

export const fetchRecentNotifications = (userID) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_RECENT_NOTIFICATIONS,
    xhr: {
      req: () => Axios.get(`/api/v1/notifications/${userID}/${1}/${10}`),
    },
  });
};

export const markAsRead = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.MARK_AS_READ,
    xhr: {
      req: () => Axios.patch(`/api/v1/notifications/mark-as-read/${id}`),
    },
  });
};

export const newNotification = (notification) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_NOTIFICATION,
    payload: notification
  });
};

export const fetchUsers = (page = 1, itemPerPage = ITEM_PER_PAGE) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_USERS,
    xhr: { req: () => Axios.get(`${BASE_URL}/${page}/${itemPerPage}`) },
  });
};

export const fetchAccountUsers = (customerId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ACCOUNT_USERS,
    xhr: { req: () => Axios.get(`${BASE_URL}/account-users/${customerId}`) },
  });
};

export const addUser = (payload) => (dispatch) =>
  dispatch({
    type: actionTypes.ADD_USER,
    xhr: {
      req: () => Axios.post(`${BASE_URL}/`, payload),
      onSuccess: (res) => {
        dispatch({ type: actionTypes.INVITE_USER, payload: res.data });
      },
    },
  });

export const deleteAccountUser = (userId) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_ACCOUNT_USERS,
    payload: userId,
  });
};