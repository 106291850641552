import styled from '@emotion/styled';
import { animated } from 'react-spring';
import { memo } from 'react';

const Overlay = styled(animated.div)`
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const ModalContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalContent = styled(animated.div)`
  z-index: 5;
  background: white;
  padding: 5px;
`;

export default memo(Overlay);
