import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';
import { normalizePosition } from 'utils/helpers';
import { SUB_POS } from 'constants/env';

export default function reducer(
  state = initialState.matchPrediction,
  { type, error, payload }
) {
  switch (type) {
    case getActionType(actionTypes.LAUNCH_MATCH_PREDICTION, STATUSES.REQUEST):
      return {
        ...state,
        simulation: {
          ...state.simulation,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.LAUNCH_MATCH_PREDICTION, STATUSES.SUCCESS):
      return {
        ...state,
        simulation: {
          ...state.simulation,
          status: actionTypes.SUCCESS_STATUS,
          data: payload.simulation,
        },
        lineup: {
          homeTeamSquad: payload.query.homeTeamSquad,
          awayTeamSquad: payload.query.awayTeamSquad,
        },
      };
    case actionTypes.RESET_PREDICTION:
      return {
        ...state,
        simulation: {
          ...state.simulation,
          status: actionTypes.IDLE_STATUS,
          data: null,
        },
        lineup: null,
      };
    case getActionType(actionTypes.LAUNCH_MATCH_PREDICTION, STATUSES.ERROR):
      return {
        ...state,
        simulation: {
          ...state.simulation,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    case getActionType(actionTypes.FETCH_TEAM_LATEST_SQUAD, STATUSES.REQUEST):
      return {
        ...state,
        playersByIds: {
          ...state.playersByIds,
          [payload.teamId]: {},
        },
        playersByPosition: {
          ...state.playersByPosition,
          [payload.teamId]: {},
        },
        formationByTeam: {
          ...state.formationByTeam,
          [payload.teamId]: {},
        },
      };
    case getActionType(actionTypes.FETCH_TEAM_LATEST_SQUAD, STATUSES.SUCCESS): {
      const { normalizedPositions, formation, squad, teamId } = payload;

      const selectedPlayers = {};
      const playersById = {};
      const playersByPosition = {};
      squad.forEach((player) => {
        const playerPositionName = player.preferredPosition || SUB_POS;
        const formattedPosition = !player.preferredPosition || !normalizedPositions[formation][player.preferredPosition]
          ? { name: SUB_POS }
          : normalizePosition(
            normalizedPositions[formation][player.preferredPosition]
          );
        if (formattedPosition?.name === SUB_POS) player.isStarting = false;

        playersByPosition[playerPositionName] = {
          ...player,
          position: formattedPosition,
        };
        playersById[player.playerId] = {
          ...player,
          position: formattedPosition,
        };
        selectedPlayers[player.playerId] = !!player.isStarting;
      });
      return {
        ...state,
        playersByIds: {
          ...state.playersByIds,
          [teamId]: playersById,
        },
        selectedPlayers: {
          ...state.selectedPlayers,
          [teamId]: selectedPlayers,
        },
        playersByPosition: {
          ...state.playersByPosition,
          [teamId]: playersByPosition,
        },
        formationByTeam: {
          ...state.formationByTeam,
          [teamId]: formation,
        },
      };
    }
    case actionTypes.CHANGE_FORMATION: {
      const { teamId, formation } = payload;
      const newPosition = { name: SUB_POS };
      const prevPlayersByPosition = state.playersByPosition[teamId];
      const prevPlayersByIds = state.playersByIds[teamId];
      Object.values(prevPlayersByIds).forEach((player) => {
        prevPlayersByPosition[player.position.name] = undefined;
        prevPlayersByIds[player.playerId].position = newPosition;
      });
      return {
        ...state,
        playersByPosition: {
          ...state.playersByPosition,
          [teamId]: prevPlayersByPosition,
        },
        playersByIds: {
          ...state.playersByIds,
          [teamId]: prevPlayersByIds,
        },
        selectedPlayers: {
          ...state.selectedPlayers,
          [teamId]: {},
        },
        formationByTeam: {
          ...state.formationByTeam,
          [teamId]: formation.value,
        },
      };
    }

    case actionTypes.SELECT_PLAYERS_TO_PREDICT: {
      const { teamId, player } = payload;
      const prevSelectedPlayers = state.selectedPlayers[teamId];
      return {
        ...state,
        selectedPlayers: {
          ...state.selectedPlayers,
          [teamId]: {
            ...prevSelectedPlayers,
            [player.playerId]: !prevSelectedPlayers[player.playerId],
          },
        },
      };
    }
    case actionTypes.CHANGE_PLAYER_POSITION: {
      const { teamId, player, position: newPosition } = payload;
      const { position: prevPosition } = player;
      const prevPlayersByPosition = state.playersByPosition[teamId];
      const prevPlayersByIds = state.playersByIds[teamId];
      const newNormalizedPosition = normalizePosition(newPosition);
      const newPlayer = {
        ...player,
        position: newNormalizedPosition,
      };
      return {
        ...state,
        playersByPosition: {
          ...state.playersByPosition,
          [teamId]: {
            ...prevPlayersByPosition,
            [newPosition.name]: newPlayer,
            ...(prevPosition ? { [prevPosition.name]: undefined } : {}),
          },
        },
        playersByIds: {
          ...state.playersByIds,
          [teamId]: {
            ...prevPlayersByIds,
            [player.playerId]: newPlayer,
          },
        },
      };
    }

    default:
      return state;
  }
}
