import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.markets,
  { type, error, payload }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_MARKETS, STATUSES.REQUEST):
      return {
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_MARKETS, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: payload,
      };
    case getActionType(actionTypes.FETCH_MARKETS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    default:
      return state;
  }
}
