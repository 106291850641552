import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectableDropdown from './SelectableDropdown';
import Img from './Img';
import colors from 'styles/colors';
import { DEFAULT_FLAG_LOGO } from 'constants/env';
import { ALL_COUNTRIES } from 'constants/regions';
import { getFlagPath } from 'utils/helpers';
import { MdCheck } from '../icons';
const ITEM_PROPS = {
  closeOnClick: false,
  className: 'no-deco',
};

const COUNTRIES = ALL_COUNTRIES.map((it) => ({
  value: it.title,
  label: it.title,
  logoPath: getFlagPath(it.title),
}));

function CountriesDropdown({ title = Title, value, toggle, ...rest }) {
  const toggleProps = useMemo(
    () => ({
      className: 'btn btn-light mr-2',
      style: {
        width: '100%',
        border: 'solid 1px #ABBFDA',
      },
      title: value.join(','),
      ...toggle,
    }),
    [value]
  );
  return (
    <SelectableDropdown
      title={title}
      renderItem={Option}
      name='countries'
      value={value}
      itemProps={ITEM_PROPS}
      options={COUNTRIES}
      toggle={toggleProps}
      {...rest}
    />
  );
}
function Option({ option, selected }) {
  return (
    <div className='text-base' style={{ padding: 5, width: '100%' }}>
      <Img
        width='20'
        src={option.logoPath}
        alt={option.value}
        defaultImage={DEFAULT_FLAG_LOGO}
      />
      <span className='ml-2'>{option.label}</span>
      {selected.includes(option.value) && (
        <MdCheck color={colors.base} className='mr-1 float-right' />
      )}
    </div>
  );
}

function Title({ selected }) {
  return (
    <>
      {selected.length ? (
        selected.map((it) => (
          <span key={it.value} className='mr-2'>
            <Img
              width='20'
              src={it.logoPath}
              alt={it.value}
              defaultImage={DEFAULT_FLAG_LOGO}
            />
            <span className='ml-2'>{it.label}</span>
          </span>
        ))
      ) : (
        <span className='ml-2'>Select nationalities</span>
      )}
    </>
  );
}

Option.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.any,
};

Title.propTypes = {
  selected: PropTypes.any,
};

CountriesDropdown.propTypes = {
  toggle: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.func,
  value: PropTypes.any,
};

export default React.memo(CountriesDropdown);
