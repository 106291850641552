const colors = {
  base: '#141D38',
  secondary: '#45c2c5',
  tertiary: '#1c2a4e',
  dark: '#0B0F1D',
  white: 'white',
  text: '#131d37',
  gray: '#f1f2f7',
  indigo: '#3f51b5',
  green: '#4caf50',
  red: '#F34423',
  danger: '#ef5350',
  warning: '#ffefb3',
  linkWater: '#E6EAF8',
  blueHaze: '#C3C9DC',
};

export const chartColors = {
  positive: ['green', 'blue'],
  negative: ['red', 'pink'],
};

export default colors;
