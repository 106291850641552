/* eslint-disable react/no-children-prop */
import MessageHub from 'components/shared/MessageHub';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { ERROR_STATUS, SUCCESS_STATUS } from 'store/actionTypes';

import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

const MessageContext = React.createContext();
function MessageProvider({ children, ...rest }) {
  const ref = useRef(null);
  const store = useSelector((state) => state);
  // ADD your slice of reducer to this array if you want to have a toastr message (SHOULD Have a status property)
  const deps = [
    store.user.edit,
    store.user.add,
    store.matchPrediction.simulation,
  ];

  // Not sure about this yet
  deps.forEach(({ status, message, error }) => {
    useEffect(() => {
      if (status === ERROR_STATUS) notify(get(error, 'message'));
      if (status === SUCCESS_STATUS)
        notify(message || 'Operation performed successfully');
    }, [status]);
  });

  const notify = useCallback((message, options) => {
    ref.current(message, options);
  }, []);

  return (
    <MessageContext.Provider value={notify} {...rest}>
      {children}
      <MessageHub children={(add) => (ref.current = add)} />
    </MessageContext.Provider>
  );
}

MessageProvider.propTypes = {
  children: PropTypes.any,
};

function useMessage() {
  const context = React.useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
}

export { MessageProvider, useMessage };
