import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.similarPlayers,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(
      actionTypes.LAUNCH_SIMILAR_PLAYERS_SIMULATION,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(
      actionTypes.LAUNCH_SIMILAR_PLAYERS_SIMULATION,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        players: payload,
      };
    case getActionType(
      actionTypes.LAUNCH_SIMILAR_PLAYERS_SIMULATION,
      STATUSES.ERROR
    ):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    default:
      return state;
  }
}
