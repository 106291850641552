import * as Portal from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { PORTAL_EL } from 'constants/env';
import PropTypes from 'prop-types';
import colors from 'styles/colors';

import React, { Fragment, cloneElement } from 'react';
import ReactDOM from 'react-dom';

const centered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

const PopupStyle = (style) => ({
  border: 'none',
  borderRadius: '3px',
  padding: '0.1em 0.5em',
  ...style,
});
const ArrowStyle = (style) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderBottom: `10px solid ${style.background}`,
});

function Tooltip({
  children,
  label,
  style = {
    background: colors.white,
    color: colors.base,
  },
}) {
  const [trigger, tooltip] = useTooltip();

  const { isVisible, triggerRect } = tooltip;
  return (
    <Fragment>
      {cloneElement(children, trigger)}
      {isVisible &&
        ReactDOM.createPortal(
          <div
            style={{
              ...ArrowStyle(style),
              left:
                triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
              top: triggerRect && triggerRect.bottom + window.scrollY,
            }}
          />,
          PORTAL_EL
        )}
      <TooltipPopup
        {...tooltip}
        label={label}
        style={PopupStyle(style)}
        position={centered}
      />
    </Fragment>
  );
}

Tooltip.propTypes = {
  style: PropTypes.object,
  label: PropTypes.any,
  children: PropTypes.any,
};

export default Tooltip;

export function TriangleTooltip({ children, label, enabled = true }) {
  // get the props from useTooltip
  const [trigger, tooltip] = useTooltip();

  // destructure off what we need to position the triangle
  const { isVisible, triggerRect } = tooltip;
  if (!enabled) {
    return <>{children}</>;
  }
  return (
    <React.Fragment>
      {React.cloneElement(children, trigger)}

      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal.Portal>
          <div
            style={{
              position: 'absolute',
              left:
                triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
              top: triggerRect && triggerRect.bottom + window.scrollY,
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '15px solid #fff',
            }}
          />
        </Portal.Portal>
      )}
      <TooltipPopup
        {...tooltip}
        label={label}
        style={{
          background: 'transparent',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          padding: '0.5em 1em',
        }}
        position={centered}
      />
    </React.Fragment>
  );
}
TriangleTooltip.propTypes = {
  enabled: PropTypes.bool,
  label: PropTypes.any,
  children: PropTypes.any,
};
