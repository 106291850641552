import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { mapToDropdown } from 'utils/helpers';
import { orderBy } from 'lodash-es';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.teams,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_TEAMS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_TEAMS, STATUSES.SUCCESS): {
      const orderedNewTeams = orderBy(
        mapToDropdown(payload.data, 'teamName'),
        ['teamName'],
        ['asc']
      );
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          teams: payload.data,
          dropdown: orderedNewTeams,
          dropdownMulti: {
            ...state.data.dropdownMulti,
            [payload.competitionId]: orderedNewTeams,
          },
        },
      };
    }
    case getActionType(actionTypes.FETCH_TEAMS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.FETCH_TEAM_SQUAD_OVERVIEW, STATUSES.REQUEST):
      return {
        ...state,
        squadOverview: {
          ...state.squadOverview,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_TEAM_SQUAD_OVERVIEW, STATUSES.SUCCESS):
      return {
        ...state,
        squadOverview: {
          ...state.squadOverview,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_TEAM_SQUAD_OVERVIEW, STATUSES.ERROR):
      return {
        ...state,
        squadOverview: {
          ...state.squadOverview,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(actionTypes.FETCH_SUMMARY, STATUSES.REQUEST):
      return {
        ...state,
        summary: {
          ...state.summary,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_SUMMARY, STATUSES.SUCCESS):
      return {
        ...state,
        summary: {
          ...state.summary,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_SUMMARY, STATUSES.ERROR):
      return {
        ...state,
        summary: {
          ...state.summary,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    case getActionType(actionTypes.FETCH_DESCRIPTIVE_STATS, STATUSES.REQUEST):
      return {
        ...state,
        descriptiveStats: {
          ...state.descriptiveStats,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_DESCRIPTIVE_STATS, STATUSES.SUCCESS):
      return {
        ...state,
        descriptiveStats: {
          ...state.descriptiveStats,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_DESCRIPTIVE_STATS, STATUSES.ERROR):
      return {
        ...state,
        descriptiveStats: {
          ...state.descriptiveStats,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(actionTypes.FETCH_LATEST_RESULTS, STATUSES.REQUEST):
      return {
        ...state,
        latestResults: {
          ...state.latestResults,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_LATEST_RESULTS, STATUSES.SUCCESS):
      return {
        ...state,
        latestResults: {
          ...state.latestResults,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_LATEST_RESULTS, STATUSES.ERROR):
      return {
        ...state,
        latestResults: {
          ...state.latestResults,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(
      actionTypes.FETCH_STATUS_WITHIN_LEAGUE,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        statusWithinLeague: {
          ...state.statusWithinLeague,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(
      actionTypes.FETCH_STATUS_WITHIN_LEAGUE,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        statusWithinLeague: {
          ...state.statusWithinLeague,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_STATUS_WITHIN_LEAGUE, STATUSES.ERROR):
      return {
        ...state,
        statusWithinLeague: {
          ...state.statusWithinLeague,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(
      actionTypes.FETCH_EXPECTED_LEAGUE_POSITION,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        expectedLeaguePosition: {
          ...state.expectedLeaguePosition,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(
      actionTypes.FETCH_EXPECTED_LEAGUE_POSITION,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        expectedLeaguePosition: {
          ...state.expectedLeaguePosition,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(
      actionTypes.FETCH_EXPECTED_LEAGUE_POSITION,
      STATUSES.ERROR
    ):
      return {
        ...state,
        expectedLeaguePosition: {
          ...state.expectedLeaguePosition,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(
      actionTypes.FETCH_PRIORITY_AND_VULNERABILITY_AREAS,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        priorityAndVulnerability: {
          ...state.priorityAndVulnerability,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(
      actionTypes.FETCH_PRIORITY_AND_VULNERABILITY_AREAS,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        priorityAndVulnerability: {
          ...state.priorityAndVulnerability,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(
      actionTypes.FETCH_PRIORITY_AND_VULNERABILITY_AREAS,
      STATUSES.ERROR
    ):
      return {
        ...state,
        priorityAndVulnerability: {
          ...state.priorityAndVulnerability,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    case getActionType(actionTypes.FETCH_TEAM_LATEST_SQUAD, STATUSES.REQUEST):
      return {
        ...state,
        latestSquad: {
          ...state.latestSquad,
          status: actionTypes.LOADING_STATUS,
          statusByTeam: {
            ...state.latestSquad.statusByTeam,
            [payload.teamId]: actionTypes.LOADING_STATUS,
          },
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_TEAM_LATEST_SQUAD, STATUSES.SUCCESS):
      return {
        ...state,
        latestSquad: {
          ...state.latestSquad,
          status: actionTypes.SUCCESS_STATUS,
          statusByTeam: {
            ...state.latestSquad.statusByTeam,
            [payload.teamId]: actionTypes.SUCCESS_STATUS,
          },
          formationsByTeams: {
            ...state.latestSquad.formationsByTeams,
            [payload.teamId]: payload.formation,
          },
          data: {
            ...state.latestSquad.data,
            [payload.teamId]: payload.squad,
          },
        },
      };
    case getActionType(actionTypes.FETCH_TEAM_LATEST_SQUAD, STATUSES.ERROR):
      return {
        ...state,
        statusByTeam: {
          ...state.latestSquad.statusByTeam,
          [payload.teamId]: actionTypes.ERROR_STATUS,
        },
        latestSquad: {
          ...state.latestSquad,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    default:
      return state;
  }
}
