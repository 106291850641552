import React, { useLayoutEffect } from 'react';
import './Home.scss';
import ContactUs from 'components/home/ContactUs';
import Intro from 'components/home/Intro';
import TrackRecord from 'components/home/TrackRecord';
import Footer from 'components/home/Footer';
import AboutUs from 'components/home/AboutUs';
import OurProducts from 'components/home/OurProducts';
import OurResearch from 'components/home/OurResearch';
import { useHistory } from 'react-router-dom';

function Home() {
  const history = useHistory();
  useLayoutEffect(() => {
    const el =
      history.location.hash && document.querySelector(history.location.hash);
    if (el)
      el.scrollIntoView({
        behavior: 'smooth',
      });
  });
  return (
    <div>
      <Intro />
      <AboutUs />
      <OurProducts />
      {/* <Works /> */}
      {/* <section className={'trusted'}>
        <img
          height={'145'}
          src='/media/assets/trusted.png'
          alt='trustedby'
          className='mr-5'
        />
        <div className='md-hidden sm-hidden xs-hidden'>
          <div>
            <span>
              <strong>Trusted</strong> BY
            </span>
            <strong className='number'>+300</strong>
            <span>user</span>
          </div>
          <div>
            from <strong>all over the world</strong>
          </div>
        </div>
        <div
          className='lg-hidden xs-hidden'
          style={{
            marginLeft: '10%',
            fontSize: 'xxx-large',
          }}
        >
          <span>
            <strong>Trusted</strong>
            <span className='light'> BY</span>
          </span>
          <br />
          <strong className='number'>+300</strong>
          <span className='light'>user from</span>
          <br />
          <strong>all over the world</strong>
        </div>
        <div
          className='lg-hidden md-hidden sm-hidden'
          style={{
            fontSize: 'xxx-large',
            textAlign: 'center',
          }}
        >
          <span>
            <strong>Trusted</strong>
            <span className='light'> BY</span>
          </span>
          <br />
          <strong className='number'>+300</strong>
          <br />
          <span className='light'>user from</span>
          <br />
          <strong>all over the world</strong>
        </div>
      </section> */}
      <TrackRecord />
      <OurResearch />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
