/* eslint-disable react/jsx-no-bind */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from './Dropdown';
import classNames from 'classnames';
import colors from 'styles/colors';
import { MdCheck } from 'components/icons';

export default class SelectableDropdown extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    renderItem: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    toggle: PropTypes.object,
    unselectedValue: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    className: PropTypes.string,
    options: PropTypes.array,
    itemProps: PropTypes.object,
  };

  static defaultProps = {
    options: [],
  };

  /**
   * @param {Number} fieldIndex
   * @param {MouseEvent} event
   */
  handleOnSelect = (value) => {
    const { options, name } = this.props;
    const item = options.find((o) => o.value === value);
    if (item) {
      this.props.onSelect(item, name);
    } else {
      this.props.onSelect({ value: '', label: '' }, name);
    }
  };

  getSelectedOption() {
    const { options, value } = this.props;
    if (Array.isArray(value))
      return options.filter((option) => value.includes(option.value));
    else return options.find((option) => option.value === value);
  }

  render() {
    const {
      title,
      options,
      loading,
      unselectedValue = '',
      renderItem,
      value,
      toggle = { className: 'btn-default btn-lg' },
      className = '',
      itemProps = { className: 'no-deco' },
      ...rest
    } = this.props;

    const titleValue =
      typeof title === 'function'
        ? title({ options, selected: this.getSelectedOption() })
        : title;
    return (
      <Dropdown
        {...rest}
        value={value}
        onSelect={this.handleOnSelect}
        options={options}
        className={classNames('selectable', className)}
      >
        <DropdownToggle {...toggle} loading={loading}>
          {titleValue || unselectedValue}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option, index) => {
            return (
              <DropdownItem key={index} {...itemProps} value={option.value}>
                <div className='position-relative dd-item'>
                  {renderItem({ option, index, selected: value })}
                  {value === option.value && (
                    <MdCheck color={colors.base} className='mr-1' />
                  )}
                </div>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
