import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchMarkets = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_MARKETS,
    xhr: {
      req: () => Axios.get('/api/v1/markets/'),
    },
  });
};
