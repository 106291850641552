import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchPredictions = (gameId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_LATEST_1X2_PREDICTIONS,
    xhr: {
      req: () => Axios.get(`/api/v1/predictions/${gameId}/1X2`),
    },
  });
};
