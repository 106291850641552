import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';
// elastic_search_player        GET   /api/v1/search/player/{term}
// elastic_search_team          GET   /api/v1/search/team/{term}
// elastic_search_competition   GET   /api/v1/search/competition/{term}

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/search';

export const searchTeams = (term) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_TEAMS,
    xhr: { req: () => Axios.get(`${BASE_URL}/team/${term}`) },
  });
};

export const searchLeagues = (term) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_LEAGUES,
    xhr: { req: () => Axios.get(`${BASE_URL}/competition/${term}`) },
  });
};

export const searchPlayers = (term) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_PLAYERS,
    xhr: { req: () => Axios.post(`${BASE_URL}/player/${term}`) },
  });
};

export const searchPlayerByTeam = (term, teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_PLAYERS_BY_TEAM,
    xhr: { req: () => Axios.post(`${BASE_URL}/player/${term}`, { teamId }) },
  });
};
