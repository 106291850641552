import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const TheVideo = styled.video(
  {
    objectFit: 'cover',
    backgroundColor: 'transparent',
    border: '10px solid #ffffff4f',
  },
  ({ variant = 'big' }) => variants[variant]
);

const variants = {
  big: {
    width: '45em',
    height: '25em',
  },
  medium: {
    width: 270,
    height: 250,
  },
};

function Video(props) {
  return (
    <Container>
      <TheVideo poster={'/media/assets/poster.jpg'} {...props}>
        VIDEO
      </TheVideo>
    </Container>
  );
}

export default React.memo(Video);
