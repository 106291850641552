/** @jsx jsx */

import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { MdSpinner } from 'components/icons';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export function Spinner(props) {
  return (
    <MdSpinner
      css={{ animation: `${spin} 1s linear infinite` }}
      aria-label='loading'
      {...props}
    />
  );
}

export const FullPageCenterContainer = styled.div`
  position: absolute;
  background: #0a0f1d;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function FullPageSpinner({ style }) {
  return (
    <FullPageCenterContainer
      css={{ fontSize: '5em', color: 'white', ...style }}
    >
      <Spinner />
    </FullPageCenterContainer>
  );
}

FullPageSpinner.propTypes = {
  style: PropTypes.object,
};

export default FullPageSpinner;
