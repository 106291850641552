import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { isEmpty, get } from 'lodash-es';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.players,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_PLAYER_PROFILE, STATUSES.REQUEST):
      return {
        ...state,
        profile: {
          ...state.profile,
          status: actionTypes.LOADING_STATUS,
          [payload.playerId]: {
            status: actionTypes.LOADING_STATUS,
            data: {},
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_PROFILE, STATUSES.SUCCESS):
      return {
        ...state,
        profile: {
          ...state.profile,
          status: actionTypes.SUCCESS_STATUS,
          [payload.playerId]: {
            ...state.profile[payload.playerId],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.data,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_PROFILE, STATUSES.ERROR):
      return {
        ...state,
        profile: {
          ...state.profile,
          status: actionTypes.ERROR_STATUS,
          [payload.playerId]: {
            ...state.profile[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_FULL_RATINGS, STATUSES.REQUEST):
      return {
        ...state,
        fullRatings: {
          ...state.fullRatings,
          [payload.playerId]: {
            status: actionTypes.LOADING_STATUS,
            data: {
              availableRatings: [],
            },
          },
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            data: state.referenceQuantile[payload.memKey]
              ? state.referenceQuantile[payload.memKey].data
              : {},
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_PLAYER_FULL_RATINGS,
      STATUSES.SUCCESS
    ): {
      const nextReferences = Object.keys(payload.data.ratings).reduce(
        (acc, ratingField) => {
          acc[ratingField] = {
            rank: get(payload, `data.rankings.${ratingField}.value`),
            quantile: get(payload, `data.quantiles.${ratingField}.value`),
          };
          return acc;
        },
        {}
      );

      return {
        ...state,
        fullRatings: {
          ...state.fullRatings,
          [payload.playerId]: {
            status: actionTypes.SUCCESS_STATUS,
            data: {
              ...payload.data, // contains (ranking/ratings/quantile)
              availableRatings: Object.keys(payload.data.ratings).filter(
                (key) =>
                  ![
                    'untrustworthyHigh',
                    'untrustworthyLow',
                    'peek_age',
                  ].includes(key)
              ),
            },
          },
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.SUCCESS_STATUS,
            data: {
              ...state.referenceQuantile[payload.memKey].data,
              ...nextReferences,
            },
          },
        },
      };
    }
    case getActionType(actionTypes.FETCH_PLAYER_FULL_RATINGS, STATUSES.ERROR):
      return {
        ...state,
        fullRatings: {
          ...state.fullRatings,
          [payload.playerId]: {
            ...state.fullRatings[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };

    case getActionType(actionTypes.FETCH_PLAYER_STATS, STATUSES.REQUEST):
      return {
        ...state,
        attributeRatings: {
          ...state.attributeRatings,
          [payload.playerId]: {
            ...state.attributeRatings[payload.playerId],
            status: actionTypes.LOADING_STATUS,
            data: null,
            normalized: [],
            ratingsByField: {},
          },
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            data: state.referenceQuantile[payload.memKey]
              ? state.referenceQuantile[payload.memKey].data
              : {},
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_STATS, STATUSES.SUCCESS): {
      const nextReferences = payload.data.reduce((acc, curr) => {
        acc[curr.field] = curr;
        return acc;
      }, {});

      return {
        ...state,
        attributeRatings: {
          ...state.attributeRatings,
          [payload.playerId]: {
            ...state.attributeRatings[payload.playerId],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.data,
            ratingsByField: nextReferences,
            normalized: !isEmpty(payload.customWeights)
              ? payload.data.map((rating) => ({
                  ...rating,
                  weight: payload.customWeights[rating.field],
                }))
              : payload.data,
          },
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.SUCCESS_STATUS,
            data: {
              ...state.referenceQuantile[payload.memKey].data,
              ...nextReferences,
            },
          },
        },
      };
    }
    case getActionType(actionTypes.FETCH_PLAYER_STATS, STATUSES.ERROR):
      return {
        ...state,
        attributeRatings: {
          ...state.attributeRatings,
          [payload.playerId]: {
            ...state.attributeRatings[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.ERROR_STATUS,
          },
        },
      };

    case actionTypes.UPDATE_PLAYER_STATS:
      return {
        ...state,
        attributeRatings: {
          ...state.attributeRatings,
          [payload.playerId]: {
            ...state.attributeRatings[payload.playerId],
            normalized: payload.customWeigts,
          },
        },
      };

    case getActionType(actionTypes.FETCH_PLAYER_HEATMAP, STATUSES.REQUEST):
      return {
        ...state,
        heatmaps: {
          ...state.heatmaps,
          [payload.memKey]: {
            status: actionTypes.LOADING_STATUS,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_HEATMAP, STATUSES.SUCCESS):
      return {
        ...state,
        heatmaps: {
          ...state.heatmaps,
          [payload.memKey]: {
            ...state.heatmaps[payload.memKey],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.heatmap,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_HEATMAP, STATUSES.ERROR):
      return {
        ...state,
        heatmaps: {
          ...state.heatmaps,
          [payload.memKey]: {
            ...state.heatmaps[payload.memKey],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };

    case getActionType(
      actionTypes.FETCH_EXPECTED_PERFORMANCE,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(
      actionTypes.FETCH_EXPECTED_PERFORMANCE,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          status: actionTypes.SUCCESS_STATUS,
          data: payload.data,
        },
      };
    case getActionType(actionTypes.FETCH_EXPECTED_PERFORMANCE, STATUSES.ERROR):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.REQUEST):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.SUCCESS):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          status: actionTypes.SUCCESS_STATUS,
          data: payload.data,
        },
      };
    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.ERROR):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    case getActionType(
      actionTypes.FETCH_PLAYER_BASIC_RATINGS,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        basicStatistics: {
          ...state.basicStatistics,
          [payload.playerId]: {
            ...state.basicStatistics[payload.playerId],
            status: actionTypes.LOADING_STATUS,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_PLAYER_BASIC_RATINGS,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        basicStatistics: {
          ...state.basicStatistics,
          [payload.playerId]: {
            ...state.basicStatistics[payload.playerId],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.data,
          },
        },
      };
    case getActionType(actionTypes.FETCH_PLAYER_BASIC_RATINGS, STATUSES.ERROR):
      return {
        ...state,
        basicStatistics: {
          ...state.basicStatistics,
          [payload.playerId]: {
            ...state.basicStatistics[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };
    case getActionType(actionTypes.FETCH_REFERENCES, STATUSES.REQUEST):
    case getActionType(actionTypes.FETCH_ONE_REFERENCE, STATUSES.REQUEST):
      return {
        ...state,
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            data: state.referenceQuantile[payload.memKey]
              ? state.referenceQuantile[payload.memKey].data
              : {},
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(actionTypes.FETCH_REFERENCES, STATUSES.SUCCESS): {
      const nextReferences = payload.data.reduce((acc, curr) => {
        acc[curr.field] = curr;
        return acc;
      }, {});
      return {
        ...state,
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.SUCCESS_STATUS,
            data: {
              ...state.referenceQuantile[payload.memKey].data,
              ...nextReferences,
            },
          },
        },
      };
    }
    case getActionType(actionTypes.FETCH_REFERENCES, STATUSES.ERROR):
    case getActionType(actionTypes.FETCH_ONE_REFERENCE, STATUSES.ERROR):
      return {
        ...state,
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };
    case getActionType(actionTypes.FETCH_ONE_REFERENCE, STATUSES.SUCCESS): {
      return {
        ...state,
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            status: actionTypes.SUCCESS_STATUS,
            data: {
              ...state.referenceQuantile[payload.memKey].data,
              [payload.field]: payload.data,
            },
          },
        },
      };
    }

    case getActionType(
      actionTypes.FETCH_PLAYER_REFERENCE_HISTOGRAM,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        referenceHistogram: {
          ...state.referenceHistogram,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(
      actionTypes.FETCH_PLAYER_REFERENCE_HISTOGRAM,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        referenceHistogram: {
          ...state.referenceHistogram,
          status: actionTypes.SUCCESS_STATUS,
          data: {
            ...state.referenceHistogram.data,
            [payload.memKey]: payload.data,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_PLAYER_REFERENCE_HISTOGRAM,
      STATUSES.ERROR
    ):
      return {
        ...state,
        referenceHistogram: {
          ...state.referenceHistogram,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };
    case getActionType(actionTypes.ADVANCED_PLAYER_SEARCH, STATUSES.REQUEST):
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          status: actionTypes.LOADING_STATUS,
        },
        referenceQuantile: {
          ...state.referenceQuantile,
          [payload.memKey]: {
            ...state.referenceQuantile[payload.memKey],
            data: state.referenceQuantile[payload.memKey]
              ? state.referenceQuantile[payload.memKey].data
              : {},
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(actionTypes.ADVANCED_PLAYER_SEARCH, STATUSES.SUCCESS): {
      const oldReferences = { ...state.referenceQuantile };
      const nextReferences = payload.data.reduce((acc, player) => {
        const memKey = `${player.playerId}-${payload.competitionId}`;
        Object.keys(player.quantiles).forEach((ratingField) => {
          if (!acc[memKey]) {
            acc[memKey] = {
              data: {},
            };
          }
          acc[memKey] = {
            ...acc[memKey],
            data: {
              ...acc[memKey].data,
              [ratingField]: {
                rank: get(player, `rankings.${ratingField}.value`),
                quantile: get(player, `quantiles.${ratingField}.value`),
              },
            },
          };
        });
        return acc;
      }, oldReferences);

      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          status: actionTypes.SUCCESS_STATUS,
          data: payload.data,
        },
        referenceQuantile: nextReferences,
      };
    }
    case getActionType(actionTypes.ADVANCED_PLAYER_SEARCH, STATUSES.ERROR):
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    default:
      return state;
  }
}
