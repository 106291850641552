import { useEffect, useRef } from 'react';

const useOnUpdate = (fn, deps = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fn();
    }
  }, deps);
};

export default useOnUpdate;
