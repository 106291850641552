import React, { useState, memo } from 'react';
import { noop, round } from 'lodash-es';
import PropTypes from 'prop-types';
import './RangeInput.scss';
import styled from '@emotion/styled';

export const Label = styled.span`
  color: white;
  background: #131d37;
  padding: 5px 7px;
  border-radius: 7px;
  margin-left: 14px;
  width: 57px;
  position: relative;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #131d37;
    border-bottom: 12px solid transparent;
    top: 4px;
    left: -8px;
    position: absolute;
  }
  > input {
    color: white;
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 4px;
    background-color: #273559;
    font-size: 12px;
  }
  > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const READ_MODE = 'READ_MODE';
export const WRITE_MODE = 'WRITE_MODE';
function RangeInput({ value = 0, onChange = noop, min = 0, max = 100 }) {
  const [mode, setMode] = useState(READ_MODE);
  const handleKeyPress = (event) => {
    if (event.charCode == 13) {
      onChange(event);
      setMode(READ_MODE);
    }
  };
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <input
        className='input-range'
        type='range'
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
      <Label>
        {mode === WRITE_MODE ? (
          <input
            value={value}
            onChange={onChange}
            type='number'
            max={100}
            min={0}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <span
            onDoubleClick={() => {
              mode === WRITE_MODE ? setMode(READ_MODE) : setMode(WRITE_MODE);
            }}
          >
            {round(value)} %
          </span>
        )}
      </Label>
    </div>
  );
}

RangeInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};
export default memo(RangeInput);
