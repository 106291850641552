import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductBox from '../home/ProductBox';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPages } from 'store';
import { productImages } from 'constants/env';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        infinite: false,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.5,
        variableWidth: true,
        dots: false,
        infinite: false,
        arrows: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        variableWidth: true,
        dots: false,
        infinite: false,
        arrows: false,
      },
    },
  ],
};

const Container = styled.section`
  height: 20rem;
  background: white;
  margin-bottom: 2rem;
  //For Tablet 768px
  @media (max-width: 768px) {
    height: 25rem;
  }
  h2 {
    text-transform: uppercase;
    color: black;
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    font-family: 'SF UI Display Ultralight';
    strong {
      font-family: 'SF UI Display Bold';
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
`;
const Products = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    justify-content: start;
  }
`;
const Slides = styled.div`
  width: 70%;
  //For laptop L 1440px
  @media (max-width: 1440px) and (min-width: 426px) {
    width: 100%;
    .slick-slide {
      margin: 0 10px;
    }
  }
  //For mobile L 425px
  @media (max-width: 425px) and (min-width: 376px) {
    width: 100%;
    .slick-initialized .slick-slide {
      width: 290px !important;
    }
  }

  //For mobile M 375px
  @media (max-width: 375px) and (min-width: 321px) {
    width: 100%;
    .slick-initialized .slick-slide {
      width: 230px !important;
    }
  }

  //For mobile S 320px
  @media (max-width: 320px) {
    width: 100%;
    .slick-initialized .slick-slide {
      width: 213px !important;
    }
  }
`;

function OtherProducts() {
  const { pages } = useSelector((state) => state.pages.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages(0, 0));
  }, []);
  return (
    <Container>
      <div className='container-fluid'>
        <h2>
          <strong>Other</strong> products
        </h2>
        <Products>
          <Slides>
            <Slider {...settings}>
              {pages.data.map((page) => (
                <ProductBox
                  key={page.id}
                  title={page.name}
                  image={productImages[page.slug]}
                  to={`/products/${page.slug}`}
                  text={page.summary}
                />
              ))}
            </Slider>
          </Slides>
        </Products>
      </div>
    </Container>
  );
}

export default React.memo(OtherProducts);
