import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { get, values } from 'lodash-es';
import PropTypes from 'prop-types';
import { formatNumbers } from 'utils/helpers';

import React, { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';

function Histogram({
  data,
  index,
  competitionId,
  playerId,
  plots,
  label,
  selectedColors,
  viewPort = 'charts',
}) {
  const histogramRef = useRef(null);
  const referenceQuantile = useSelector(
    ({ players }) => players.referenceQuantile
  );

  am4core.options.onlyShowOnViewport = true;
  am4core.options.autoDispose = true;
  am4core.options.viewportTarget = document.getElementById(viewPort);

  useEffect(() => {
    // Create chart
    histogramRef.current = am4core.create(
      `histogram${index}`,
      am4charts.XYChart
    );
    histogramRef.current.paddingTop = 0;
    histogramRef.current.paddingBottom = 0;
    histogramRef.current.paddingLeft = 0;
    histogramRef.current.paddingRight = 0;

    histogramRef.current.paddingLeft = 0;
    const bucket = values(
      get(referenceQuantile, `${playerId}-${competitionId}.data`)
    ).find((e) => e.label === label)?.bucket;
    let title = histogramRef.current.titles.create();
    title.text = `${label} (${bucket})`;
    title.fontSize = 13;
    title.marginBottom = 30;
    title.wrap = true;
    //title.maxWidth = 150;

    am4core.useTheme(am4themes_animated);
    // Create axes
    let xAxis = histogramRef.current.xAxes.push(new am4charts.ValueAxis());
    xAxis.dataFields.id = 'value';
    xAxis.renderer.minGridDistance = 80;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.numberFormatter = new am4core.NumberFormatter();
    xAxis.numberFormatter.numberFormat = '#.';
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.labels.template.fontSize = 13;
    xAxis.max = 120;
    xAxis.strictMinMax = true;

    let yAxis = histogramRef.current.yAxes.push(new am4charts.ValueAxis());
    yAxis.baseValue = 0;
    yAxis.renderer.labels.template.fontSize = 13;

    // Create series
    let reference = histogramRef.current.series.push(
      new am4charts.LineSeries()
    );
    histogramRef.current.series.showOnInit = false;
    reference.dataFields.valueX = 'value';
    reference.dataFields.valueY = 'prob';
    reference.strokeWidth = 1;
    reference.fillOpacity = 0.4;
    reference.tensionX = 0.77;

    let playerValue = histogramRef.current.series.push(
      new am4charts.ColumnSeries()
    );
    playerValue.dataFields.valueX = 'value';
    playerValue.dataFields.valueY = 'y';
    playerValue.sequencedInterpolation = true;
    playerValue.fillOpacity = 0;
    playerValue.strokeOpacity = 0;
    playerValue.strokeWidth = 0;
    playerValue.columns.template.width = 0.01;

    let bullet = playerValue.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 0;
    bullet.circle.radius = 0;

    let valueLabel = playerValue.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.background = new am4core.RoundedRectangle();
    valueLabel.label.background.fill = am4core.color('black');
    valueLabel.label.background.cornerRadius(5, 5, 5, 5);
    valueLabel.label.padding(2, 2, 2, 2);
    valueLabel.label.fill = am4core.color('white');
    valueLabel.label.text = '{value}';
    valueLabel.label.fontSize = 13;
    valueLabel.label.truncate = false;
    valueLabel.label.hideOversized = false;
    valueLabel.label.dy = -35;
    const dotsLegend = [];
    let differentBucket = false;
    const sort = [...data].sort((a, b) => a.prob - b.prob);
    const maxProb = sort[data.length - 1];
    const medianProb = sort[~~(data.length / 2)];

    const yValues = [{ prob: 0 }, medianProb, maxProb];
    let i = 0;
    for (const { profile, data } of plots) {
      const { playerId } = profile;
      const reference = values(
        get(referenceQuantile, `${playerId}-${competitionId}.data`)
      ).find((e) => e.label === label);
      const dot = data.find((e) => e.label === label);

      dotsLegend.push({
        y: i < 3 ? yValues[i].prob : 0.001,
        value:
          formatNumbers(dot.value) +
          `(${reference?.rank + (reference?.bucket !== bucket ? '*' : '')})`,
      });
      i++;
      if (reference?.bucket !== bucket) {
        differentBucket = true;
      }
      let event = xAxis.axisRanges.create();
      event.value = formatNumbers(dot.value);
      event.endValue = formatNumbers(dot.value);
      event.grid.disabled = true;
      event.axisFill.fillOpacity = 0.1;
      let axisBullet = new am4charts.AxisBullet();

      event.bullet = axisBullet;
      let circle = event.bullet.createChild(am4core.Circle);
      circle.width = 15;
      circle.height = 15;
      const dotColor = selectedColors.find((e) => e.id === profile.playerId);

      circle.fill = am4core.color(dotColor.color);
      circle.horizontalCenter = 'middle';
    }
    if (differentBucket) {
      let label = chart.createChild(am4core.Label);
      label.text =
        '(*) : The rank was computed with respect to a different reference';
      label.fontSize = 10;
      label.align = 'right';
    }

    const sorted = [...data, ...dotsLegend].sort((a, b) => a.value - b.value);

    histogramRef.current.data = sorted;

    return () => {
      if (histogramRef.current) {
        histogramRef.current.dispose();
      }
    };
  }, [data, referenceQuantile]);

  return (
    <div
      id={`histogram${index}`}
      ref={histogramRef}
      style={{ height: '150px', width: '100%' }}
    ></div>
  );
}

Histogram.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number,
  competitionId: PropTypes.string,
  plots: PropTypes.array,
  label: PropTypes.string,
  playerId: PropTypes.string,
  selectedColors: PropTypes.array,
  viewPort: PropTypes.string,
};
export default memo(Histogram);
