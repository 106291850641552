import { AxiosRepo } from 'utils/axios';
import { useMessage } from 'context/message-context';
import { useState, useRef } from 'react';
import useToggle from '../use-toggle';
const useContactUs = () => {
  const recaptchaInstance = useRef(null);
  const [verified, setVerified] = useState(false);
  const [loading, toggleLoading] = useToggle(false);
  const [error, setError] = useState('');
  const notify = useMessage();

  async function handleOnSubmit(event) {
    event.preventDefault();
    const { target } = event;
    const {
      fullName,
      company,
      email,
      phone,
      country,
      message,
    } = target.elements;
    toggleLoading();
    if (verified) {
      try {
        await AxiosRepo.getPublicInstance().post('/api/v1/contact/send', {
          fullName: fullName.value,
          company: company.value,
          email: email.value,
          phone: phone.value,
          country: country.value,
          message: message.value,
          recaptcha: verified,
        });
        notify('Operation performed successfully');
        target.reset();
        recaptchaInstance.current.reset();
        toggleLoading();
        return true;
      } catch (error) {
        recaptchaInstance.current.reset();
        notify('Something went wrong');
        toggleLoading();
        return false;
      }
    } else {
      setError('Please confirm again you are a human, not a robot.');
      toggleLoading();
      return false;
    }
  }

  return {
    onSubmit: handleOnSubmit,
    onVerify: setVerified,
    loading,
    error: error,
    recaptcha: recaptchaInstance,
  };
};

export default useContactUs;
