import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Img } from './index';
import { compareString } from 'utils/helpers';

function ACPlayerPic({ term, suggestions }) {
  const item = term
    ? suggestions.find((s) => compareString(s.searchLabel, term))
    : null;
  return (
    <Img
      src={get(item, 'playerLogoPath')}
      alt={get(item, 'searchLabel')}
      width={20}
      height={23}
    />
  );
}

ACPlayerPic.propTypes = {
  term: PropTypes.string,
  suggestions: PropTypes.array,
};

export default ACPlayerPic;
