import flatMap from 'lodash-es/flatMap';
export const CONTINENTS = [
  {
    label: 'Europe',
    value: 'Europe',
    image: '/media/contients/europe.png',
    active: '/media/contients/europe-active.png',
  },
  {
    label: 'Africa',
    value: 'Africa',
    image: '/media/contients/africa.png',
    active: '/media/contients/africa-active.png',
  },
  {
    label: 'Asia',
    value: 'Asia',
    image: '/media/contients/asia.png',
    active: '/media/contients/asia-active.png',
  },
  {
    label: 'Oceania',
    value: 'Oceania',
    image: '/media/contients/australia.png',
    active: '/media/contients/australia-active.png',
  },
  {
    label: 'South America',
    value: 'South America',
    image: '/media/contients/south-america.png',
    active: '/media/contients/south-america-active.png',
  },
  {
    label: 'North America',
    value: 'North America',
    image: '/media/contients/north-america.png',
    active: '/media/contients/north-america-active.png',
  },
];

export const REGIONS = [
  {
    name: 'Europe',
    color: '#B2B6BE',
    data: [
      {
        title: 'Serbia',
        id: 'RS',
        group: 'Europe',
      },
      {
        title: 'Turkey',
        id: 'TR',
        group: 'Europe',
      },
      {
        title: 'Albania',
        id: 'AL',
        group: 'Europe',
      },
      {
        title: 'Andorra',
        id: 'AD',
        group: 'Europe',
      },
      {
        title: 'Austria',
        id: 'AT',
        group: 'Europe',
      },
      {
        title: 'Belarus',
        id: 'BY',
        group: 'Europe',
      },
      {
        title: 'Belgium',
        id: 'BE',
        group: 'Europe',
      },
      {
        title: 'Bosnia and Herzegovina',
        id: 'BA',
        group: 'Europe',
      },
      {
        title: 'Bulgaria',
        id: 'BG',
        group: 'Europe',
      },
      {
        title: 'Croatia',
        id: 'HR',
        group: 'Europe',
      },
      {
        title: 'Czech Republic',
        id: 'CZ',
        group: 'Europe',
      },
      {
        title: 'Denmark',
        id: 'DK',
        group: 'Europe',
      },
      {
        title: 'England',
        id: 'GB-ENG',
        group: 'Europe',
      },
      {
        title: 'Estonia',
        id: 'EE',
        group: 'Europe',
      },
      {
        title: 'Faroe Islands',
        id: 'FO',
        group: 'Europe',
      },
      {
        title: 'Finland',
        id: 'FI',
        group: 'Europe',
      },
      {
        title: 'France',
        id: 'FR',
        group: 'Europe',
      },
      {
        title: 'Germany',
        id: 'DE',
        group: 'Europe',
      },
      {
        title: 'Gibraltar',
        id: 'GI',
        group: 'Europe',
      },
      {
        title: 'Greece',
        id: 'GR',
        group: 'Europe',
      },
      {
        title: 'Haiti',
        id: 'HT',
        group: 'Europe',
      },
      {
        title: 'Hungary',
        id: 'HU',
        group: 'Europe',
      },
      {
        title: 'Iceland',
        id: 'IS',
        group: 'Europe',
      },
      {
        title: 'Ireland',
        id: 'IE',
        group: 'Europe',
      },
      {
        title: 'Isle of Man',
        id: 'IM',
        group: 'Europe',
      },
      {
        title: 'Italy',
        id: 'IT',
        group: 'Europe',
      },
      {
        title: 'Latvia',
        id: 'LV',
        group: 'Europe',
      },
      {
        title: 'Liechtenstein',
        id: 'LI',
        group: 'Europe',
      },
      {
        title: 'Lithuania',
        id: 'LT',
        group: 'Europe',
      },
      {
        title: 'Luxembourg',
        id: 'LU',
        group: 'Europe',
      },
      {
        title: 'Malta',
        id: 'MT',
        group: 'Europe',
      },
      {
        title: 'Moldova',
        id: 'MD',
        group: 'Europe',
      },
      {
        title: 'Monaco',
        id: 'MC',
        group: 'Europe',
      },
      {
        title: 'Montenegro',
        id: 'ME',
        group: 'Europe',
      },
      {
        title: 'Netherlands',
        id: 'NL',
        group: 'Europe',
      },
      {
        title: 'North Macedonia',
        id: 'MK',
        group: 'Europe',
      },
      {
        title: 'Northern Ireland',
        id: 'GB',
        group: 'Europe',
      },
      {
        title: 'Norway',
        id: 'NO',
        group: 'Europe',
      },
      {
        title: 'Poland',
        id: 'PL',
        group: 'Europe',
      },
      {
        title: 'Portugal',
        id: 'PT',
        group: 'Europe',
      },
      {
        title: 'Romania',
        id: 'RO',
        group: 'Europe',
      },
      {
        title: 'Russian Federation',
        id: 'RU',
        group: 'Europe',
      },
      {
        title: 'San Marino',
        id: 'SM',
        group: 'Europe',
      },
      {
        title: 'Slovakia',
        id: 'SK',
        group: 'Europe',
      },
      {
        title: 'Slovenia',
        id: 'SI',
        group: 'Europe',
      },
      {
        title: 'Spain',
        id: 'ES',
        group: 'Europe',
      },
      // {
      //   title: 'Svalbard and Jan Mayen',
      //   id: 'SJ',
      //   group: 'Europe',
      // },
      {
        title: 'Sweden',
        id: 'SE',
        group: 'Europe',
      },
      {
        title: 'Switzerland',
        id: 'CH',
        group: 'Europe',
      },
      {
        title: 'Ukraine',
        id: 'UA',
        group: 'Europe',
      },
      {
        title: 'United Kingdom',
        id: 'GB',
        group: 'Europe',
      },
      {
        title: 'Wales',
        id: 'GB-WALES',
        group: 'Europe',
      },
      {
        title: 'Northern Ireland',
        id: 'GB-NOR-IR',
        group: 'Europe',
      },
      {
        title: 'Scotland',
        id: 'GB-SCOT',
        group: 'Europe',
      },
      {
        title: 'Yugoslavia',
        id: 'YU',
        group: 'Europe',
      },
      {
        title: 'Armenia',
        id: 'AM',
        group: 'Europe',
      },
      {
        title: 'Azerbaijan',
        id: 'AZ',
        group: 'Europe',
      },
      {
        title: 'Cyprus',
        id: 'CY',
        group: 'Europe',
      },
      {
        title: 'Georgia',
        id: 'GE',
        group: 'Asia',
      },

      {
        title: 'Israel',
        id: 'IL',
        group: 'Asia',
      },
    ],
  },
  {
    name: 'Africa',
    color: '#B2B6BE',
    data: [
      {
        title: 'Guinea',
        id: 'GN',
        group: 'Africa',
      },
      {
        title: 'Senegal',
        id: 'SN',
        group: 'Africa',
      },
      {
        title: 'Algeria',
        id: 'DZ',
        group: 'Africa',
      },
      {
        title: 'Angola',
        id: 'AO',
        group: 'Africa',
      },
      {
        title: 'Benin',
        id: 'BJ',
        group: 'Africa',
      },
      {
        title: 'Botswana',
        id: 'BW',
        group: 'Africa',
      },
      {
        title: 'British Indian Ocean Territory',
        id: 'IO',
        group: 'Africa',
      },
      {
        title: 'Burkina Faso',
        id: 'BF',
        group: 'Africa',
      },
      {
        title: 'Burundi',
        id: 'BI',
        group: 'Africa',
      },
      {
        title: 'Cameroon',
        id: 'CM',
        group: 'Africa',
      },
      {
        title: 'Cape Verde',
        id: 'CV',
        group: 'Africa',
      },
      {
        title: 'Central African Republic',
        id: 'CF',
        group: 'Africa',
      },
      {
        title: 'Chad',
        id: 'TD',
        group: 'Africa',
      },
      {
        title: 'Comoros',
        id: 'KM',
        group: 'Africa',
      },
      {
        title: 'Congo',
        id: 'CG',
        group: 'Africa',
      },
      {
        title: 'Djibouti',
        id: 'DJ',
        group: 'Africa',
      },
      {
        title: 'Egypt',
        id: 'EG',
        group: 'Africa',
      },
      {
        title: 'Equatorial Guinea',
        id: 'GQ',
        group: 'Africa',
      },
      {
        title: 'Eritrea',
        id: 'ER',
        group: 'Africa',
      },
      {
        title: 'Ethiopia',
        id: 'ET',
        group: 'Africa',
      },
      {
        title: 'Gabon',
        id: 'GA',
        group: 'Africa',
      },
      {
        title: 'Gambia',
        id: 'GM',
        group: 'Africa',
      },
      {
        title: 'Ghana',
        id: 'GH',
        group: 'Africa',
      },
      {
        title: 'Guinea-Bissau',
        id: '',
        group: 'Africa',
      },
      {
        title: 'Guernsey',
        id: 'GG',
        group: 'Africa',
      },
      {
        title: 'Ivory Coast',
        id: 'CI',
        group: 'Africa',
      },
      {
        title: 'Kenya',
        id: 'KE',
        group: 'Africa',
      },
      {
        title: 'Lesotho',
        id: 'LS',
        group: 'Africa',
      },
      {
        title: 'Liberia',
        id: 'LR',
        group: 'Africa',
      },
      {
        title: 'Libyan Arab Jamahiriya',
        id: 'LY',
        group: 'Africa',
      },
      {
        title: 'Madagascar',
        id: 'MG',
        group: 'Africa',
      },
      {
        title: 'Malawi',
        id: 'MW',
        group: 'Africa',
      },
      {
        title: 'Mali',
        id: 'ML',
        group: 'Africa',
      },
      {
        title: 'Mauritania',
        id: 'MR',
        group: 'Africa',
      },
      {
        title: 'Mauritius',
        id: 'MU',
        group: 'Africa',
      },
      {
        title: 'Mayotte',
        id: 'YT',
        group: 'Africa',
      },
      {
        title: 'Morocco',
        id: 'MA',
        group: 'Africa',
      },
      {
        title: 'Mozambique',
        id: 'MZ',
        group: 'Africa',
      },
      {
        title: 'Namibia',
        id: 'NA',
        group: 'Africa',
      },
      {
        title: 'Niger',
        id: 'NE',
        group: 'Africa',
      },
      {
        title: 'Nigeria',
        id: 'NG',
        group: 'Africa',
      },
      {
        title: 'Reunion',
        id: 'RE',
        group: 'Africa',
      },
      {
        title: 'Rwanda',
        id: 'RW',
        group: 'Africa',
      },
      {
        title: 'Saint Helena',
        id: 'SH',
        group: 'Africa',
      },
      {
        title: 'Sao Tome and Principe',
        id: 'ST',
        group: 'Africa',
      },
      {
        title: 'Seychelles',
        id: 'SC',
        group: 'Africa',
      },
      {
        title: 'Sierra Leone',
        id: 'SL',
        group: 'Africa',
      },
      {
        title: 'Somalia',
        id: 'SO',
        group: 'Africa',
      },
      {
        title: 'South Africa',
        id: 'ZA',
        group: 'Africa',
      },
      {
        title: 'South Sudan',
        id: 'SS',
        group: 'Africa',
      },
      {
        title: 'Sudan',
        id: 'SD',
        group: 'Africa',
      },
      {
        title: 'Swaziland',
        id: 'SZ',
        group: 'Africa',
      },
      {
        title: 'Tanzania',
        id: 'TZ',
        group: 'Africa',
      },
      {
        title: 'The Democratic Republic of Congo',
        id: 'CD',
        group: 'Africa',
      },
      {
        title: 'Timor-Leste',
        id: 'TL',
        group: 'Africa',
      },
      {
        title: 'Togo',
        id: 'TG',
        group: 'Africa',
      },
      {
        title: 'Tunisia',
        id: 'TN',
        group: 'Africa',
      },
      {
        title: 'Uganda',
        id: 'UG',
        group: 'Africa',
      },
      {
        title: 'Western Sahara',
        id: 'EH',
        group: 'Africa',
      },
      {
        title: 'Zambia',
        id: 'ZM',
        group: 'Africa',
      },
      {
        title: 'Zimbabwe',
        id: 'ZW',
        group: 'Africa',
      },
    ],
  },
  {
    name: 'North America',
    color: '#B2B6BE',
    data: [
      {
        title: 'Anguilla',
        id: 'AI',
        group: 'North America',
      },
      {
        title: 'Antigua and Barbuda',
        id: 'AG',
        group: 'North America',
      },
      {
        title: 'Aruba',
        id: 'AW',
        group: 'North America',
      },
      {
        title: 'Bahamas',
        id: 'BS',
        group: 'North America',
      },
      {
        title: 'Barbados',
        id: 'BB',
        group: 'North America',
      },
      {
        title: 'Belize',
        id: 'BZ',
        group: 'North America',
      },
      {
        title: 'Bermuda',
        id: 'BM',
        group: 'North America',
      },
      {
        title: 'Canada',
        id: 'CA',
        group: 'North America',
      },
      {
        title: 'Cuba',
        id: 'CU',
        group: 'North America',
      },
      {
        title: 'Dominica',
        id: 'DM',
        group: 'North America',
      },
      {
        title: 'Dominican Republic',
        id: 'DO',
        group: 'North America',
      },
      {
        title: 'Grenada',
        id: 'GD',
        group: 'North America',
      },
      {
        title: 'Guadeloupe',
        id: 'GP',
        group: 'North America',
      },
      {
        title: 'Heard Island and McDonald Islands',
        id: 'HM',
        group: 'North America',
      },
      {
        title: 'Jamaica',
        id: 'JM',
        group: 'North America',
      },
      {
        title: 'Martinique',
        id: 'MQ',
        group: 'North America',
      },
      {
        title: 'Mexico',
        id: 'MX',
        group: 'North America',
      },
      {
        title: 'Montserrat',
        id: 'MS',
        group: 'North America',
      },
      {
        title: 'Netherlands Antilles',
        id: 'AN',
        group: 'North America',
      },
      {
        title: 'Puerto Rico',
        id: 'PR',
        group: 'North America',
      },
      {
        title: 'Saint Kitts and Nevis',
        id: 'KN',
        group: 'North America',
      },
      {
        title: 'Saint Lucia',
        id: 'LC',
        group: 'North America',
      },
      {
        title: 'Saint Pierre and Miquelon',
        id: 'PM',
        group: 'North America',
      },
      {
        title: 'Saint Vincent and the Grenadines',
        id: 'VC',
        group: 'North America',
      },
      {
        title: 'Trinidad and Tobago',
        id: 'TT',
        group: 'North America',
      },
      {
        title: 'Turks and Caicos Islands',
        id: 'TC',
        group: 'North America',
      },
      {
        title: 'USA',
        id: 'US',
        group: 'North America',
      },
      {
        title: 'Virgin Islands British',
        id: 'VG',
        group: 'North America',
      },
      {
        title: 'Virgin Islands U.S.',
        id: 'VI',
        group: 'North America',
      },
    ],
  },
  {
    name: 'South America',
    color: '#B2B6BE',
    data: [
      {
        title: 'Nicaragua',
        id: 'NI',
        group: 'South America',
      },
      {
        title: 'Panama',
        id: 'PA',
        group: 'South America',
      },
      {
        title: 'Costa Rica',
        id: 'CR',
        group: 'South America',
      },
      {
        title: 'El Salvador',
        id: 'SV',
        group: 'South America',
      },
      {
        title: 'Guatemala',
        id: 'GT',
        group: 'South America',
      },
      {
        title: 'Argentina',
        id: 'AR',
        group: 'South America',
      },
      {
        title: 'Honduras',
        id: 'HN',
        group: 'South America',
      },
      {
        title: 'Bolivia',
        id: 'BO',
        group: 'South America',
      },
      {
        title: 'Brazil',
        id: 'BR',
        group: 'South America',
      },
      {
        title: 'Chile',
        id: 'CL',
        group: 'South America',
      },
      {
        title: 'Colombia',
        id: 'CO',
        group: 'South America',
      },
      {
        title: 'Ecuador',
        id: 'EC',
        group: 'South America',
      },
      {
        title: 'Falkland Islands',
        id: 'FK',
        group: 'South America',
      },
      {
        title: 'French Guiana',
        id: 'GF',
        group: 'South America',
      },
      {
        title: 'Paraguay',
        id: 'PY',
        group: 'South America',
      },
      {
        title: 'Peru',
        id: 'PE',
        group: 'South America',
      },
      {
        title: 'Suriname',
        id: 'SR',
        group: 'South America',
      },
      {
        title: 'Uruguay',
        id: 'UY',
        group: 'South America',
      },
      {
        title: 'Venezuela',
        id: 'VE',
        group: 'South America',
      },
    ],
  },
  {
    name: 'Asia',
    color: '#B2B6BE',
    data: [
      {
        title: 'Afghanistan',
        id: 'AF',
        group: 'Asia',
      },
      {
        title: 'Bahrain',
        id: 'BH',
        group: 'Asia',
      },
      {
        title: 'Bangladesh',
        id: 'BD',
        group: 'Asia',
      },
      {
        title: 'Bhutan',
        id: 'BT',
        group: 'Asia',
      },
      {
        title: 'Brunei',
        id: 'BN',
        group: 'Asia',
      },
      {
        title: 'Cambodia',
        id: 'KH',
        group: 'Asia',
      },
      {
        title: 'China',
        id: 'CN',
        group: 'Asia',
      },

      {
        title: 'East Timor',
        id: 'TP',
        group: 'Asia',
      },
      {
        title: 'Holy See (Vatican City State)',
        id: 'VA',
        group: 'Asia',
      },
      {
        title: 'Hong Kong',
        id: 'HK',
        group: 'Asia',
      },
      {
        title: 'India',
        id: 'IN',
        group: 'Asia',
      },
      {
        title: 'Indonesia',
        id: 'ID',
        group: 'Asia',
      },
      {
        title: 'Iran',
        id: 'IR',
        group: 'Asia',
      },
      {
        title: 'Iraq',
        id: 'IQ',
        group: 'Asia',
      },
      {
        title: 'Japan',
        id: 'JP',
        group: 'Asia',
      },
      {
        title: 'Jordan',
        id: 'JO',
        group: 'Asia',
      },
      {
        title: 'Kazakhstan',
        id: 'KZ',
        group: 'Asia',
      },
      {
        title: 'Kuwait',
        id: 'KW',
        group: 'Asia',
      },
      {
        title: 'Kyrgyzstan',
        id: 'KG',
        group: 'Asia',
      },
      {
        title: 'Laos',
        id: 'LA',
        group: 'Asia',
      },
      {
        title: 'Lebanon',
        id: 'LB',
        group: 'Asia',
      },
      {
        title: 'Macao',
        id: 'MO',
        group: 'Asia',
      },
      {
        title: 'Malaysia',
        id: 'MY',
        group: 'Asia',
      },
      {
        title: 'Maldives',
        id: 'MV',
        group: 'Asia',
      },
      {
        title: 'Mongolia',
        id: 'MN',
        group: 'Asia',
      },
      {
        title: 'Myanmar',
        id: 'MM',
        group: 'Asia',
      },
      {
        title: 'Nepal',
        id: 'NP',
        group: 'Asia',
      },
      {
        title: 'North Korea',
        id: 'KP',
        group: 'Asia',
      },
      {
        title: 'Oman',
        id: 'OM',
        group: 'Asia',
      },
      {
        title: 'Pakistan',
        id: 'PK',
        group: 'Asia',
      },
      {
        title: 'Palestine',
        id: 'PS',
        group: 'Asia',
      },
      {
        title: 'Philippines',
        id: 'PH',
        group: 'Asia',
      },
      {
        title: 'Qatar',
        id: 'QA',
        group: 'Asia',
      },
      {
        title: 'Saudi Arabia',
        id: 'SA',
        group: 'Asia',
      },
      {
        title: 'Singapore',
        id: 'SG',
        group: 'Asia',
      },
      {
        title: 'South Korea',
        id: 'KR',
        group: 'Asia',
      },
      {
        title: 'Sri Lanka',
        id: 'LK',
        group: 'Asia',
      },
      {
        title: 'Syria',
        id: 'SY',
        group: 'Asia',
      },
      {
        title: 'Tajikistan',
        id: 'TJ',
        group: 'Asia',
      },
      {
        title: 'Thailand',
        id: 'TH',
        group: 'Asia',
      },

      {
        title: 'Turkmenistan',
        id: 'TM',
        group: 'Asia',
      },
      {
        title: 'United Arab Emirates',
        id: 'AE',
        group: 'Asia',
      },
      {
        title: 'Uzbekistan',
        id: 'UZ',
        group: 'Asia',
      },
      {
        title: 'Vietnam',
        id: 'VN',
        group: 'Asia',
      },

      {
        title: 'Yemen',
        id: 'YE',
        group: 'Asia',
      },
    ],
  },
  {
    name: 'Oceania',
    color: '#B2B6BE',
    data: [
      {
        title: 'American Samoa',
        id: 'AS',
        group: 'Oceania',
      },
      {
        title: 'Australia',
        id: 'AU',
        group: 'Oceania',
      },
      {
        title: 'Christmas Island',
        id: 'CX',
        group: 'Oceania',
      },
      {
        title: 'Cocos (Keeling) Islands',
        id: 'CC',
        group: 'Oceania',
      },
      {
        title: 'Cook Islands',
        id: 'CK',
        group: 'Oceania',
      },
      {
        title: 'Fiji Islands',
        id: 'FJ',
        group: 'Oceania',
      },
      {
        title: 'French Polynesia',
        id: 'PF',
        group: 'Oceania',
      },
      {
        title: 'Guam',
        id: 'GU',
        group: 'Oceania',
      },
      {
        title: 'Kiribati',
        id: 'KI',
        group: 'Oceania',
      },
      {
        title: 'Marshall Islands',
        id: 'MH',
        group: 'Oceania',
      },
      {
        title: 'Nauru',
        id: 'NR',
        group: 'Oceania',
      },
      {
        title: 'New Caledonia',
        id: 'NC',
        group: 'Oceania',
      },
      {
        title: 'New Zealand',
        id: 'NZ',
        group: 'Oceania',
      },
      {
        title: 'Niue',
        id: 'NU',
        group: 'Oceania',
      },
      {
        title: 'Norfolk Island',
        id: 'NF',
        group: 'Oceania',
      },
      {
        title: 'Northern Mariana Islands',
        id: 'MP',
        group: 'Oceania',
      },
      {
        title: 'Palau',
        id: 'PW',
        group: 'Oceania',
      },
      {
        title: 'Papua New Guinea',
        id: 'PG',
        group: 'Oceania',
      },
      {
        title: 'Pitcairn',
        id: 'PN',
        group: 'Oceania',
      },
      {
        title: 'Samoa',
        id: 'WS',
        group: 'Oceania',
      },
      {
        title: 'Solomon Islands',
        id: 'SB',
        group: 'Oceania',
      },
      {
        title: 'Tokelau',
        id: 'TK',
        group: 'Oceania',
      },
      {
        title: 'Tonga',
        id: 'TO',
        group: 'Oceania',
      },
      {
        title: 'Tuvalu',
        id: 'TV',
        group: 'Oceania',
      },
      {
        title: 'United States Minor Outlying Islands',
        id: 'UM',
        group: 'Oceania',
      },
      {
        title: 'Vanuatu',
        id: 'VU',
        group: 'Oceania',
      },
      {
        title: 'Wallis and Futuna',
        id: 'WF',
        group: 'Oceania',
      },
    ],
  },
];

export const ALL_COUNTRIES = flatMap(REGIONS, (region) => region.data);

export const ACTIVE_COUNTRIES = [
  {
    title: 'Paris',
    latitude: 48.8567,
    longitude: 2.351,
    group: 'Europe',
  },
  {
    title: 'Madrid',
    latitude: 40.4167,
    longitude: -3.7033,
    group: 'Europe',
  },
  {
    title: 'London',
    latitude: 51.5002,
    longitude: -0.1262,
    group: 'Europe',
  },
];
