import React, { memo } from 'react';

function KeyboardArrowUp(props) {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'></path>
    </svg>
  );
}

export default memo(KeyboardArrowUp);
