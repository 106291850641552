/** @jsx jsx */

import { jsx } from '@emotion/react';
import colors from 'styles/colors';
import PropTypes from 'prop-types';
const errorMessageVariants = {
  stacked: { display: 'block' },
  inline: { display: 'inline-block' },
};

function ErrorMessage({ error, variant = 'stacked', ...props }) {
  return (
    <div
      role='alert'
      css={[{ color: colors.danger }, errorMessageVariants[variant]]}
      {...props}
    >
      <span>There was an error: </span>
      <pre
        css={[
          { whiteSpace: 'break-spaces', margin: '0', marginBottom: -5 },
          errorMessageVariants[variant],
        ]}
      >
        {error.message}
      </pre>
    </div>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.object,
  variant: PropTypes.string,
};

export default ErrorMessage;
