import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

const loaderVariants = {
  'no-radius': { borderRadius: 0 },
  rounded: {
    borderRadius: '50%',
  },
  'rounded-20': {
    borderRadius: '20%',
  },
};
const Loader = styled.div`
  display: inline-block;
  height: 100%;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

function ImgLoader({ style, variant = 'rounded', ...props }) {
  return (
    <Loader
      {...props}
      style={{ ...loaderVariants[variant], ...style }}
    ></Loader>
  );
}

ImgLoader.propTypes = {
  style: PropTypes.object,
  variant: PropTypes.string,
};

export default memo(ImgLoader);
