import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import { isNil } from 'lodash-es';
import TrustworthyValue from './TrustworthyValue';

function RateValue({ value, tooltip, trustworthy }) {
  if (isNil(value)) return <div>{'-'}</div>;
  return (
    <Tooltip label={<span className='p-3'>{tooltip}</span>}>
      <span tabIndex={0}>
        {trustworthy ? (
          <TrustworthyValue trustworthy={trustworthy}>{value}</TrustworthyValue>
        ) : (
          value
        )}
      </span>
    </Tooltip>
  );
}

RateValue.propTypes = {
  trustworthy: PropTypes.any,
  value: PropTypes.any,
  tooltip: PropTypes.any,
};

export default React.memo(RateValue);
