
import { isEmpty } from 'lodash-es';
import { deltaE } from './colorDistance';

const COLOR_DISTANCE_THRESHOLD = 10;
const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));

function rgbToHex([r, g, b]) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

const hexColorToRgb = (hex) => {

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}


const kitsColorDistance = (rgbColor, existingKits) => {

    if (isEmpty(existingKits)) return COLOR_DISTANCE_THRESHOLD;
    if (isEmpty(rgbColor) || !rgbColor) return 0;
    let minDistance = 100;
    for (const kit of existingKits) {

        const distance = deltaE(rgbColor, hexColorToRgb(kit));

        if (distance < minDistance) {
            minDistance = distance;
        }
    }
    return minDistance;
}


const randomColor = (existingKits) => {
    let color = [];

    while (isEmpty(color) ||
        kitsColorDistance(color, existingKits) < COLOR_DISTANCE_THRESHOLD
    ) {
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        color = [r, g, b]
    }

    return color;
}

export const getColor = (teamKits, existingKits) => {
    for (let teamKit of teamKits) {

        if (
            kitsColorDistance(
                hexColorToRgb(
                    teamKit['colour1']), existingKits) >= COLOR_DISTANCE_THRESHOLD &&
            teamKit['colour1'] !== '#FFFFFF' && teamKit['colour1'] !== null

        ) {

            return teamKit['colour1'];
        }
        if (
            kitsColorDistance(
                hexColorToRgb(
                    teamKit['colour2']), existingKits) >= COLOR_DISTANCE_THRESHOLD &&
            teamKit['colour2'] !== '#FFFFFF' && teamKit['colour2'] !== null
        ) {
            return teamKit['colour2'];
        }
    }

    return rgbToHex(randomColor(existingKits));
}

/*
export const getColor = (teamKits, existingKits) => {
    for (let teamKit of teamKits) {
        if (
            !existingKits.some((e) => e === teamKit['colour1'])
            
        ) {
            return teamKit['colour1'];
        }
        if (!existingKits.some((e) => e === teamKit['colour2'])) {
            return teamKit['colour2'];
        }
    }
    return randomColor(existingKits);
}*/


export const normalizeData = (plots) => {
    const plotData = [];



    plots.forEach(plot => {
        plot?.data.forEach((e) => {

            plotData.push({
                [`quantile${plot.profile.playerId}`]: (1 - e.quantile) * 100,
                'label': e.label,
                [`value${plot.profile.playerId}`]: e.value,
                'rank': e.rank,
                'playerId': plot.profile.playerId,
                'field': e.field
            })
        })
    });


    return plotData;
}

