import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchScheduledMatchs = ({ competitionId, from, to, params }) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_SCHEDULED_MATCHS,
    xhr: {
      req: () =>
        Axios.get(
          `/api/v1/fixtures/scheduled/${competitionId}/${from}/${to || ''}`, { params }
        ),
    },
  });
};

export const fetchScheduledMatchById = (matchId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_MATCH,
    xhr: {
      req: () => Axios.get(`/api/v1/fixtures/${matchId}`), //todo,
    },
  });
};
