import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchAllCompetitions = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_COMPETITIONS,
    xhr: { req: () => Axios.get('/api/v1/competitions/') },
  });
};

export const fetchAccessibleCompetitions = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ACCESSIBLE_COMPETITIONS,
    xhr: { req: () => Axios.get('/api/v1/competitions/accessible') },
  });
};

export const fetchCompetitionById = (competetionId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_COMPETITION,
    xhr: { req: () => Axios.get(`/api/v1/competitions/${competetionId}`) },
  });
};

export const fetchCompetitionsByCountry = (country, matchPrediction = false) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_COMPETITION_BY_COUNTRY,
    xhr: {
      req: () => Axios.get(`/api/v1/competitions/by-country/${country}?match-prediction=${matchPrediction}`),
    },
  });
};

export const fetchTopCompetitions = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_TOP_COMPETITIONS,
    xhr: {
      req: () => Axios.get('/api/v1/competitions/reference'),
    },
  });
};
