import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Dot from '../shared/Dot';

const Option = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
`;

function RatingOption({ option, selected }) {
  return (
    <Option title={option.label} className='text-base'>
      <Dot
        variant={
          selected.includes(option.value) ? 'big-checked' : 'big-unchecked'
        }
      />
      <span className='ml-2'>{option.label}</span>
    </Option>
  );
}

RatingOption.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.any,
};

export default RatingOption;
