import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';
export default function reducer(
  state = initialState.search,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.SEARCH_PLAYERS, STATUSES.REQUEST):
    case getActionType(actionTypes.SEARCH_PLAYERS_BY_TEAM, STATUSES.REQUEST):
      return {
        ...state,
        players: {
          ...state.players,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.SEARCH_PLAYERS, STATUSES.SUCCESS):
    case getActionType(actionTypes.SEARCH_PLAYERS_BY_TEAM, STATUSES.SUCCESS):
      return {
        ...state,
        players: {
          ...state.players,
          data: payload || [],
          status: actionTypes.SUCCESS_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_TEAMS, STATUSES.REQUEST):
      return {
        ...state,
        teams: {
          ...state.teams,
          status: actionTypes.LOADING_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_TEAMS, STATUSES.SUCCESS):
      return {
        ...state,
        teams: {
          ...state.teams,
          data: payload || [],
          status: actionTypes.SUCCESS_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_LEAGUES, STATUSES.REQUEST):
      return {
        ...state,
        leagues: {
          ...state.leagues,
          status: actionTypes.LOADING_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_LEAGUES, STATUSES.SUCCESS):
      return {
        ...state,
        leagues: {
          ...state.leagues,
          data: payload || [],
          status: actionTypes.SUCCESS_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_LEAGUES, STATUSES.ERROR):
      return {
        ...state,
        leagues: {
          ...state.leagues,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_PLAYERS, STATUSES.ERROR):
    case getActionType(actionTypes.SEARCH_PLAYERS_BY_TEAM, STATUSES.ERROR):
      return {
        ...state,
        players: {
          ...state.players,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };

    case getActionType(actionTypes.SEARCH_TEAMS, STATUSES.ERROR):
      return {
        ...state,
        teams: {
          ...state.teams,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };

    default:
      return state;
  }
}
