import React, { memo } from 'react';

function Add(props) {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'></path>
    </svg>
  );
}

export default memo(Add);
