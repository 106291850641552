import React, { useState } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import Img from '../shared/Img';
import { downloadFile } from 'utils/helpers';

const Container = styled.section`
  /* height: 50em; */
  background: white;
  h2 {
    text-transform: uppercase;
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    font-family: 'SF UI Display Ultralight';
    strong {
      font-family: 'SF UI Display Bold';
    }
  }

  .timeline {
    position: relative;
    margin: 0 auto;
    width: 90%;
    ul {
      padding: 0;
    }
  }

  .timeline ul li {
    margin-bottom: 50px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    @media (max-width: 425px) {
      flex-direction: column-reverse;
    }
  }

  .point {
    min-width: 20px;
    height: 20px;
    background-color: ${colors.secondary};
    border-radius: 100%;
    z-index: 2;
    position: relative;
    left: 0px;
    margin-right: 20px;
    @media (max-width: 425px) {
      margin-top: 10px;
      left: 2px;
      margin-right: 0;
    }
    @media (max-width: 768px) and (min-width: 426px) {
      left: 10px;
    }
    @media (width: 1024px) {
      left: 8px;
    }
  }

  .timeline ul li .content {
    width: 100%;
    background: white;
    color: ${colors.base};
    border: 1px solid ${colors.base};
    border-radius: 5px;
    padding: 10px;
    @media (max-width: 425px) {
      z-index: 2;
      margin-top: 10px;
    }
  }
  .timeline ul li.active .content {
    background: ${colors.base};
    color: white;
  }

  .timeline ul li .content h4 {
    padding: 10px 20px;
    margin-bottom: 0;
    text-align: justify;
    font-family: 'SF UI Display Regular';
    font-size: 1rem;
  }

  .timeline ul li .content div {
    font-family: 'SF UI Display Bold';
    margin-top: 2rem;
    padding: 10px 20px;
    margin-top: 0;
    display: flex;
    text-align: justify;
    @media (max-width: 425px) {
      flex-direction: column;
      img {
        width: 100% !important;
      }
      p {
        margin: 0 !important;
      }
    }
  }
  .timeline ul li .date {
    font-weight: normal;
    background-color: ${colors.base};
    color: white;
    font-family: 'SF UI Display Bold';
    font-size: 3rem;
    width: 170px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 30px;
    float: right;
    @media (max-width: 425px) {
      z-index: 2;
      margin-right: 0;
    }
  }

  .timeline ul li.active .date {
    background-color: ${colors.secondary};
  }

  .timeline::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    left: 180px;

    background-color: ${colors.base};

    @media (max-width: 425px) {
      left: 50%;
      z-index: 1;
    }
  }
`;

function TrackRecord() {
  const [hovered, setHovered] = useState([false, false, false, false, false, false]);

  return (
    <Container>
      <div className='container-fluid'>
        <h2 id="exampleOfOurAnalytics">
          Examples of our <strong>analytics</strong>
        </h2>

        <div className='timeline'>
          <ul>
            <li
              onMouseEnter={() =>
                setHovered([true, false, false, false, false, false])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[0] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => downloadFile('/media/success_stories/Recruitement_impact_Arsenal.jpg', 'Recruitement_impact_Arsenal.jpg')}>
                <h4>
                  In April 2020 Real Analytics took a closer look at the impact of the potential sale of Pierre-Emerick Aubameyang for Arsenal FC. Our AI powered
                  prediction models simulate the season 100,000 times under two scenarios: (1) Arsenal keep Aubameyang (Squad before), and (2) Arsenal sell
                  Aubameyang to Barcelona (Squad after).
                  The results show that although there is no impact on Arsenal&apos;s chances of winning the league which stays at 0% (sorry Arsenal fans!), Arsenal&apos;s
                  chances of finishing in the top 3 halve from 1.8% to 0.9%. Similarly, the chances of a Champions League place nearly halves from 5.8% to 3.1%.
                  The team is expected to score around 54.7 goals this season, with Aubameyang on board, but this reduces to 50.1 without him.
                  Expected league points for the team are expected to fall from 55.7 to 53.4 - a loss of two and a half league points. The clearest indicator of the
                  importance of Aubameyang to Arsenal is in the expected league position, which drops from 8.5 to 9.3 - nearly a whole league place.
                  The Arsenal management team and board could use these results to gauge whether the money on offer for Aubameyang presents a serious option
                  for them as they decide on a strategy to get them back into the upper echelons of the Premier League.
                </h4>

                <div>
                  <Img width='170' src='/media/assets/Export_Arsenal.jpg' alt='' />
                  <p className='ml-3'>
                    The impact of Arsenal losing Pierre-Emerick Aubameyang{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2020</span>
              </div>
            </li>

            <li
              onMouseEnter={() =>
                setHovered([false, true, false, false, false, false])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[1] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => downloadFile('/media/success_stories/Recruitement_impact_Newcastle.jpg', 'Recruitement_impact_Newcastle.jpg')}>
                <h4>
                  Allan Saint-Maximin is possibly Newcastle United&apos;s prize asset. Chances are other clubs are watching him closely as a potential recruit. Can Newcasle
                  afford to lose him? In July 2020, Real Analytics took a closer look at the potential impact of Newcastle United selling Saint-Maximin.
                  With Saint-Maximin in the squad, Newcastle earns, on average, 45.5 points in the league. Without him, this drops to 39.4 - dangerously close to the
                  40 points threshold often used as the &quot;safe from relegation&quot; mark. Indeed, the probability of relegation changes enormously. With him, it is just
                  1.9%, but without him, it rises to 33.3%. The average finish position of the team decreases from 13.1 to 16.5 - a loss of 3.5 league places.
                  It appears that Allan Saint-Maximin plays a pivotal role at the club and without him, they would face a tough season fighting to avoid relegation. The
                  selling price would likely have to be high to make it a worthwhile deal.
                </h4>

                <div>
                  <Img width='170' src='/media/assets/Export_Newcastle.jpg' alt='' />
                  <p className='ml-3'>
                    The impact of Newcastle losing Allan Saint-Maximin{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2020</span>
              </div>
            </li>

            <li
              onMouseEnter={() =>
                setHovered([false, false, true, false, false, false])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[2] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => downloadFile('/media/success_stories/Recruitement_impact_Barnsley.jpg', 'Recruitement_impact_Barnsley.jpg')}>
                <h4>
                  Knowing which players to buy and sell is the decision at the heart of a recruitment department in any football club. Being able to measure the impact
                  of such decisions is the golden ticket to success. In August 2020 we set our AI driven prediction engine to look at what the impact of Barnsley selling
                  their forward Cauley Woodrow and using the money to fund the purchase of central midfielder Ben Whiteman from Doncaster Rovers would be.
                  Despite Woodrow being a top performer for Barnsley, Real Analytics predicted it would be a very positive move. Taking into account the full squad
                  profile, and the likely in-house replacements for Woodrow upfront, the addition of Whiteman brings an additional 6.6 league points (up from an
                  expected 60.0 to 66.6), and a climb of 4.5 league positions (from 14.5 to 9.9, on average). Perhaps most telling is the increased chances of finishing
                  in the playoff positions which nearly quadruple.
                  Being able to estimate the impact of complex recruitment decisions like these is invaluable to club management and boards. Making better decisions
                  in recruitment can have a lasting legacy on the clubs fans, the team, and the club management.
                </h4>

                <div>
                  <Img width='170' src='/media/assets/Export_Barnsley.jpg' alt='' />
                  <p className='ml-3'>
                    The impact of Barnsley recruiting Ben Whiteman from Doncaster Rovers, and losing Cauley Woodrow{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2020</span>
              </div>
            </li>

            <li
              onMouseEnter={() =>
                setHovered([false, false, false, true, false, false])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[3] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => window.open('https://www.bbc.com/sport/football/38524102')}>
                <h4>
                    In January 2017
                  , we predicted Chelsea to win by seven points and Arsenal to
                  finish outside the top four (for the first time in 20 years)
                </h4>

                <div>
                  <Img width='170' src='/media/assets/track4.png' alt='' />
                  <p className='ml-3'>
                    Premier League: Chelsea to win by seven points? Arsenal
                    outside top four?{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2017</span>
              </div>
            </li>

            <li
              onMouseEnter={() =>
                setHovered([false, false, false, false, true, false])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[4] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => window.open('https://www.bbc.com/sport/football/35500840')}>
                <h4>
                  During the extraordinary 2016-17 Premier League season, we
                  identified earlier than anyone that &nbsp;
                    Leicester City were favourites for the league title
                  . Our player ratings and forecasting models recognised that
                  Leicester City had a team more than capable of continuing
                  their incredible start to the season.
                </h4>

                <div>
                  <Img width='170' src='/media/assets/track5.png' alt='' />
                  <p className='ml-3'>
                    Man City v Leicester: Why top-two meeting IS a title decider{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2017</span>
              </div>
            </li>

            <li
              onMouseEnter={() =>
                setHovered([false, false, false, false, false, true])
              }
              onMouseLeave={() =>
                setHovered([false, false, false, false, false, false])
              }
              className={hovered[5] === true ? 'active' : ''}
            >
              <div className='content' onClick={() => window.open('https://www.bbc.com/sport/football/40974299')}>
                <h4>
                  In September 2017
                  , before Coutinho’s move away from Liverpool, our algorithms
                  suggested that Coutinho was not Liverpool’s most important
                  player. In fact, he was far from it. We think the subsequent
                  success of Liverpool, and Coutinho’s struggles demonstrate the
                  power and accuracy of our analysis.
                </h4>

                <div>
                  <Img width='170' src='/media/assets/track1.jpg' alt='' />
                  <p className='ml-3'>
                    Premier League: Who is your team&apos;s &apos;most
                    influential&apos; player?{' '}
                  </p>
                </div>
              </div>

              <div className='point'></div>

              <div className='date'>
                <span>2017</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}

TrackRecord.propTypes = {};

export default TrackRecord;
