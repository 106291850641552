import React, { memo } from 'react';
import { LeaguesDrowdown } from './index';
import { fetchTopCompetitions } from 'store';
import { useSelector } from 'react-redux';
import { IDLE_STATUS } from 'store/actionTypes';
import { omit } from 'lodash-es';

const ITEM_PROPS = {
  closeOnClick: true,
  className: 'no-deco',
};
export const TOP_LEAGUES_FETCHER = {
  fetcher: fetchTopCompetitions,
  path: 'topLeagues',
};
const SOURCE = omit(TOP_LEAGUES_FETCHER, ['fetcher']);

function TopLeagues(props) {
  const status = useSelector((state) => state.competitions.topLeagues.status);
  return (
    <LeaguesDrowdown
      itemProps={ITEM_PROPS}
      source={status === IDLE_STATUS ? TOP_LEAGUES_FETCHER : SOURCE}
      {...props}
    />
  );
}

export default memo(TopLeagues);
