import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 15px;
  background: #ccc;
  display: flex;
  position: relative;
`;

const Bar = styled.div`
  height: 100%;
  position: absolute;
  border-radius: 15px;
`;

const Value = styled.div`
  z-index: 0;
  font-family: monospace;
  flex: 1;
`;

function ProgressBar({ bg = 'red', color = 'white', percent = 0, value }) {
  return (
    <Container>
      <Bar
        style={{
          width: `${percent}%`,
          background: bg,
        }}
      ></Bar>
      {value && <Value style={{ color }}>{value}</Value>}
    </Container>
  );
}

ProgressBar.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  percent: PropTypes.number,
  value: PropTypes.number,
};
export default memo(ProgressBar);
