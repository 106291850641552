import styled from '@emotion/styled';
import AutocompleteInput from 'components/AutocompleteInput';
import { BoxHolder, Img } from 'components/shared';
import ACPlayerPic from 'components/shared/ACPlayerPic';
import ACTeamPic from 'components/shared/ACTeamPic';
import { DEFAULT_TEAM_LOGO } from 'constants/env';
import useQueryHistory from 'hooks/use-query-history';
import PropTypes from 'prop-types';
import { LOADING_STATUS } from 'store/actionTypes';
import { normalizeSuggestion } from 'utils/helpers';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchPlayers, searchTeams, searchPlayerByTeam } from 'store';

const Container = styled.div`
  min-height: 10rem;
  background: white;
  padding: 50px 80px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

function PlayerFinder({ redirect, title }) {
  const history = useQueryHistory();
  const dispatch = useDispatch();
  const [scope, setScope] = useState(null);
  const players = useSelector((state) => state.search.players);
  const teams = useSelector((state) => state.search.teams);
  const isSearchingTeams = teams.status === LOADING_STATUS;
  const isSearchingPlayer = players.status === LOADING_STATUS;
  return (
    <BoxHolder>
      <BoxHolder.Header className='p-2'>
        <BoxHolder.Title>{title}</BoxHolder.Title>
      </BoxHolder.Header>
      <BoxHolder.Body>
        <Container className='row mr-0 ml-0'>
          <Column className='col-6'>
            <AutocompleteInput
              className='form-control'
              label='Search by team'
              isLoading={isSearchingTeams}
              onChangeTerm={(term) => {
                term = term.trim();
                if (term) {
                  dispatch(searchTeams(term));
                } else {
                  setScope(null);
                }
              }}
              onSelect={setScope}
              renderImg={ACTeamPic}
              suggestions={normalizeSuggestion([
                {
                  array: teams.data,
                  value: 'teamId',
                  label: 'name',
                  image(item) {
                    return (
                      <Img
                        src={item.teamLogoPath}
                        defaultImage={DEFAULT_TEAM_LOGO}
                        alt={item.name}
                        width={25}
                      />
                    );
                  },
                },
              ])}
            />
          </Column>
          <Column className='col-6'>
            <AutocompleteInput
              className='form-control'
              label='Search by player'
              isLoading={isSearchingPlayer}
              renderImg={ACPlayerPic}
              suggestions={normalizeSuggestion([
                {
                  array: players.data,
                  value: 'playerId',
                  label: 'name',
                  image(item) {
                    return (
                      <Img
                        src={item.playerLogoPath}
                        alt={item.name}
                        width={25}
                      />
                    );
                  },
                  type: 'PLAYER',
                },
              ])}
              onChangeTerm={(term) => {
                term = term.trim();
                if (term)
                  dispatch(
                    scope
                      ? searchPlayerByTeam(term, scope.teamId)
                      : searchPlayers(term)
                  );
              }}
              onSelect={(player) => history.push(redirect(player))}
            />
          </Column>
        </Container>
      </BoxHolder.Body>
    </BoxHolder>
  );
}
PlayerFinder.propTypes = {
  title: PropTypes.string,
  redirect: PropTypes.func,
};
export default React.memo(PlayerFinder);
