import { GlobalBackground, Button } from 'components/styled';
import PropTypes from 'prop-types';

import React from 'react';

const isNotDev = process.env.NODE_ENV !== 'development';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });

    if (isNotDev) {
      console.log('Something went wrong .....', error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <GlobalBackground />
          <div
            className='d-flex align-items-center justify-content-center flex-column'
            style={{
              height: '50vh',
            }}
          >
            <div className='d-flex align-items-center justify-content-center'>
              <img
                src='/media/assets/soccer-ball.png'
                height='100'
                style={{ marginTop: '40px' }}
              />
              <img
                src='/media/assets/soccer-ball.png'
                height='100'
                style={{ marginTop: '40px' }}
              />
              <div style={{ color: 'white', fontSize: '10rem' }}>ps !</div>
            </div>
            <div
              style={{
                color: 'white',
                fontSize: '1.8rem',
                textAlign: 'center',
              }}
            >
              Something went wrong
            </div>
            <a className='btn btn-danger' href={'/app'}>
              Take me to the home page
            </a>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
