export const BASE_API_URL = `${process.env.APP_DOMAIN}/`;
export const CLIENT_ID = process.env.CLIENT_ID;
export const CLIENT_SECRET = process.env.CLIENT_SECRET;
export const REDIRECT_URI = process.env.APP_DOMAIN;
export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

export const APP_VERSION = process.env.APP_VERSION;

export const ACCESS_TOKEN_KEY = '__ra_token__';
export const REFRESH_TOKEN_KEY = '__ra_refresh_token__';
export const REMEMBER_ME_KEY = '__ra_remember_me__';
export const ACCESSIBLE_APPLICATIONS_KEY = '__ra_acc_apps__';

export const DEFAULT_PLAYER_LOGO = '/media/players/player.png';
export const DEFAULT_TEAM_LOGO = '/media/assets/default-team-logo.png';
export const DEFAULT_LEAGUE_LOGO = '/media/assets/default-league-logo.png';
export const DEFAULT_FLAG_LOGO = '/media/assets/default-flag.jpg';
export const DEFAULT_PREDICTION_ICON = '/media/assets/prediction-icon.png';
export const DEFAULT_USER_AVATAR = '/media/assets/default_avatar.png';

export const MERCURE_HUB_URL = process.env.MERCURE_PUBLIC_URL;
export const MERCURE_TOPIC = 'https://realanalytics.org/notification/1';

export const PREMIER_LEAGUE =
  '80fb37adb5b11ceb1c74a0acbec30cefdf6f38a1b23a8e1c001f6cf44198af12';

export const PORTAL_EL = document.getElementById('portals');
export const ITEM_PER_PAGE = 10;

export const MODULES = [
  'Player rating',
  'Player search',
  'Similar players search',
  'Player impact evaluation ',
  'Real analytics Match lab',
  'Player evaluation',
  'Squad analysis',
  'Match prediction',
];

//! TODO this **HAS TO BE** dynamic
export const APPLICATIONS = {
  PLAYER_PROFILE: 'Player Profile',
  SQUAD_PROFILE: 'Squad Profile',
  ADVANCED_PLAYER_SEARCH: 'Advanced Player Search',
  COMPARE_PLAYERS: 'Compare Players',
  SIMILAR_PLAYER_SEARCH: 'Similar Player Search',
  PLAYER_IMPACT: 'Player Impact',
  MATCH_PREDICTION: 'Match Prediction',
  GBE_POINTS_CALCULATOR: 'GBE Points Calculator',
  MATCH_LAB: 'GBE Points Calculator',
};

// todo maybe this should be dynamic
export const NEEDS_TEAM_SELECTION = [
  'squad-profile',
  'player-impact',
  'match-prediction',
  'match-lab',
];

export const MAIN_RATINGS = [
  { id: 'ove', label: 'Overall', field: 'overall' },
  { id: 'pot', label: 'Potential', field: 'potential' },
  { id: 'xgpm', label: 'Influence on chances', field: 'xgpm' },
  { id: 'xppm', label: 'Influence on results', field: 'xppm' },
  { id: 'performance', label: 'Performance', field: 'performance' },
  { id: 'reliability', label: 'Reliability', field: 'reliability' },
];

export const RATINGS = [
  ...MAIN_RATINGS,
  { id: 'minPlayed', label: 'Minutes Played', field: 'minPlayed' },
];

export const DEFENDERS_POS = ['DR', 'DC', 'DL'];
export const MIDFIELDERS_POS = [
  'DMC',
  'DML',
  'DMR',
  'AMC',
  'AML',
  'AMR',
  'MC',
  'ML',
  'MR',
];
export const FORWARDS_POS = ['FW', 'FWL', 'FWR'];
export const SUB_POS = 'Sub';
export const POS_COORDINATES = {
  AMC: { x: 5.0, y: 6.75 },
  AML: { x: 8.0, y: 7.0 },
  AMR: { x: 2.0, y: 7.0 },
  DC: { x: 5.0, y: 2.5 },
  DL: { x: 9.0, y: 2.5 },
  DMC: { x: 5.0, y: 4.5 },
  DML: { x: 9.0, y: 5.25 },
  DMR: { x: 1.0, y: 5.25 },
  DR: { x: 1.0, y: 2.5 },
  FW: { x: 5.0, y: 9.0 },
  FWL: { x: 9.0, y: 8.65 },
  FWR: { x: 1.875, y: 8.65 },
  GK: { x: 5.0, y: 0.0 },
  MC: { x: 5.0, y: 5.5 },
  ML: { x: 8.5, y: 6.0 },
  MR: { x: 1.5, y: 6.0 },
};

export const POSITIONS = [
  'AMC',
  'AML',
  'AMR',
  'DC',
  'DL',
  'DMC',
  'DML',
  'DMR',
  'DR',
  'FW',
  'FWL',
  'FWR',
  'GK',
  'MC',
  'ML',
  'MR',
];
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER';
export const ROLES = [ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_USER];

export const productImages = {
  'player-rating': '/media/assets/player-profile.png',
  'player-search': '/media/assets/players-search.png',
  'similar-player-search': '/media/assets/similar-players.png',
  'player-impact-evaluation': '/media/assets/player-impact.png',
  'real-analytics-match-lab': '/media/assets/p5-matchlab.png',
  'compare-players': '/media/assets/compare-players.png',
  'squad-analysis': '/media/assets/squad-analysis.png',
  'match-prediction': '/media/assets/match-prediction.png',
  'gbe-calculator': '/media/assets/gbe-calculator.png',
  'match-lab': '/media/assets/match-lab.png',
};

export const WITHOUT_REFERENCE_RANKINGS = [
  'performance',
  'international_experience',
  'growth',
];

export const ATTRIBUTE_RATINGS_TO_EXCLUDE = [
  'market_value',
  'international_experience',
  'work_rate',
];

console.log(`Application version: ${process.env.APP_VERSION}`);

export const LAB_SIZES = { xMax: 1100, yMax: 700 };
