import React from 'react';
import PropTypes from 'prop-types';

function Tshirt({ height, width, fill }) {
  return (
    <svg
      height={height}
      viewBox='0 0 512 512'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='flat'>
        <path
          d='m465.21 192-73.21 28.85-8 3.15v264h-256v-264l-8-3.2-72-28.8 34.05-108.97a40.033 40.033 0 0 1 24.73-25.74l70.68-25.24 22.54-8.05h113.21l21.37 7.63 71.85 25.66a40.033 40.033 0 0 1 24.73 25.74z'
          fill={fill}
        />
        <path
          d='m334.58 31.63-.06.67a78.844 78.844 0 0 1 -157.04 0l-.02-.25 22.54-8.05h113.21z'
          fill='#f3f3f3'
        />
        <path
          d='m150.66 132.44-14.66 21.98v72.58l-16-6.2v-68.8a8.066 8.066 0 0 1 1.34-4.44l16-24z'
          fill='#e4e4e2'
        />
        <path
          d='m392 152v68.85l-16 6.15v-72.73l-13.6-22.07 13.62-8.4 14.79 24a7.99 7.99 0 0 1 1.19 4.2z'
          fill='#e4e4e2'
        />
        <path
          d='m256 72a48 48 0 0 1 -48-48h96a48 48 0 0 1 -48 48z'
          fill='#c3c3c3'
        />
      </g>
    </svg>
  );
}

Tshirt.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default Tshirt;
