import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Calendar.scss';
import { GoCalendar } from 'react-icons/go';
import RCalendar from 'react-calendar';
import format from 'date-fns/format';
function Calendar({
  onChangeDays,
  onChangeDate,
  date,
  withIcon = true,
  style,
}) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className='box_component_box-header-calendar'>
        <div
          className='box_component_box-header-calendar-prev-week'
          onClick={() => onChangeDays(-7)}
        >
          {'<<'}
        </div>
        <div
          className='box_component_box-header-calendar-prev-day'
          onClick={() => onChangeDays(-1)}
        >
          {'<'}
        </div>
        <div
          className='box_component_box-header-calendar-day bold'
          style={style}
        >
          {withIcon && (
            <GoCalendar
              size='30px'
              style={{ cursor: 'pointer' }}
              onClick={() => setVisible(!visible)}
            />
          )}
          {date ? format(new Date(date), 'iii, LLL dd yyyy') : '-'}
        </div>
        <div
          className='box_component_box-header-calendar-next-day'
          onClick={() => onChangeDays(1)}
        >
          {'>'}
        </div>
        <div
          className='box_component_box-header-calendar-next-week'
          onClick={() => onChangeDays(7)}
        >
          {'>>'}
        </div>
      </div>
      {visible && (
        <div className='calendarBox'>
          <RCalendar
            onChange={(date) => {
              onChangeDate(date);
              setVisible(false);
            }}
            value={new Date(date)}
            showNavigation={true}
          />
        </div>
      )}
    </>
  );
}

Calendar.propTypes = {
  date: PropTypes.any,
  onChangeDate: PropTypes.func,
  onChangeDays: PropTypes.func,
  withIcon: PropTypes.bool,
  style: PropTypes.object,
};

export default Calendar;
