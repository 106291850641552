import React from 'react';
import styled from '@emotion/styled';
import { AnchorLink, Button } from 'components/styled';
import HomeNavbar from './HomeNavbar';

const Container = styled.section`
  min-height: 37em;
  background-image: url('/media/assets/section-bg1.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
    margin-top: 100px;
    margin-left: 90px;
    color: white;
    .title {
      font-size: 4rem;
      font-weight: 100;
      font-family: 'SF UI Display Ultralight', 'sans-serif';
      strong {
        font-family: 'SF UI Display Bold', 'sans-serif';
      }
    }
    img {
      filter: brightness(0) invert(1);
      margin-top: 10px;
      width: 40px;
    }
    .actions {
      a,
      button {
        text-align: center;
      }
    }
  }
  @media (max-width: 768px) {
    main {
      margin-left: 25px;
      .title {
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 425px) {
    main {
      margin-left: 25px;
      .title {
        font-size: 2.5rem;
      }
      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        button,
        a {
          width: 140px;
          margin-top: 5px;
        }
        a {
          margin-left: 0px !important;
        }
      }
    }
  }
`;
function Intro() {
  return (
    <Container id='intro'>
      <HomeNavbar />
      <main>
        <div className='title'>
          <strong>Artificial intelligence aiding the decision making process in football</strong>
        </div>
        <div className='actions'>
          <Button
            variant='transparent'
            onClick={() =>
              document.querySelector('#contactUs').scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            Contact Us
          </Button>
          <AnchorLink to={'/login'} style={{ marginLeft: 10 }}>
            Login
          </AnchorLink>
        </div>
      </main>
    </Container>
  );
}

Intro.propTypes = {};

export default React.memo(Intro);
