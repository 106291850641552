import React from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';

const Container = styled.section`
  background: #f4f5ff;
  padding: 3rem 0;
  h2 {
    text-transform: uppercase;
    color: black;
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    font-family: 'SF UI Display Ultralight';
    strong {
      font-family: 'SF UI Display Bold';
    }
  }
  h3 {
    margin-top: 4rem;
    font-family: 'SF UI Display Bold';
  }

  p {
    font-family: 'SF UI Display Regular';
    &.italic {
      font-style: italic;
      color: ${colors.base};
    }
  }
`;

function OurResearch() {
  return (
    <Container>
      <div className='container-fluid'>
        {/* Title */}
        <h2 id="ourResearch">
          Our <strong>research</strong>
        </h2>
        {/* Description */}
        <h3>Prediction</h3>
        <p>
          Our background is in prediction modelling where we boast industry leading research and practical experience in
          the field of forecasting in football.
        </p>
        <p className='mt-4 italic'>
          <a href="https://blogs.salford.ac.uk/business-school/wp-content/uploads/sites/7/2016/09/paper.pdf">Boshnakov, G., Kharrat, T. and McHale, I.G. (2017). &ldquo;A bivariate
          Weibull count model for forecasting Association Football
          scores&ldquo;, International Journal of Forecasting, 33, 458-466.
          </a>
        </p>

        <h3>Player and team ratings</h3>
        <p>
          Our team has been working on methods to rate players in sport for over 20 years, and we are proud that the
          latest developments in this area of research are still being produced by members of the team.
        </p>
        <p className='mt-4 italic'>
          <a href="https://www.springerprofessional.de/en/deep-soccer-analytics-learning-an-actionvalue-
function-for-eval/18203810">Liu, G., Luo, Y., Schulte, O. and Kharrat, T. (2020). “Deep soccer analytics: learning an action-value function for
evaluating soccer players”. Data Mining and Knowledge Discovery, 34(5): 1531-59.</a>
        </p>
        <p className='mt-4 italic'>
          <a href="https://www.sciencedirect.com/science/article/abs/pii/S0377221718300365">McHale, I.G. and Relton S. (2018). “Identifying key players in soccer teams using network analysis and pass
          difficulty”, European Journal of Operational Research. 268(1), 339-347.</a>
        </p>
        <p className='mt-4 italic'>
          <a href="(https://www.sciencedirect.com/science/article/abs/pii/S0377221719309373">Kharrat, T.,McHale, I. G., and Peña, J. L.(2020).“Plus–minus player ratings for soccer”. European Journal of
Operational Research, 283(2), 726-736.</a>
        </p>
      </div>
    </Container>
  );
}

export default React.memo(OurResearch);
