import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';
// elastic_search_player        GET   /api/v1/search/player/{term}
// elastic_search_team          GET   /api/v1/search/team/{term}
// elastic_search_competition   GET   /api/v1/search/competition/{term}

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/resources';

export const download = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD,
    xhr: { req: () => Axios.get(`${BASE_URL}/download/${id}`) },
  });
};

export const upload = (file, oldResource) => (dispatch) => {
  if (oldResource && oldResource.id) {
    dispatch(removeFile(oldResource.id));
  }

  const form = createForm(file);
  return dispatch({
    type: actionTypes.UPLOAD,
    xhr: { req: () => Axios.post(`${BASE_URL}/upload`, form) },
  });
};

export const removeFile = (id) => (dispatch) => {
  return dispatch({
    type: actionTypes.REMOVE_FILE,
    xhr: { req: () => Axios.delete(`${BASE_URL}/delete/${id}`) },
  });
};

function createForm(file) {
  var fd = new FormData();
  fd.append('file', file);
  fd.append('subdir', 'users');
  return fd;
}
