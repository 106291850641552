import React, { useState, Fragment } from 'react';
import styled from '@emotion/styled';
import orderBy from 'lodash-es/orderBy';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'components/icons';
import { makeHeaderGroups, flattenDataColumns } from 'utils/helpers';

const Sorter = styled.div`
  &.sortable:hover {
    .no-sort {
      opacity: 0.4;
    }
  }
`;

function Datatable({
  table = { className: 'table table-striped' },
  body,
  row = () => { },
  columns = [],
  data = [],
  itemKey = 'id',
  headless = false,
  extraRow,
  defaultSort = {},
}) {
  const [sort, setSort] = useState(defaultSort);
  // asc => desc => null
  const handleSort = ({ sorter, accessor }) => {
    const theSorter = sorter || accessor;
    const order =
      sort.by !== theSorter ? 'asc' : sort.order === 'asc' ? 'desc' : null;
    setSort({
      by: order === null ? null : theSorter,
      order,
    });
  };
  return (
    <table {...table}>
      {!headless && <thead>{_renderColumns(columns)}</thead>}
      <tbody style={{ fontSize: '15px' }} {...body}>
        {
        data.sort((firstItem,secondItem) => {
          const a = get(firstItem, sort.by, null);
          const b = get(secondItem, sort.by, null); 
          if (a === b) {
            return 0;
          }
          // nulls sort after anything else
          else if (a === null) {
            return 1;
          } else if (b === null) {
            return -1;
          }
          // otherwise, if we're ascending, lowest sorts first
          else if (sort.order === 'asc') {
            return a < b ? -1 : 1;
          }
          // if descending, highest sorts first
          else {
            return a < b ? 1 : -1;
          }
        })  
        .map((item, index) => {
          const rowKey =
            typeof itemKey === 'function' ? itemKey(item) : get(item, itemKey);
          return (
            <Fragment key={rowKey}>
              <tr {...row(item, index)}>
                {flattenDataColumns(columns).map((column) => {
                  const columnKey =
                    typeof column.title === 'string'
                      ? column.title
                      : column.key;

                  return (
                    <td key={columnKey + rowKey} {...column.cell}>
                      {typeof column.accessor === 'function'
                        ? column.accessor(item, index)
                        : get(item, column.accessor)}
                    </td>
                  );
                })}
              </tr>
              {extraRow ? (
                <tr>
                  <td colSpan={extraRow.colSpan} style={{ padding: 0 }}>
                    {extraRow.render(item, index)}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );

  function _renderColumns(columns) {
    const headerGroups = makeHeaderGroups(columns);
    return Object.values(headerGroups).map((headerGroup, index) => (
      <tr key={index} style={{ fontSize: '15px' }}>
        {headerGroup.map((column, colIndex) => {
          const theSorter = column.sorter || column.accessor;

          return (
            <th
              key={colIndex + index + column.title}
              colSpan={column.colspan}
              scope='col'
              onClick={column.sortable ? () => handleSort(column) : null}
              {...column.header}
              className={cx(get(column, 'header.className'), {
                pointer: column.sortable,
              })}
            >
              <Sorter
                className={cx({
                  sortable: column.sortable,
                })}
              >
                <span>{column.title}</span>
                {theSorter === sort.by && sort.order === 'desc' ? (
                  <MdKeyboardArrowDown />
                ) : theSorter === sort.by && sort.order === 'asc' ? (
                  <MdKeyboardArrowUp />
                ) : null}
              </Sorter>
            </th>
          );
        })}
      </tr>
    ));
  }
}

Datatable.propTypes = {
  table: PropTypes.object,
  row: PropTypes.func,
  body: PropTypes.object,
  data: PropTypes.array,
  columns: PropTypes.array,
  extraRow: PropTypes.shape({
    render: PropTypes.func,
    colSpan: PropTypes.number,
  }),
  headless: PropTypes.bool,
  defaultSort: PropTypes.object,
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
export default React.memo(Datatable);
