import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useDebounce from 'hooks/use-debounce';
import colors from 'styles/colors';
import { Img, LoadingDots } from './shared';
import { on, off } from 'utils/helpers';
import cx from 'classnames';
import useClickAway from 'hooks/use-click-away';
import AbsoluteContainer from './shared/Dropdown/AbsoluteContainer';
import { DEFAULT_TEAM_LOGO } from 'constants/env';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-content: center;

  span {
    position: absolute;
    width: 14%;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
  }
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  width: 95%;
  &.icon {
    padding-left: 15.5%;
  }
`;

const Suggestions = styled.ul`
  position: absolute;
  width: 100%;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  color: ${colors.text};
  border: 1px solid #ccc;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  > li {
    border-radius: 5px;
    padding: 4px 5px;
    min-width: 150px;
    display: flex;
    align-items: center;
    &.active {
      background: ${colors.text};
      color: white;
    }
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
    &:not(.disabled):hover {
      background: #45c2c6;
      color: white;
    }
  }
`;

function AutocompleteInput({
  label,
  isLoading,
  onChangeTerm = () => {},
  renderImg,
  className,
  suggestions = [],
  maxSuggestions = 10,
  onSelect,
  debounce = 350,
  defaultValue = '',
  clearAfterSelect,
  ...rest
}) {
  const [term, setTerm] = useState(defaultValue);
  const searchRef = useRef();
  const containerEl = useRef();
  const suggestionRef = useRef();
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showSuggestion, setShowSuggestion] = useState(
    !!getSuggestions().length
  );
  useEffect(() => {
    const handler = () => {
      setTerm('');
    };
    on(searchRef.current, 'search', handler);
    return () => {
      off(searchRef.current, 'search', handler);
    };
  }, []);

  useDebounce(
    () => {
      setDebouncedTerm(term);
      onChangeTerm(term);
    },
    debounce,
    [term]
  );

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  useEffect(() => {
    if (defaultValue && !term) setTerm(defaultValue);
  }, [defaultValue]);

  const handleSelect = (index) => {
    const currentList = getSuggestions();
    onSelect(currentList[index]);
    setShowSuggestion(false);

    if (clearAfterSelect) setTerm('');
    else setTerm(currentList[index].searchLabel);
  };

  const handleOnChange = useCallback(({ target }) => {
    setShowSuggestion(true);
    setTerm(target.value);
    if (!target.value) setActiveIndex(-1);
  }, []);

  const handleOnKeyDown = useCallback(
    (event) => {
      const { key } = event;
      const suggestionList = getSuggestions();
      switch (key) {
        case 'ArrowUp':
          // Prevent input cursor from going to the beginning when pressing up.
          event.preventDefault();
          setActiveIndex(
            (activeIndex - 1 + suggestionList.length) % suggestionList.length
          );
          break;
        case 'ArrowDown':
          // Prevent input cursor from going to the beginning when pressing up.
          event.preventDefault();
          setActiveIndex((activeIndex + 1) % suggestionList.length);
          break;
        case 'Enter':
          // Prevent form submission while menu is open.
          event.preventDefault();
          event.stopPropagation();
          activeIndex !== -1 && handleSelect(activeIndex);
          break;
        case 'Escape':
        case 'Tab':
          // ESC simply hides the menu. TAB will blur the input and move focus to
          // the next item; hide the menu so it doesn't gain focus.
          setShowSuggestion(false);
          break;
        default:
          break;
      }
    },
    [activeIndex, suggestions]
  );

  useClickAway([containerEl.current], () => {
    setShowSuggestion(false);
  });

  return (
    <div
      className='position-relative'
      style={{ width: '100%' }}
      ref={containerEl}
    >
      {label && (
        <div>
          <label htmlFor='search'>{label}</label>
        </div>
      )}
      <FormGroup>
        {renderImg && (
          <span>{renderImg({ term, suggestions: getSuggestions() })}</span>
        )}
        <SearchInput
          id='search'
          type='search'
          autoComplete='off'
          onChange={handleOnChange}
          onFocus={() => setShowSuggestion(true)}
          value={term}
          ref={searchRef}
          onKeyDown={handleOnKeyDown}
          className={cx(className, {
            icon: !!renderImg,
          })}
          {...rest}
        />
      </FormGroup>
      <AbsoluteContainer
        container={suggestionRef.current}
        trigger={searchRef.current}
        visible={term && showSuggestion}
        style={{ zIndex: 11 }}
      >
        <Suggestions ref={suggestionRef}>
          {debouncedTerm !== term || isLoading ? (
            <li className='disabled justify-content-center pt-3 pb-3'>
              <LoadingDots />
            </li>
          ) : debouncedTerm === term &&
            term &&
            getSuggestions().length === 0 ? (
            <li className='disabled justify-content-center pt-3 pb-3'>
              No matching results
            </li>
          ) : (
            getSuggestions().map((item, index) => (
              <li
                key={item.searchValue}
                tabIndex={0}
                className={cx({
                  active: index === activeIndex,
                })}
                onClick={() => handleSelect(index)}
              >
                {item.searchImage && item.searchImage}
                <span
                  title={item.searchLabel}
                  className='ml-2 overflow-ellipsis'
                >
                  {item.searchLabel}
                  {item.searchType === 'PLAYER' && (
                    (
                      <>
                      (
                      <Img
                      src={item?.teamLogoPath}
                      alt={item?.teamName}
                      defaultImage={DEFAULT_TEAM_LOGO}
                      />
                      <span>
                       {item.teamName}
                      </span>
                      )  
                      </>
                    )
                  )}
                </span>
              </li>
            ))
          )}
        </Suggestions>
      </AbsoluteContainer>
    </div>
  );

  function getSuggestions() {
    return suggestions.slice(0, maxSuggestions);
  }
}

AutocompleteInput.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  maxSuggestions: PropTypes.number,
  debounce: PropTypes.number,
  suggestions: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onChangeTerm: PropTypes.func,
  clearAfterSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderImg: PropTypes.func,
  className: PropTypes.string,
};

export default memo(AutocompleteInput);
