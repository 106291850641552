import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { mapToDropdown } from 'utils/helpers';
import { orderBy } from 'lodash-es';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.competitions,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_COMPETITION, STATUSES.REQUEST):
    case getActionType(
      actionTypes.FETCH_ACCESSIBLE_COMPETITIONS,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_COMPETITIONS, STATUSES.REQUEST):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(
      actionTypes.FETCH_COMPETITION_BY_COUNTRY,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        byCountry: {
          ...state.byCountry,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_COMPETITION, STATUSES.ERROR):
    case getActionType(
      actionTypes.FETCH_ACCESSIBLE_COMPETITIONS,
      STATUSES.ERROR
    ):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.FETCH_COMPETITIONS, STATUSES.ERROR):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(
      actionTypes.FETCH_COMPETITION_BY_COUNTRY,
      STATUSES.ERROR
    ):
      return {
        ...state,
        byCountry: {
          ...state.byCountry,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };
    case getActionType(actionTypes.FETCH_COMPETITION, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          competition: payload,
        },
      };
    case getActionType(
      actionTypes.FETCH_ACCESSIBLE_COMPETITIONS,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          accessible: payload,
          dropdown: orderBy(
            mapToDropdown(payload, 'name', 'competitionId', 'competitionId'),
            ['name'],
            ['asc']
          ),
        },
      };
    case getActionType(actionTypes.FETCH_TOP_COMPETITIONS, STATUSES.REQUEST):
      return {
        ...state,
        topLeagues: {
          ...state.topLeagues,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_TOP_COMPETITIONS, STATUSES.SUCCESS):
      return {
        ...state,
        topLeagues: {
          ...state.topLeagues,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
          dropdown: orderBy(
            mapToDropdown(payload, 'name', 'competitionId', 'competitionId'),
            ['name'],
            ['asc']
          ),
        },
      };
    case getActionType(actionTypes.FETCH_TOP_COMPETITIONS, STATUSES.ERROR):
      return {
        ...state,
        topLeagues: {
          ...state.topLeagues,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    case getActionType(
      actionTypes.FETCH_COMPETITION_BY_COUNTRY,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        byCountry: {
          ...state.byCountry,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_COMPETITIONS, STATUSES.SUCCESS):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };

    default:
      return state;
  }
}
