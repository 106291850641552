import React from 'react';
import 'pages/Home.scss';
import ContactUsForm from './ContactUsForm';
import useContactUs from 'hooks/home/use-contact-us';

function ContactUs() {
  const { error, onSubmit, onVerify, recaptcha } = useContactUs();
  return (
    <section className={'contact'}>
      <div className='container-fluid'>
        <h1 id='contactUs' className='mt-20'>
          <strong>Contact</strong> US
        </h1>
        <ContactUsForm
          onSubmit={onSubmit}
          onVerify={onVerify}
          ref={recaptcha}
          error={error}
        />
      </div>
    </section>
  );
}

export default ContactUs;
