import React, { memo } from 'react';

function RaFifa(props) {
  return (
    <svg
      version='1.0'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 500.000000 251.000000'
      preserveAspectRatio='xMidYMid meet'
      {...props}
      fill='currentColor'
      stroke='none'
    >
      <g
        transform='translate(0.000000,251.000000) scale(0.100000,-0.100000)'
        fill='currentColor'
        stroke='none'
      >
        <path
          d='M400 1255 l0 -685 215 0 215 0 0 245 0 245 153 0 152 0 57 148 56
147 -209 3 -209 2 0 140 0 140 257 2 257 3 53 140 c28 77 52 143 52 148 1 4
-235 7 -524 7 l-525 0 0 -685z'
        />
        <path
          d='M1597 1933 c-4 -3 -7 -312 -7 -685 l0 -678 215 0 215 0 0 685 0 685
-208 0 c-115 0 -212 -3 -215 -7z'
        />
        <path
          d='M2320 1255 l0 -685 215 0 215 0 0 245 0 244 151 3 151 3 58 148 58
147 -209 0 -209 0 0 140 0 140 254 0 c232 0 254 1 261 18 4 9 26 69 50 132 24
63 46 123 50 133 7 16 -22 17 -519 17 l-526 0 0 -685z'
        />
        <path
          d='M3655 1923 c-3 -10 -111 -310 -240 -668 -128 -357 -237 -658 -240
-667 -7 -17 7 -18 197 -18 l205 0 24 88 24 87 247 3 247 2 27 -90 27 -90 209
0 c114 0 208 2 208 4 0 4 -470 1309 -485 1349 -7 15 -27 17 -225 17 -198 0
-218 -2 -225 -17z m310 -630 l83 -278 -166 -3 c-90 -1 -167 0 -170 3 -5 5 144
538 155 558 3 5 7 6 10 3 3 -2 42 -130 88 -283z'
        />
      </g>
    </svg>
  );
}

export default memo(RaFifa);
