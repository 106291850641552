import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import PropTypes from 'prop-types';
import SelectableDropdown from './SelectableDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAccessibleCompetitions } from 'store';
import Img from './Img';
import { DEFAULT_LEAGUE_LOGO } from 'constants/env';
import Dot from '../shared/Dot';
import { LOADING_STATUS } from 'store/actionTypes';
import { isFunction, get } from 'lodash-es';

const ITEM_PROPS = {
  closeOnClick: false,
  className: 'no-deco',
};
function LeaguesDrowdown({
  value,
  source = { fetcher: fetchAccessibleCompetitions, path: 'data' }, // pass false to prevent refetch
  toggleStyle = {
    width: '100%',
    border: 'solid 1px #ABBFDA',
  },
  onChange,
  ...rest
}) {
  const competitions = useSelector((state) => state.competitions);
  const dispatch = useDispatch();
  const toggleProps = useMemo(
    () => ({ className: 'btn btn-light mr-2', style: toggleStyle }),
    [toggleStyle]
  );
  const dataPath = `${source.path}.dropdown`;
  const statusPath = source.path ? `${source.path}.status` : 'status';
  useEffect(() => {
    if (
      !get(competitions, `${dataPath}.length`) &&
      isFunction(get(source, 'fetcher'))
    )
      dispatch(source.fetcher());
  }, []);

  return (
    <SelectableDropdown
      title={Title}
      name='league'
      renderItem={Option}
      options={get(competitions, dataPath)}
      loading={get(competitions, statusPath) === LOADING_STATUS}
      toggle={toggleProps}
      required
      value={value}
      itemProps={ITEM_PROPS}
      onSelect={onChange}
      {...rest}
    />
  );
}

function Title({ selected }) {
  return (
    <>
      {Array.isArray(selected) ? (
        selected.length ? (
          selected.map((it) => (
            <span key={it.value} className='mr-2'>
              <Img
                width='20'
                src={it.competitionLogoPath}
                alt={it.value}
                defaultImage={DEFAULT_LEAGUE_LOGO}
              />
              <span className='ml-2'>{it.label}</span>
            </span>
          ))
        ) : (
          <span className='ml-2'>Select League</span>
        )
      ) : !selected ? (
        <span>Select League</span>
      ) : (
        <>
          <Img
            width='20'
            src={selected.competitionLogoPath}
            alt={selected.value}
            defaultImage={DEFAULT_LEAGUE_LOGO}
          />
          <span className='ml-2'>{selected.label}</span>
        </>
      )}
    </>
  );
}

const OptionItem = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
`;

function Option({ option, selected }) {
  return Array.isArray(selected) ? (
    <OptionItem className='text-base d-flex align-items-center'>
      <Dot
        className='mr-2'
        variant={
          selected.includes(option.value) ? 'big-checked' : 'big-unchecked'
        }
      />
      <span className='mr-2'>
        <Img
          width='20'
          src={option.competitionLogoPath}
          alt={option.value}
          defaultImage={DEFAULT_LEAGUE_LOGO}
        />
        <span className='ml-2 overflow-ellipsis' title={option.label}>
          {option.label}
        </span>
      </span>
    </OptionItem>
  ) : (
    <OptionItem className='text-base'>
      <Img
        width='20'
        src={option.competitionLogoPath}
        alt={option.value}
        defaultImage={DEFAULT_LEAGUE_LOGO}
      />
      <span className='ml-2'>{option.label}</span>
    </OptionItem>
  );
}

LeaguesDrowdown.propTypes = {
  onChange: PropTypes.func,
  source: PropTypes.object,
  value: PropTypes.any,
  toggleStyle: PropTypes.object,
};
Title.propTypes = {
  selected: PropTypes.any,
};
Option.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.any,
};
export default React.memo(LeaguesDrowdown);
