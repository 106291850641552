import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/teams';

export const fetchTeamSquadOverview = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_TEAM_SQUAD_OVERVIEW,
    xhr: { req: () => Axios.get(`${BASE_URL}/${teamId}/squad-overview`) },
  });
};

export const fetchTeamLatestSquad = (teamId, gameId) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_TEAM_LATEST_SQUAD,
    payload: { teamId },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/latest-squad/${gameId}`),
      mapper: (res) => ({
        teamId,
        formation: res.data.formation,
        squad: res.data.players,
        normalizedPositions: getState().formations.normalizedPositions,
      }),
    },
  });
};

export const fetchTeamSummary = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_SUMMARY,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/summary`),
      // mapper: (res) => head(res.data),
    },
  });
};

export const fetchDescriptiveStats = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_DESCRIPTIVE_STATS,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/descriptive-stats`),
    },
  });
};

export const fetchLatestResults = (teamId, limit) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_LATEST_RESULTS,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/latest-results/${limit}`),
    },
  });
};

export const fetchStatusWithinLeague = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_STATUS_WITHIN_LEAGUE,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/status-within-league`),
    },
  });
};

export const fetchExpectedLeaguePosition = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_EXPECTED_LEAGUE_POSITION,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${teamId}/expected-league-position`),
    },
  });
};

export const fetchTeamsByCompetition = (competitionId, filter = true) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_TEAMS,
    xhr: {
      req: () =>
        Axios.get(
          `/api/v1/competitions/${competitionId}/teams?filter=${filter}`
        ),
      mapper: (res) => ({ data: res.data, competitionId }),
    },
  });
};

export const fetchPriorityAndVulnerabilityAreas = (teamId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_PRIORITY_AND_VULNERABILITY_AREAS,
    xhr: {
      req: () =>
        Axios.get(`${BASE_URL}/${teamId}/priority-and-vulnerability-areas`),
    },
  });
};
