import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectableDropdown from './SelectableDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeamsByCompetition } from 'store';
import { Img } from 'components/shared';
import { DEFAULT_TEAM_LOGO } from 'constants/env';
import { LOADING_STATUS } from 'store/actionTypes';
import { values, flatten, pick } from 'lodash-es';
import Dot from './Dot';

const ITEM_PROPS = {
  closeOnClick: false,
  className: 'no-deco',
};
function TeamsDropdown({
  onChange,
  competitionIds = [],
  value,
  toggleStyle = {
    width: '100%',
    border: 'solid 1px #ABBFDA',
  },
  ...rest
}) {
  const teams = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  const toggleProps = useMemo(
    () => ({ className: 'btn btn-light mr-2', style: toggleStyle }),
    [toggleStyle]
  );

  useEffect(() => {
    competitionIds.map((competitionId) => {
      if (!teams.data.dropdownMulti[competitionId])
        dispatch(fetchTeamsByCompetition(competitionId, false));
    });
  }, [competitionIds]);

  return (
    <SelectableDropdown
      title={Title}
      name="teams"
      renderItem={Option}
      loading={teams.status === LOADING_STATUS}
      options={flatten(values(pick(teams.data.dropdownMulti, competitionIds)))}
      toggle={toggleProps}
      required
      value={value}
      onSelect={onChange}
      itemProps={ITEM_PROPS}
      {...rest}
    />
  );
}

function Title({ selected }) {
  return selected.length ? (
    selected.map((it) => (
      <span key={it.value} className="mr-2">
        <Img
          width="20"
          src={it.teamLogoPath}
          alt={it.value}
          defaultImage={DEFAULT_TEAM_LOGO}
        />
        <span className="ml-2">{it.label}</span>
      </span>
    ))
  ) : (
    <span className="ml-2">Select Team</span>
  );
}
function Option({ option, selected }) {
  return (
    <div
      style={{ padding: 5, width: '100%' }}
      className="text-base d-flex align-items-center"
    >
      <div className="mr-2">
        <Dot
          variant={
            selected.includes(option.value) ? 'big-checked' : 'big-unchecked'
          }
        />
      </div>
      <span key={option.value} className="mr-2">
        <Img
          width="20"
          src={option.teamLogoPath}
          alt={option.value}
          defaultImage={DEFAULT_TEAM_LOGO}
        />
        <span className="ml-2">{option.label}</span>
      </span>
    </div>
  );
}

TeamsDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  competitionIds: PropTypes.array,
  toggleStyle: PropTypes.object,
};

Title.propTypes = {
  selected: PropTypes.any,
};

Option.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.any,
};

export default React.memo(TeamsDropdown);
