import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Img } from './index';
import { DEFAULT_TEAM_LOGO } from 'constants/env';
import { compareString } from 'utils/helpers';

function ACTeamPic({ term, suggestions }) {
  const item = term
    ? suggestions.find((s) => compareString(s.searchLabel, term))
    : null;
  return (
    <Img
      src={get(item, 'teamLogoPath')}
      defaultImage={DEFAULT_TEAM_LOGO}
      alt={get(item, 'searchLabel')}
      width={30}
      height={30}
    />
  );
}

ACTeamPic.propTypes = {
  term: PropTypes.string,
  suggestions: PropTypes.array,
};

export default ACTeamPic;
