import React, { useMemo, useEffect } from 'react';
import useAsync from 'hooks/use-async';
import { getApplications } from 'store';
import { usePersistentState } from 'hooks/use-persisted-state';
import { ACCESSIBLE_APPLICATIONS_KEY } from 'constants/env';
import storage from 'utils/storage';
import { useAuth } from 'context/auth-context';
const AppsContext = React.createContext();

function AppsProvider(props) {
  const { user } = useAuth();
  const [apps, setApps] = usePersistentState(ACCESSIBLE_APPLICATIONS_KEY, []);
  const { isLoading, run } = useAsync();
  useEffect(() => {
    if (user) loadApps();
  }, [user]);

  const loadApps = async () => {
    const data = await run(getApplications());
    setApps(data);
  };

  const clearApps = () => {
    storage.remove(ACCESSIBLE_APPLICATIONS_KEY);
  };
  const hasAccess = (application) =>
    apps.map((it) => it.name).includes(application);

  const context = useMemo(
    () => ({
      apps,
      clearApps,
      loadApps,
      isLoading,
      hasAccess,
    }),
    [apps, isLoading]
  );

  return <AppsContext.Provider value={context} {...props} />;
}

/**
 * @returns {{apps:Array, clearApps:Function, loadApps:Function, isLoading: Boolean, hasAccess:Function }} context

 */
function useApps() {
  const context = React.useContext(AppsContext);
  if (context === undefined) {
    throw new Error('useApps must be used within a AppsProvider');
  }
  return context;
}

export { AppsProvider, useApps };
