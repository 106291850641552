import React, { useEffect, useState, memo } from 'react';
import { Title, Content, Sliders } from './styles';
import { AxiosRepo } from 'utils/axios';
import { Spinner } from 'components/shared';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Video from '../shared/Video';
import { useParams } from 'react-router-dom';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        arrows: false,
      },
    },
  ],
};

function MainProduct() {
  const { product } = useParams();
  const [page, setPage] = useState(null);
  useEffect(() => {
    loadPage();
  }, [product]);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
          {page ? (
            <>
              <Title>{page.name}</Title>
              <Content
                dangerouslySetInnerHTML={{ __html: page.description }}
              ></Content>
            </>
          ) : (
            <Spinner fontSize={32} />
          )}
        </div>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center'>
          <Sliders>
            <Slider {...settings}>
              <Video variant='medium' />
              <Video variant='medium' />
            </Slider>
          </Sliders>
        </div>
      </div>
    </div>
  );

  function loadPage() {
    AxiosRepo.getPublicInstance()
      .get(`/api/v1/pages/by-slug/${product}`)
      .then(({ data }) => {
        setPage(data);
      });
  }
}

MainProduct.propTypes = {};

export default memo(MainProduct);
