import { DEFAULT_PLAYER_LOGO, ACCESS_TOKEN_KEY } from 'constants/env';
import { isNil, isObject, get } from 'lodash-es';
import PropTypes from 'prop-types';
import storage from 'utils/storage';

import React, { useState, useEffect, memo, useRef } from 'react';

import ImgLoader from './ImgLoader';

function Img({
  withoutLoader = false,
  defaultImage = DEFAULT_PLAYER_LOGO,
  width,
  variant,
  src,
  height,
  ...rest
}) {
  const [imageSrc, setImageSrc] = useState();
  const imageRef = useRef();
  const [loaded, setLoaded] = useState(false);

  const resolveImgSrc = () => {
    if (isNil(src)) {
      return defaultImage;
    } else if (isObject(src)) {
      return `/api/v1/resources/download/${src.id}?access_token=${storage.get(
        ACCESS_TOKEN_KEY
      )}`;
    } else {
      return src;
    }
  };

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setImageSrc(defaultImage);
    setLoaded(true);
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageSrc !== resolveImgSrc()) {
      if (imageRef.current && IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(resolveImgSrc());
                observer.unobserve(imageRef.current);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );
        observer.observe(imageRef.current);
      } else {
        // Old browsers fallback
        setImageSrc(resolveImgSrc());
      }
    }
    return () => {
      didCancel = true;
      // on component cleanup, we remove the listner
      if (observer && observer.unobserve && imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src, imageRef.current]);

  return (
    <>
      {!loaded && !withoutLoader && (
        <ImgLoader
          style={{ width: width ?? height, height: height ?? width }}
          variant={variant}
        />
      )}
      <img
        ref={imageRef}
        src={imageSrc}
        {...rest}
        onLoad={onLoad}
        onError={onError}
        width={loaded ? width : 0}
        height={loaded ? height : 0}
        style={{
          ...get(rest, 'style'),
          visibility: loaded ? 'visible' : 'hidden',
          maxWidth: loaded ? undefined : 0,
          maxHeight: loaded ? undefined : 0,
        }}
        loading='lazy'
      />
    </>
  );
}

Img.propTypes = {
  withoutLoader: PropTypes.bool,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultImage: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(Img);
