import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import matchs from 'store/modules/matchs/reducer';
import search from 'store/modules/search/reducer';
import players from 'store/modules/players/reducer';
import teams from 'store/modules/teams/reducer';
import competitions from 'store/modules/competitions/reducer';
import comparePlayers from 'store/modules/compare-players/reducer';
import playerImpact from 'store/modules/player-impact/reducer';
import matchPrediction from 'store/modules/match-prediction/reducer';
import customers from 'store/modules/customers/reducer';
import pages from 'store/modules/pages/reducer';
import user from 'store/modules/user/reducer';
import resource from 'store/modules/resource/reducer';
import applications from 'store/modules/applications/reducer';
import similarPlayers from 'store/modules/similar-players/reducer';
import navigation from 'store/modules/navigation/reducer';
import gbePointsCalculator from 'store/modules/gbe-points-calculator/reducer';
import bets from 'store/modules/bets/reducer';
import markets from 'store/modules/markets/reducer';
import formations from 'store/modules/formations/reducer';
import predictions from 'store/modules/predictions/reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    navigation,
    matchs,
    search,
    players,
    competitions,
    comparePlayers,
    teams,
    playerImpact,
    matchPrediction,
    user,
    customers,
    pages,
    resource,
    applications,
    similarPlayers,
    gbePointsCalculator,
    bets,
    markets,
    formations,
    predictions,
  });
