import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { Frame, RegularTitle, TitleLink, Content } from './styles';
import cx from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  );
  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, []);
  return [{ ref }, bounds];
}

const Tree = ({
  children,
  name,
  to,
  firstLevel = false,
  defaultOpen = false,
  onClick = () => {},
}) => {
  const Title = to ? TitleLink : RegularTitle;
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <Frame>
      <Title
        to={to}
        onClick={() => {
          setOpen(!isOpen);
          onClick();
        }}
        className={cx({ first: firstLevel })}
      >
        {name}
      </Title>
      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? 'auto' : height,
        }}
      >
        <animated.div style={{ transform }} {...bind}>
          {children}
        </animated.div>
      </Content>
    </Frame>
  );
};

Tree.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  name: PropTypes.string,
  to: PropTypes.string,
  firstLevel: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(Tree);
