import React, { useEffect } from 'react';
import ProductBox from './ProductBox';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPages } from 'store';
import { productImages } from 'constants/env';
const Container = styled.section`
  padding-bottom: 50px;
  background-image: url('/media/assets/section-bg2.png');
  min-height: 30em;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;
  flex-direction: column;
  @media (max-width: 768px) {
    background-size: 130% 100%;
  }
`;

function OurProducts() {
  const { pages } = useSelector((state) => state.pages.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages(0, 0));
  }, []);

  return (
    <Container>
      <div
        className='row container-fluid'
        style={{ margin: 0, marginTop: -40 }}
      >
        {pages.data.map((page) => (
          <div
            className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-center'
            key={page.id}
          >
            <ProductBox
              key={page.id}
              title={page.name}
              image={productImages[page.slug]}
              to={`/products/${page.slug}`}
              text={page.summary}
            />
          </div>
        ))}
      </div>
    </Container>
  );
}

export default React.memo(OurProducts);
