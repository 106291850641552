import React from 'react';
import PropTypes from 'prop-types';
import Tree from './Tree';

import { useSpring } from 'react-spring';
import { AnchorLink } from 'components/styled';
import { NavOverlay, SideMenu } from './styles';
import { MdClose } from 'components/icons';

function Navigation({ opened, toggleOpened }) {
  const vw = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  const { width, opacity, display } = useSpring({
    from: { width: 0, opacity: 0, display: 'none' },
    to: {
      width: opened ? vw * 0.45 : 0,
      opacity: opened ? 1 : 0,
      display: opened ? 'flex' : 'none',
    },
  });
  const { width: overlayWidth } = useSpring({
    from: { width: 0 },
    to: {
      width: opened ? vw : 0,
    },
  });

  return (
    <nav>
      <SideMenu style={{ width, opacity, display }}>
        <div className={'header'}>
          <div className={'close-nav'}>
            <span onClick={toggleOpened}>
              Navigation
              <span className='close-icon'>
                <MdClose />
              </span>
            </span>
          </div>
          <Tree
            name='About us'
            to='/#aboutUs'
            onClick={toggleOpened}
            firstLevel
          />
          <Tree name='The RealAnalytics Platform' defaultOpen firstLevel>
            <Tree
              name='Player profile'
              onClick={toggleOpened}
              to='/products/player-rating'
            />
            <Tree
              name='Squad analysis'
              onClick={toggleOpened}
              to='/products/squad-analysis'
            />
            <Tree
              name='Search for player'
              onClick={toggleOpened}
              to='/products/player-search'
            />
            <Tree
              name='Compare players'
              onClick={toggleOpened}
              to='/products/compare-players'
            />
            <Tree
              name='Similar players search'
              onClick={toggleOpened}
              to='/products/similar-player-search'
            />
            <Tree
              name='Player impact evaluation'
              onClick={toggleOpened}
              to='/products/player-impact-evaluation'
            />
            <Tree
              name='Match prediction'
              onClick={toggleOpened}
              to='/products/match-prediction'
            />
            <Tree
              name='GBE calculator'
              onClick={toggleOpened}
              to='/products/gbe-calculator'
            />
          </Tree>
          <Tree
            onClick={toggleOpened}
            to='/#exampleOfOurAnalytics'
            name='Examples of Our Analytics'
            firstLevel
          />
          <Tree
            onClick={toggleOpened}
            to='/#ourResearch'
            name='Our Research'
            firstLevel
          />
          <Tree
            onClick={toggleOpened}
            to='/#contactUs'
            name='Contact us'
            firstLevel
          />
        </div>
        <div className={'footer'}>
          <div>
            <AnchorLink to={'/login'} style={{ marginLeft: 10 }}>
              Login
            </AnchorLink>
          </div>
          {/* <div className='social'>
            <div>
              <strong>Follow</strong> us
            </div>
            <div>
              <img src='/media/assets/twitter.png' alt='twitter' />
              <img src='/media/assets/facebook.png' alt='twitter' />
              <img src='/media/assets/linkedin.png' alt='twitter' />
            </div>
          </div> */}
        </div>
      </SideMenu>
      <NavOverlay
        onClick={toggleOpened}
        style={{ opacity, width: overlayWidth }}
      ></NavOverlay>
    </nav>
  );
}
Navigation.propTypes = {
  opened: PropTypes.bool,
  toggleOpened: PropTypes.func,
};
export default Navigation;
