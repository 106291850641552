import styled from '@emotion/styled';
import colors from 'styles/colors';
const dotVariants = {
    'big-checked': {
        minWidth: '15px',
        minHeight: '15px',
        animation: 'ripple 0.2s linear forwards',
    },
    'big-unchecked': {
        minWidth: '15px',
        minHeight: '15px',
        border: 'solid 1px #45c2c5',
        background: 'none',
    },
    'tertiary-checked': {
        minWidth: '15px',
        minHeight: '15px',
        animation: 'ripple 0.2s linear forwards',
        background: colors.tertiary,
    },
    'tertiary-unchecked': {
        minWidth: '15px',
        minHeight: '15px',
        border: `solid 1px ${colors.tertiary}`,
        background: 'none',
    },
    'gray-unchecked': {
        width: '13px',
        height: '13px',
        background: '#9e9ea0',
    },
    unchecked: {
        border: 'solid 1px #45c2c5',
        background: 'none',
    },
    position: {
        border: '2px solid white',
        boxShadow: '1px 2px 5px 0px rgba(0,0,0,0.6)',
    },
};
const Dot = styled('span')({
    minWidth: '8px',
    minHeight: '8px',
    background: colors.secondary,
    display: 'inline-block',
    borderRadius: ' 50%',
    cursor: 'pointer',
}, ({ variant }) => dotVariants[variant]);
export default Dot;
