import * as actionTypes from 'store/actionTypes';
import { AxiosRepo } from 'utils/axios';

const Axios = AxiosRepo.getInstance();

export const fetchSimulation = (simulationId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_SIMULATION,
    xhr: {
      req: () => Axios.get(`/api/v1/simulations/player-impact/${simulationId}`), //todo
    },
  });
};
export const launchPlayerImpactSimulation = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.LAUNCH_PLAYER_IMPACT,
    xhr: {
      req: () =>
        Axios.post('/api/v1/simulations/player-impact/launch', payload),
    },
  });
};
