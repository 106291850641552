import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;

  span:nth-of-type(1) {
    text-transform: uppercase;
    font-weight: bold;
  }
  span:nth-of-type(2) {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 95%;
  }
`;

export const SubItem = styled.div`
  color: #6a7aa2;
  display: flex;
  flex-direction: column;
  width: 10%;

  span:nth-of-type(1) {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
  }
  span:nth-of-type(2) {
    font-size: 13px;
  }
`;

export const Action = styled.div`
  height: 80px;
  width: 80px;
  background: #0d1325;
  margin-left: auto;
  border-radius: 0 13px 13px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    background: #131d37;
    height: 80px;
    width: 100%;
    border-radius: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
  }
  > div:hover {
    background: #45c2c5;
    color: #287476;
    div {
      color: #287476;
      &:last-of-type {
        background: #287476;
        color: white;
      }
    }
  }
`;
