import { MdClose } from 'components/icons';
import PropTypes from 'prop-types';

import React, { memo, useEffect, useState } from 'react';
import { useTransition } from 'react-spring';

import { Button, Container, Content, Life, Message } from './MessageHub.style';

let id = 0;

const config = { tension: 125, friction: 20, precision: 0.1 };
const timeout = 5000;

function MessageHub({ children }) {
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState([]);
  const transitions = useTransition(items, (item) => item.key, {
    from: { opacity: 0, height: 0, life: '100%' },
    enter: (item) => (next) => {
      item.options?.onEnter?.();
      return next({ opacity: 1, height: refMap.get(item).offsetHeight });
    },
    leave: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ life: '0%' });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    onDestroyed: (item) => {
      item.options?.onClose?.();
    },
    onRest: (item) => {
      return setItems((state) => state.filter((i) => i.key !== item.key));
    },
    config: (item, state) =>
      state === 'leave' ? [{ duration: timeout }, config, config] : config,
  });

  useEffect(
    () =>
      children((msg, options) =>
        setItems((state) => [...state, { key: id++, msg, options }])
      ),
    []
  );
  return (
    <Container>
      {transitions.map(({ key, item, props: { life, ...style } }) => (
        <Message key={key} style={style}>
          <Content ref={(ref) => ref && refMap.set(item, ref)}>
            <Life style={{ right: life }} />
            <p>{item.msg}</p>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                cancelMap.has(item) && cancelMap.get(item)();
              }}
            >
              <MdClose size={18} />
            </Button>
          </Content>
        </Message>
      ))}
    </Container>
  );
}

MessageHub.propTypes = {
  children: PropTypes.any,
};

export default memo(MessageHub);
