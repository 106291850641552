/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash-es/isString';
import isNil from 'lodash-es/isNil';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { on, off, generateGuid } from 'utils/helpers';
import { MdExpandMore } from 'components/icons';
import colors from 'styles/colors';
import { Spinner } from 'components/shared';
import {
  Actions,
  checkInView,
  findOptionFromTypeahead,
  findOptionByValue,
} from './utils';
import useOnUpdate from 'hooks/use-on-update';
import AbsoluteContainer from './AbsoluteContainer';
import useClickAway from 'hooks/use-click-away';

const TogglerWrapper = styled.span`
  width: 90%;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownContext = React.createContext();
const DropdownDispatcherContext = React.createContext();

function Item({ children, className, closeOnClick = true, value, ...rest }) {
  const { uiState, onSelect } = useContext(DropdownContext);
  const dispatch = useContext(DropdownDispatcherContext);
  const isHighlighted = uiState.navigationValue
    ? uiState.navigationValue === value
    : false;
  const currentRef = useRef(null);
  useEffect(() => {
    if (isHighlighted) {
      currentRef.current.focus();
      const inView = checkInView(
        currentRef.current.parentElement,
        currentRef.current
      );
      if (!inView)
        currentRef.current.parentElement.scrollTop =
          currentRef.current.offsetTop;
    }
  }, [isHighlighted]);
  return (
    <li
      ref={currentRef}
      style={{ cursor: 'pointer' }}
      aria-selected={isHighlighted}
      onClick={!rest.disabled ? handleOnClick : null}
      className={classNames(className, {
        'disabled-input': rest.disabled,
        active: isHighlighted,
      })}
      {...rest}
    >
      {children}
    </li>
  );

  function handleOnClick() {
    if (closeOnClick) {
      dispatch({ type: Actions.TOGGLE_VISIBILITY, payload: value });
    }
    onSelect(value);
  }
}
export const DropdownItem = React.memo(Item);

//*********** */ Menu *//////////////

function Menu({ children, className = '', ...rest }) {
  const { uiState, trigger } = useContext(DropdownContext);
  const dispatch = useContext(DropdownDispatcherContext);
  const menuRef = useRef();
  useClickAway([menuRef.current, trigger], () => {
    if (uiState.visible) dispatch({ type: Actions.TOGGLE_VISIBILITY });
  });
  return (
    <AbsoluteContainer
      trigger={trigger}
      container={menuRef.current}
      visible={uiState.visible}
    >
      <ul
        ref={menuRef}
        className={classNames(`dropdown-menu ${className}`, {
          show: uiState.visible,
        })}
        {...rest}
      >
        {children}
      </ul>
    </AbsoluteContainer>
  );
}

export const DropdownMenu = React.memo(Menu);

function Toggle({
  children,
  className = 'btn-default',
  activeClassName = '',
  loading,
  _triggerId,
  onClick,
  ...rest
}) {
  const { uiState } = useContext(DropdownContext);
  const dispatch = useContext(DropdownDispatcherContext);

  return (
    <Link
      className={classNames('btn dropdown-toggle', className, {
        [activeClassName]: uiState.visible,
      })}
      to={'#'}
      data-trigger-id={_triggerId}
      aria-haspopup='true'
      aria-expanded={uiState.visible}
      onClick={
        loading
          ? null
          : (e) => {
              e.preventDefault();
              dispatch({ type: Actions.TOGGLE_VISIBILITY });
              if (onClick) onClick(e);
            }
      }
      {...rest}
    >
      <TogglerWrapper>{children}</TogglerWrapper>
      {loading ? (
        <Spinner fontSize={20} color={colors.base} />
      ) : (
        <MdExpandMore fontSize={20} color={colors.base} />
      )}
    </Link>
  );
}
export const DropdownToggle = React.memo(Toggle);
function Dropdown({
  children,
  direction = 'dropdown',
  className,
  options,
  onSelect = () => {},
  value,
  ...rest
}) {
  const triggerId = useRef(generateGuid());
  const enhancedChildren = React.Children.map(children, (child, index) =>
    index === 0
      ? React.cloneElement(child, {
          _triggerId: triggerId.current,
        })
      : child
  );

  const [state, dispatch] = useReducer(reducer, {
    uiState: {
      visible: false,
      typeaheadQuery: null,
      navigationValue: '',
      navigationIndex: 0,
      value: value,
    },
    triggerId: triggerId.current,
    onSelect,
  });
  useEffect(() => {
    function keydownHandler(event) {
      const { key } = event;
      const isSearching = isString(key) && key.length === 1;
      switch (key) {
        case 'Enter':
          event.preventDefault();
          event.stopPropagation();
          onSelect(state.uiState.navigationValue);
          dispatch({ type: Actions.SELECT_VALUE });
          return;
        case ' ':
          // Prevent browser from scrolling down
          event.preventDefault();
          return;
        case 'Escape': {
          dispatch({ type: Actions.TOGGLE_VISIBILITY });
          return;
        }
        case 'Tab':
          return; // TODO
        case 'Backspace':
          dispatch({
            type: Actions.SEARCH,
            payload: state.uiState.typeaheadQuery
              ? state.uiState.typeaheadQuery.slice(
                  0,
                  state.uiState.typeaheadQuery.length - 1
                )
              : '',
          });
          return;
        case 'ArrowUp':
          dispatch({
            type: Actions.PREV,
          });
          event.preventDefault();
          return;
        case 'ArrowDown':
          dispatch({
            type: Actions.NEXT,
          });
          event.preventDefault();
          return;
        default:
          if (isSearching) {
            dispatch({
              type: Actions.SEARCH,
              payload: (state.uiState.typeaheadQuery || '') + key,
            });
          }
          return;
      }
    }
    if (state.uiState.visible) {
      if (options.length) {
        on(document, 'keydown', keydownHandler);
      }
    }
    return () => {
      off(document, 'keydown', keydownHandler);
    };
  }, [
    state.uiState.visible,
    state.uiState.typeaheadQuery,
    state.uiState.navigationValue,
    options,
  ]);

  useEffect(() => {
    const found = findOptionFromTypeahead(
      options,
      state.uiState.typeaheadQuery
    );
    if (found) dispatch({ type: Actions.SET_INDEX, payload: found.index });

    let timeout = window.setTimeout(() => {
      if (state.uiState.typeaheadQuery != null) {
        dispatch({ type: Actions.CLEAR_TYPEAHEAD }); // back to IDLE
      }
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [state.uiState.typeaheadQuery]);

  useOnUpdate(() => {
    if (isNil(value)) {
      dispatch({ type: Actions.INIT });
    } else {
      dispatch({ type: Actions.SELECT_VALUE, payload: value });
    }
  }, [value]);

  return (
    <DropdownContext.Provider value={state}>
      <DropdownDispatcherContext.Provider value={dispatch}>
        <div
          className={classNames(direction, className, {
            open: state.uiState.visible,
          })}
          {...rest}
        >
          {enhancedChildren}
        </div>
      </DropdownDispatcherContext.Provider>
    </DropdownContext.Provider>
  );

  function reducer(state, action) {
    switch (action.type) {
      case Actions.INIT:
        return {
          ...state,
          uiState: {
            ...state.uiState,
            navigationIndex: null,
            navigationValue: null,
            value: null,
          },
        };
      case Actions.TOGGLE_VISIBILITY: {
        const isOpening = state.uiState.visible === false;
        let newIndex = state.uiState.navigationIndex;
        if (action.payload) {
          newIndex = findOptionByValue(options, action.payload);
        }
        return {
          ...state,
          uiState: {
            ...state.uiState,
            visible: !state.uiState.visible,
            value: isOpening ? value : action.payload,
            navigationIndex: newIndex,
            navigationValue:
              newIndex !== null && options.length
                ? options[newIndex].value
                : null,
          },
          trigger: isOpening
            ? document.querySelector(`[data-trigger-id="${state.triggerId}"]`)
            : null,
        };
      }
      case Actions.SEARCH:
        return {
          ...state,
          uiState: {
            ...state.uiState,
            typeaheadQuery: action.payload,
          },
        };
      case Actions.SELECT_VALUE:
        return {
          ...state,
          uiState: {
            ...state.uiState,
            value: action.payload || state.uiState.navigationValue,
          },
        };
      case Actions.SET_INDEX:
        return {
          ...state,
          uiState: {
            ...state.uiState,
            navigationIndex: action.payload,
            navigationValue: options[action.payload].value,
          },
        };

      case Actions.PREV: {
        const prevIndex =
          (state.uiState.navigationIndex - 1 + options.length) % options.length;
        return {
          ...state,
          uiState: {
            ...state.uiState,
            navigationIndex: prevIndex,
            navigationValue: options[prevIndex].value,
          },
        };
      }

      case Actions.NEXT: {
        const nextIndex = (state.uiState.navigationIndex + 1) % options.length;
        return {
          ...state,
          uiState: {
            ...state.uiState,
            navigationIndex: nextIndex,
            navigationValue: options[nextIndex].value,
          },
        };
      }

      case Actions.CLEAR_TYPEAHEAD:
        return {
          ...state,
          uiState: {
            ...state.uiState,
            typeaheadQuery: null,
          },
        };
      default:
        throw new Error('Unknown action');
    }
  }
}

Dropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  direction: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(Dropdown);
