import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/simulations/similar-players';

export const launchSimilarPlayersSimulation = (query, weights) => (
  dispatch
) => {
  const weightsData = Object.keys(weights).map((field) => ({
    field: field,
    weight: weights[field],
  }));
  const payload = {
    ...query,
    weights: weightsData,
  };
  dispatch({
    type: actionTypes.LAUNCH_SIMILAR_PLAYERS_SIMULATION,
    xhr: {
      req: () => Axios.post(`${BASE_URL}/launch`, payload),
    },
  });
};
