import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.comparePlayers,
  { type, payload, error }
) {
  switch (type) {
    case actionTypes.COMPARE_PLAYERS:
      return {
        ...state,
        players: payload,
      };

    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.REQUEST):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          [payload.playerId]: {
            status: actionTypes.LOADING_STATUS,
          },
        },
      };
    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.SUCCESS):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          [payload.playerId]: {
            ...state.potentialEvolution[payload.playerId],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.data,
          },
        },
      };
    case getActionType(actionTypes.FETCH_POTENIAL_EVOLUTION, STATUSES.ERROR):
      return {
        ...state,
        potentialEvolution: {
          ...state.potentialEvolution,
          [payload.playerId]: {
            ...state.potentialEvolution[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };

    case getActionType(
      actionTypes.FETCH_EXPECTED_PERFORMANCE,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          [payload.playerId]: {
            status: actionTypes.LOADING_STATUS,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_EXPECTED_PERFORMANCE,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          [payload.playerId]: {
            ...state.expectedPerformance[payload.playerId],
            status: actionTypes.SUCCESS_STATUS,
            data: payload.data,
          },
        },
      };
    case getActionType(actionTypes.FETCH_EXPECTED_PERFORMANCE, STATUSES.ERROR):
      return {
        ...state,
        expectedPerformance: {
          ...state.expectedPerformance,
          [payload.playerId]: {
            ...state.expectedPerformance[payload.playerId],
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };

    default:
      return state;
  }
}
