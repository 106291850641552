import React from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';

const Dots = styled.span`
  text-align: center;
  span {
    display: inline-block;
    margin-left: 8px;
    background-color: ${colors.base};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-of-type(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: 0.2s;
  }

  span:nth-of-type(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: 0.4s;
  }
  @keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
      opacity: 0.2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
      opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
      opacity: 0.2;
    }
  }
`;
function LoadingDots() {
  return (
    <Dots>
      <span></span>
      <span></span>
      <span></span>
    </Dots>
  );
}

export default React.memo(LoadingDots);
