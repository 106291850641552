import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';
import { PREMIER_LEAGUE } from 'constants/env';
const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/players';

export const fetchPlayerProfile = (playerId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_PLAYER_PROFILE,
    payload: { playerId },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${playerId}`),
      mapper: (res) => ({ playerId, data: res.data }),
    },
  });
};

export const fetchAttributeRatings = (
  playerId,
  customWeights = {},
  competitionId = PREMIER_LEAGUE,
  filter = false
) => (dispatch) => {
  const memKey = `${playerId}-${competitionId}`;

  dispatch({
    type: actionTypes.FETCH_PLAYER_STATS,
    payload: { playerId, memKey },
    xhr: {
      req: () =>
        Axios.get(`${BASE_URL}/${playerId}/attribute-ratings?filter=${filter}`),
      mapper: ({ data }) => ({ data, playerId, memKey, customWeights }),
    },
  });
};

export const updatePlayerStats = (info, playerId) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_PLAYER_STATS,
    payload: {
      playerId,
      customWeigts: info,
    },
  });
};

export const fetchPlayerHeatmap = (playerId, scope = 'attacking') => (
  dispatch
) => {
  const memKey = `${playerId}-${scope}`;
  dispatch({
    type: actionTypes.FETCH_PLAYER_HEATMAP,
    payload: { memKey },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${playerId}/heatmap/${scope}`),
      mapper: (res) => ({ heatmap: res.data, memKey }),
    },
  });
};

export const fetchExpectedPerformance = (playerId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_EXPECTED_PERFORMANCE,
    payload: { playerId },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${playerId}/expected-performance`),
      mapper: (res) => ({ data: res.data, playerId }),
    },
  });
};
export const fetchReferenceHistogram = (playerId, competitionId, field) => (
  dispatch
) => {
  const memKey = `${playerId}-${competitionId}-${field}`;

  dispatch({
    type: actionTypes.FETCH_PLAYER_REFERENCE_HISTOGRAM,
    payload: { playerId },
    xhr: {
      req: () =>
        Axios.get(
          `${BASE_URL}/${playerId}/reference-histogram/${competitionId}/${field}`
        ),
      mapper: (res) => ({ memKey, data: res.data }),
    },
  });
};
export const fetchReferenceQuantile = (playerId, competitionId) => (
  dispatch
) => {
  const memKey = `${playerId}-${competitionId}`;
  dispatch({
    type: actionTypes.FETCH_REFERENCES,
    payload: { memKey },
    xhr: {
      req: () =>
        Axios.get(
          `${BASE_URL}/${playerId}/reference-quantile/${competitionId}`
        ),
      mapper: ({ data }) => ({ memKey, data }),
    },
  });
};

export const fetchOneReferenceQuantile = (playerId, competitionId, field) => (
  dispatch
) => {
  const memKey = `${playerId}-${competitionId}`;
  dispatch({
    type: actionTypes.FETCH_ONE_REFERENCE,
    payload: { memKey, field },
    xhr: {
      req: () =>
        Axios.get(
          `${BASE_URL}/${playerId}/reference-quantile/${competitionId}/${field || ''
          }`
        ),
      mapper: ({ data }) => ({ memKey, field, data }),
    },
  });
};

export const fetchBasicStatistics = (playerId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_PLAYER_BASIC_RATINGS,
    payload: { playerId },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${playerId}/basic-statistics`),
      mapper: (res) => ({ playerId, data: res.data }),
    },
  });
};
export const fetchFullRatings = (playerId, competitionId = null) => (
  dispatch
) => {
  const memKey = `${playerId}-${competitionId || PREMIER_LEAGUE}`;
  if (competitionId === null) {
    dispatch({
      type: actionTypes.FETCH_PLAYER_FULL_RATINGS,
      payload: { playerId, memKey },
      xhr: {
        req: () => Axios.get(`${BASE_URL}/${playerId}/full-ratings`),
        mapper: (res) => ({ playerId, memKey, data: res.data }),
      },
    });
  } else {
    dispatch({
      type: actionTypes.FETCH_PLAYER_FULL_RATINGS,
      payload: { playerId, memKey },
      xhr: {
        req: () =>
          Axios.get(`${BASE_URL}/${playerId}/full-ratings/${competitionId}`),
        mapper: (res) => ({ playerId, memKey, data: res.data }),
      },
    });
  }
};

export const fetchPotentialEvolution = (playerId) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_POTENIAL_EVOLUTION,
    payload: { playerId },
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${playerId}/potential-evolution`),
      mapper: (res) => ({ playerId, data: res.data }),
    },
  });
};

export const fetchAdvancedSearch = (payload, competitionId) => (dispatch) => {
  return dispatch({
    type: actionTypes.ADVANCED_PLAYER_SEARCH,
    payload: { competitionId },

    xhr: {
      req: () => Axios.post('/api/v1/advanced-search/player', payload),
      mapper: ({ data }) => ({ competitionId, data }),
    },
  });
};
