import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Img from './Img';
import cx from 'classnames';
import { AnchorLink } from 'components/styled';

const Container = styled.div`
  display: inline-block;
  img {
    margin-left: 2px;
    margin-right: 10px;
    height: ${(props) => (props.height ? `${props.height}px` : null)};
    width: ${(props) => (props.width ? `${props.width}px` : null)};
  }
`;
const Link = styled(AnchorLink)`
  display: inline-block;
  img {
    margin-left: 2px;
    margin-right: 10px;
    height: ${(props) => (props.height ? `${props.height}px` : null)};
    width: ${(props) => (props.width ? `${props.width}px` : null)};
  }
`;

const Text = styled.span`
  margin-left: 8px;
  font-size: 15px;
`;

function Item({
  name,
  src,
  width,
  to,
  height,
  defaultImage,
  reverse = false,
  children,
  ...rest
}) {
  const Content = to ? Link : Container;
  return (
    <Content variant='natural' height={height} width={width} to={to}>
      <div
        className={cx('d-flex align-items-center', {
          'flex-row-reverse': reverse,
        })}
      >
        <Img
          src={src}
          alt={name}
          height={height}
          width={width}
          defaultImage={defaultImage}
        />
        <Text
          {...rest}
        >{name}</Text>
        {children}
      </div>
    </Content>
  );
}

Item.propTypes = {
  name: PropTypes.any,
  to: PropTypes.string,
  src: PropTypes.string,
  defaultImage: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  reverse: PropTypes.bool,
  children: PropTypes.any,
};

export default memo(Item);
