import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import Tshirt from './Tshirt';
import noop from 'lodash-es/noop';

export const PlayerPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  cursor: pointer;
  span {
    background: #45c2c5;
    color: white;
    border-radius: 2px;
    padding: 0px 4px;
    font-size: smaller;
  }
`;

const Field = styled.div`
  background-image: url(/media/assets/field.png);
  background-size: 100% 100%;
  height: 376px;
  width: 252px;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: 0px;
  background-position-y: 0px;
`;

const PLAYER_ADVANCED_SEARCH_POSITIONS = [
  {
    position: 'GK',
    bottom: (17 / 376) * 100,
    right: (112 / 252) * 100,
    color: '#131D37',
  },
  {
    position: 'DC',
    bottom: (62 / 376) * 100,
    right: (112 / 252) * 100,
    color: '#795548',
  },
  {
    position: 'DCL',
    bottom: (68 / 376) * 100,
    right: (145 / 252) * 100,
    color: '#795548',
  },
  {
    position: 'DCR',
    bottom: (68 / 376) * 100,
    right: (71 / 252) * 100,
    color: '#795548',
  },
  {
    position: 'DL',
    bottom: (75 / 376) * 100,
    right: (189 / 252) * 100,
    color: '#3F51B5',
  },
  {
    position: 'DR',
    bottom: (75 / 376) * 100,
    right: (32 / 252) * 100,
    color: '#3F51B5',
  },

  {
    position: 'DMC',
    bottom: (117 / 376) * 100,
    right: (111 / 252) * 100,
    color: '#03A9F4',
  },
  {
    position: 'DML',
    bottom: (126 / 376) * 100,
    right: (153 / 252) * 100,
    color: '#03A9F4',
  },
  {
    position: 'DMR',
    bottom: (126 / 376) * 100,
    right: (71 / 252) * 100,
    color: '#03A9F4',
  },
  {
    position: 'WBL',
    bottom: (140 / 376) * 100,
    right: (191 / 252) * 100,
    color: '#3F51B5',
  },
  {
    position: 'WBR',
    bottom: (140 / 376) * 100,
    right: (28 / 252) * 100,
    color: '#3F51B5',
  },

  {
    position: 'MC',
    bottom: (177 / 376) * 100,
    right: (112 / 252) * 100,
    color: '#9B59B6',
  },
  {
    position: 'MCL',
    bottom: (177 / 376) * 100,
    right: (145 / 252) * 100,
    color: '#9B59B6',
  },
  {
    position: 'MCR',
    bottom: (177 / 376) * 100,
    right: (71 / 252) * 100,
    color: '#9B59B6',
  },
  {
    position: 'MR',
    bottom: (202 / 376) * 100,
    right: (32 / 252) * 100,
    color: '#E91E63',
  },
  {
    position: 'ML',
    bottom: (202 / 376) * 100,
    right: (189 / 252) * 100,
    color: '#E91E63',
  },

  {
    position: 'AMC',
    bottom: (239 / 376) * 100,
    right: (111 / 252) * 100,
    color: '#F1C40F',
  },
  {
    position: 'AML',
    bottom: (259 / 376) * 100,
    right: (145 / 252) * 100,
    color: '#F1C40F',
  },
  {
    position: 'AMR',
    bottom: (259 / 376) * 100,
    right: (71 / 252) * 100,
    color: '#F1C40F',
  },

  {
    position: 'FWL',
    bottom: (293 / 376) * 100,
    right: (178 / 252) * 100,
    color: '#E67E22',
  },
  {
    position: 'FWR',
    bottom: (293 / 376) * 100,
    right: (34 / 252) * 100,
    color: '#E67E22',
  },
  {
    position: 'FW',
    bottom: (306 / 376) * 100,
    right: (112 / 252) * 100,
    color: '#F44336',
  },
].map((it) => ({
  ...it,
  active: false,
}));

function PositionsField({
  positions = PLAYER_ADVANCED_SEARCH_POSITIONS,
  defaultActive = [],
  onChange = noop,
  ...rest
}) {
  const [_positions, setPositions] = useState(
    positions.map((p) =>
      defaultActive.includes(p.position) ? { ...p, active: true } : p
    )
  );

  useEffect(() => {
    onChange(_positions.filter((it) => it.active));
  }, [_positions]);
  return (
    <Field {...rest}>
      {_positions.map(({ right, bottom, color, position, active }) => (
        <div
          key={position}
          onClick={() => {
            setPositions(
              _positions.map((it) =>
                it.position === position ? { ...it, active: !it.active } : it
              )
            );
          }}
        >
          <PlayerPosition style={{ right: `${right}%`, bottom: `${bottom}%` }}>
            <Tshirt width={25} height={25} fill={color} />
            <span>{position}</span>
          </PlayerPosition>
          {!active && (
            <PlayerPosition
              style={{ right: `${right}%`, bottom: `${bottom}%` }}
            >
              <Tshirt width={25} height={25} fill='#000000a8' />
              <span style={{ backgroundColor: '#000000a8' }}>{position}</span>
            </PlayerPosition>
          )}
        </div>
      ))}
    </Field>
  );
}

PositionsField.propTypes = {
  onChange: PropTypes.func,
  positions: PropTypes.array,
  defaultActive: PropTypes.array,
};

export default memo(PositionsField);
