import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.matchs,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_SCHEDULED_MATCHS, STATUSES.REQUEST):
      return {
        ...state,
        scheduledMatchs: {
          ...state.scheduledMatchs,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_SCHEDULED_MATCHS, STATUSES.SUCCESS):
      return {
        ...state,
        scheduledMatchs: {
          ...state.scheduledMatchs,
          data: payload,
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_SCHEDULED_MATCHS, STATUSES.ERROR):
      return {
        ...state,
        scheduledMatchs: {
          ...state.scheduledMatchs,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_MATCH, STATUSES.REQUEST):
      return {
        ...state,
        match: {
          ...state.match,
          status: actionTypes.LOADING_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_MATCH, STATUSES.SUCCESS):
      return {
        ...state,
        match: {
          ...state.match,
          data: payload,
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case getActionType(actionTypes.FETCH_MATCH, STATUSES.ERROR):
      return {
        ...state,
        match: {
          ...state.match,
          error,
          status: actionTypes.ERROR_STATUS,
        },
      };
    default:
      return state;
  }
}
