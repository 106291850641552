import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { precisionRound } from 'utils/helpers';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'components/icons';
import { isNil } from 'lodash-es';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 25%;

  span.icons {
    position: absolute;
    right: 0;
    height: 35px;
    display: flex;
    flex-direction: column;
    svg {
      font-size: 22px;
      cursor: pointer;
    }
  }
`;

const Input = styled.input`
  padding-right: 3ex;
  box-sizing: border-box;
  font-size: inherit;
  height: 35px;
  width: 100%;
  border: 1px solid rgb(171, 191, 218);
  border-radius: 2px;
  padding-left: 4px;
  display: block;
  -webkit-appearance: none;
  line-height: normal;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const NumericInput = ({
  value = 0,
  precision,
  step,
  min,
  max,
  onChange,
  groupProps,
  ...rest
}) => {
  const handleChange = useCallback(
    ({ target }) => {
      if (isNil(target.value) || target.value === '') {
        onChange(target.value);

        return;
      }

      const nextValue =
        precision === undefined
          ? target.value
          : precisionRound(parseFloat(target.value), precision);
      onChange(parseFloat(nextValue));
    },
    [precision, onChange]
  );
  return (
    <FormGroup {...groupProps}>
      <Input
        type='number'
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={handleChange}
        {...rest}
      />
      <span className='icons'>
        <MdKeyboardArrowUp
          onClick={() =>
            handleChange({
              target: {
                value: Math.min(
                  value + step,
                  max !== undefined ? max : Infinity
                ),
              },
            })
          }
        />
        <MdKeyboardArrowDown
          onClick={() =>
            handleChange({
              target: {
                value: Math.max(
                  min !== undefined ? min : -Infinity,
                  value - step
                ),
              },
            })
          }
        />
      </span>
    </FormGroup>
  );
};

NumericInput.propTypes = {
  value: PropTypes.number,
  precision: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  groupProps: PropTypes.object,
};

export default memo(NumericInput);
