import { isEqual, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React, { memo } from 'react';

import Plot from './Plot';

const queryClient = new QueryClient();

function SpiderPlot({ plots = [], competitionId, histogram = false }) {
  return (
    plots.length > 0 &&
    !isEmpty(plots[0].profile) && (
      <div style={{ backgroundColor: 'white', height: '500px' }}>
        {
          <QueryClientProvider client={queryClient}>
            <Plot
              plots={plots}
              competitionId={competitionId}
              histogram={histogram}
            />
          </QueryClientProvider>
        }
      </div>
    )
  );
}

SpiderPlot.propTypes = {
  plots: PropTypes.array,
  competitionId: PropTypes.string,
  histogram: PropTypes.bool,
};

export default memo(SpiderPlot, (prevProps, props) =>
  isEqual(prevProps, props)
);
