import { useState, useEffect } from 'react';
import storage from 'utils/storage';

export function usePersistentState(key, defaultState = '') {
  const [state, setState] = useState(storage.get(key) || defaultState);

  useEffect(() => {
    storage.set(key, state);
  }, [state]);

  return [state, setState];
}
