import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import initialState from './initialState';
import { createBrowserHistory } from 'history';
import asyncActions from './middlewares/asyncActions';

export const history = createBrowserHistory();

const enhancers = [];
const middlewares = [thunk, asyncActions, routerMiddleware(history)];

//Devtools stuff
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);
export default store;
