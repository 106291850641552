import React, { memo } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
function Alert({
  info,
  danger,
  warning,
  success,
  dismissable,
  children,
  className,
  ...rest
}) {
  return (
    <div
      className={cx(
        'alert',
        {
          'alert-danger': danger,
          'alert-dismissable': dismissable,
          'alert-info': info,
          'alert-warning': warning,
          'alert-success': success,
        },
        className
      )}
      {...rest}
    >
      {children}
      {dismissable && (
        <button
          type='button'
          className='close'
          data-dismiss='alert'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      )}
    </div>
  );
}

Alert.propTypes = {
  info: PropTypes.bool,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  dismissable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};
export default memo(Alert);
