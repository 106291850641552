import { DEFENDERS_POS, FORWARDS_POS, MIDFIELDERS_POS } from 'constants/env';
import ISO3_LOOKUP from 'constants/iso3-lookup.json';
import { format } from 'date-fns';
import { lowerCase, padStart, snakeCase } from 'lodash-es';

import { getQueryStringValue } from './query-string';

export const on = (obj, ...args) => obj.addEventListener(...args);

export const off = (obj, ...args) => obj.removeEventListener(...args);

export const hasRole = (user, role) => user.roles.indexOf(role) !== -1;

export const normalizeSuggestion = (suggestions = []) => {
  return suggestions.reduce(
    (acc, suggestion) => [
      ...acc,
      ...(suggestion.array || []).map((item) => ({
        ...item,
        searchValue: item[suggestion.value],
        searchLabel: item[suggestion.label] ?? suggestion.label,
        searchImage: suggestion.image(item),
        searchType: suggestion.type,
      })),
    ],
    []
  );
};

export const formatNumbers = (number, isPerformance = false) => {
  try {
    if (
      isNaN(number) ||
      number === null ||
      number === undefined ||
      typeof number === 'string'
    ) {
      return number;
    }

    if (isPerformance) {
      return number.toFixed(2);
    }

    return number.toFixed(1);
  } catch (e) {
    return number;
  }
};

export const precisionRound = (number, precision = 1) => {
  let factor = Math.pow(10, precision);
  const value = Math.round(number * factor) / factor;
  return isNaN(value) ? 0 : value;
};

export const mapToDropdown = (
  arr = [],
  label = 'label',
  value = 'id',
  key = 'id'
) =>
  arr.reduce((acc, item) => {
    acc.push({
      ...item,
      key: item[key],
      value: item[value],
      label: item[label],
    });
    return acc;
  }, []);

export function makeHeaderGroups(allColumns) {
  const results = {};

  const recurse = (columns, level = 0) => {
    level++;

    columns.forEach((column) => {
      if (!column.columns) {
        (results[level] = results[level] || []).push({
          ...column,
          _key: column.title,
          colspan: 1,
        });
      } else {
        (results[level] = results[level] || []).push({
          ...column,
          colspan: column.columns.length,
        });
        recurse(column.columns, level);
      }
    });
  };

  recurse(allColumns);

  return results;
}

export function flattenDataColumns(allColumns) {
  return flattenBy(allColumns, 'columns').filter((column) => column.accessor);
}
export function flattenBy(arr, key) {
  const flat = [];

  const recurse = (arr, parent) => {
    arr.forEach((d) => {
      if (!d[key]) {
        flat.push({ ...d, key: d.key || parent + d.title });
      } else {
        recurse(d[key], d.title);
      }
    });
  };

  recurse(arr);

  return flat;
}

// Todo needs to be more robust
export function getFlagPath(country) {
  if (typeof country === 'string' && country.length === 3) {
    return `/media/flags/${country}.png`;
  }

  return `/media/flags/${ISO3_LOOKUP[country]}.png`;
}

export function getApplicationIcon(application) {
  return `/media/assets/${snakeCase(lowerCase(application))}.png`;
}

export function generateGuid() {
  let result, i, j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
}

export const calculateAge = (birthday) => {
  if (birthday) {
    birthday = new Date(birthday);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  return 'N/A';
};

export function getPageFromUrl(location, pageKey = 'page') {
  const page = parseFloat(getQueryStringValue(pageKey, location.search)) || 1;
  return Math.max(1, page);
}

export function compareString(str1 = '', str2 = '') {
  return str1.toLowerCase().trim() === str2.toLowerCase().trim();
}

export const getField = (field, playerId) => `${playerId}-${field}`;

export const getFormattedAge = (dateOfBirth) => {
  if (dateOfBirth === null) {
    return '-';
  } else {
    const years =
      new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
    const months = new Date().getMonth() - new Date(dateOfBirth).getMonth();

    if (months != 0) {
      let invert = false;
      if (months < 0) {
        invert = true;
      }
      if (invert === false) {
        return `${years} yrs and ${months} ${months > 1 ? 'mths' : 'mth'}`;
      } else {
        return `${years - 1} yrs and ${12 + months} ${
          12 + months > 1 ? 'mths' : 'mth'
        }`;
      }
    } else {
      return `${years} yrs`;
    }
  }
};

export const guid = () => {
  let result, i, j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
};

export const downloadFile = (path, name) => {
  fetch(`${path}`, { method: 'get' }).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = name;
      a.click();
    });
  });
};
export function getFormationAsString(squad = []) {
  const defenders = squad.filter((player) =>
    DEFENDERS_POS.includes(player.preferredPosition)
  ).length;

  const midfielders = squad.filter((player) =>
    MIDFIELDERS_POS.includes(player.preferredPosition)
  ).length;

  const forwards = squad.filter((player) =>
    FORWARDS_POS.includes(player.preferredPosition)
  ).length;

  return defenders + '-' + midfielders + '-' + forwards;
}

export function normalizePosition(newPosition) {
  return {
    name: newPosition.name,
    x: newPosition.horizontalCoordinate,
    y: newPosition.verticalCoordinate,
  };
}

export function humanizeDuration(s) {
  const minutes = Math.floor(s / 60);
  const seconds = Math.floor(s - minutes * 60);
  return [padStart(minutes, 2, 0), padStart(seconds, 2, 0)].join(':');
}

export function formatDate(date, _format, { fallback = '-' } = {}) {
  try {
    return format(new Date(date), _format);
  } catch {
    return fallback;
  }
}

export function formatSeconds(time) {
  //formarting duration of video
  if (Number.isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, '0')} `;
  } else return `${minutes}:${seconds}`;
}
