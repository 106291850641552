import React from 'react';

function ArrowDropDown(props) {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 10l5 5 5-5z'></path>
    </svg>
  );
}

export default React.memo(ArrowDropDown);
