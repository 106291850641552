import React from 'react';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import { NavLink } from 'react-router-dom';

export const GlobalBackground = () => (
  <Global
    styles={css`
      body {
        background-image: url('/media/assets/backgroud.jpeg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        min-height: 100vh;
      }
    `}
  />
);

const anchorVariants = {
  primary: {
    background: colors.white,
    color: colors.text,
    textDecoration: 'none',
    padding: '9px 15px',
    '&:hover': {
      background: colors.text,
      color: colors.white,
      border: '1px solid white',
    },
  },
  secondary: {
    textDecoration: 'none',
    background: colors.secondary,
    color: colors.white,
    padding: '5px 15px',
    borderRadius: 2,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      color: colors.secondary,
      background: colors.white,
    },
  },
  tertiary: {
    textDecoration: 'none',
    background: colors.tertiary,
    color: colors.white,
    padding: '5px 15px',
    borderRadius: 2,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      color: colors.tertiary,
      background: colors.white,
    },
  },
  transparent: {
    background: 'transparent',
    color: colors.white,
    border: '1px solid white',
    textDecoration: 'none',
    padding: '9px 15px',
    '&:hover': {
      background: colors.white,
      color: colors.base,
    },
  },
  natural: {
    textDecoration: 'none',
    color: colors.base,
    '&:hover': {
      fontWeight: 'normal',
      color: colors.base,
    },
  },
  lynch: {
    textDecoration: 'none',
    background: '#6C789A',
    color: colors.white,
    padding: '5px 15px',
    borderRadius: 2,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: colors.white,
      background: '#6C789A',
    },
  },
  unstyled: {
    textDecoration: 'none',
    color: colors.white,
    '&:hover': {
      color: colors.white,
      fontWeight: 'normal',
    },
  },
};

export const AnchorLink = styled(NavLink)(
  {
    border: '0',
    borderRadius: '0',
    color: 'white',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  ({ variant = 'primary' }) => anchorVariants[variant]
);

const buttonVariants = {
  primary: {
    background: colors.indigo,
    color: colors.white,
  },
  danger: {
    background: colors.danger,
    color: colors.white,
  },
  secondary: {
    background: colors.secondary,
    borderRadius: 2,
    color: colors.white,
  },
  tertiary: {
    background: colors.tertiary,
    color: colors.white,
    borderRadius: 2,
    '&:hover': {
      color: colors.tertiary,
      background: colors.white,
    },
  },
  dark: {
    background: colors.dark,
    color: colors.white,
    borderRadius: 3,
    '&:hover': {
      color: colors.white,
      background: colors.secondary,
    },
  },
  transparent: {
    background: 'transparent',
    color: colors.white,
    border: '1px solid white',
    '&:hover': {
      background: colors.white,
      color: colors.base,
    },
  },
};

export const Button = styled.button(
  {
    padding: '5px 15px',
    border: '0',
    borderRadius: '0',
    color: colors.white,
  },
  ({ variant = 'primary' }) => buttonVariants[variant]
);

export const ContactInput = styled.input`
  margin: 10px 0;
  width: 100%;
  border-radius: 0;
  height: 50px;
  border: 1px solid #ccc;
  padding: 5px;
`;

const BackBaseStyle = css`
  height: 60px;
  width: 60px;
  background: #3fc3c4;
  color: white;
  &:hover {
    color: #3fc3c4;
    background: white;
  }
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackDiv = styled.div`
  ${BackBaseStyle};
  font-size: 25px;
  font-weight: bold;
`;

export const BackLink = styled(NavLink)`
  ${BackBaseStyle};
  text-decoration: none;
`;

export const BackButton = styled.button`
  ${BackBaseStyle};
  outline: none;
  border: none;
`;

export const ContactTextArea = styled.textarea`
  margin: 10px 0;
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 5px;
`;

// Auth Pages
export const AuthLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .logo {
    width: 23rem;
    @media (max-width: 600px) {
      width: 13rem !important;
    }
  }
  @media (max-height: 400px) {
    height: 150vh;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;
  width: 23rem;
  padding: 25px;
  color: ${colors.text};
  border-radius: 1px;
  filter: drop-shadow(0px 1px 6.5px rgba(0, 0, 0, 0.2));
  background: white;
  border: 1px solid rgba(255, 255, 255, 0.06);
  font-size: 14px;
  @media (max-width: 600px) {
    width: 17rem !important;
  }
  .info {
    @media (max-width: 600px) {
      font-size: 11px !important;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #36af47;
    background-color: #36af47;
  }
  .custom-switch .custom-control-label::after {
    @media (max-width: 600px) {
      top: calc(0rem + 2px);
    }
  }
  .custom-switch .custom-control-label::before {
    @media (max-width: 600px) {
      top: 0rem;
    }
  }
  span.icon {
    position: absolute;
    margin-left: 5px;
    height: 45px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    svg {
      font-size: 22px;
    }
  }
`;

export const AuthInput = styled.input`
  border-radius: 5px;
  padding: 5px;
  padding-left: 50px;
  height: 45px;
  outline: none;
  background: white;
  border: 1px solid #ccc;
  &::placeholder {
    color: #aeb9d5;
  }
  &:hover {
    border: 1px solid ${colors.secondary};
  }
  &:focus {
    border: 1px solid ${colors.secondary};
  }
`;
