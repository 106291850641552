import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash-es';

function TrustworthyValue({ children, trustworthy }) {
  return (
    <span
      className={cx({
        'text-primary': get(trustworthy, 'untrustworthyHigh') === true,
        'text-danger': get(trustworthy, 'untrustworthyLow') === true,
      })}
    >
      {children}
    </span>
  );
}

TrustworthyValue.propTypes = {
  trustworthy: PropTypes.any,
  children: PropTypes.any,
};

export default TrustworthyValue;
