import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { get, isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { formatNumbers } from 'utils/helpers';

import React, { useRef, useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';

import Histogram from './Histogram';
import './Plot.scss';
import { fetchFullHistograms } from './api/index';
import { getColor, normalizeData } from './utils';

const FAKE_PLOT = 'FAKE_PLOT';

function Plot({ plots = [], competitionId, histogram = false }) {
  const chartRef = useRef();
  const histogramRef = useRef();
  const [created, setCreated] = useState(false);
  const [field, setField] = useState('');
  const [label, setLabel] = useState('');
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const firstPlayer = plots[0]?.profile;
  const [selectedColors, setSelectedColors] = useState([
    {
      id: firstPlayer.playerId,
      color: firstPlayer.teamKits[0].colour1,
    },
  ]);
  const referenceHistogram = fetchFullHistograms(
    firstPlayer.playerId,
    competitionId
  );
  const selectedRatings = plots[0].data.map((e) => e.label);
  const referenceQuantile = useSelector(
    ({ players }) => players.referenceQuantile
  );
  am4core.options.autoSetClassName = true;

  useEffect(() => {
    if (!document.getElementById(`histogram${index}`)) return;

    if (referenceHistogram.isLoading) return;

    if (created && show) {
      // Create chart
      let chart = am4core.create(`histogram${index}`, am4charts.XYChart);
      histogramRef.current = chart;
      chart.paddingLeft = 0;
      const bucket = get(
        referenceQuantile,
        `${plots[0].profile.playerId}-${competitionId}.data.${field}.bucket`
      );
      let title = chart.titles.create();
      title.text = `${label} (${bucket})`;
      title.fontSize = 15;
      title.marginBottom = 30;

      chart.exporting.menu = new am4core.ExportMenu();
      // Create axes
      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.dataFields.id = 'value';
      xAxis.renderer.minGridDistance = 80;
      xAxis.renderer.grid.template.disabled = true;
      xAxis.numberFormatter = new am4core.NumberFormatter();
      xAxis.numberFormatter.numberFormat = '#.';
      xAxis.renderer.grid.template.location = 0;

      let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.baseValue = 0;

      // Create series
      let reference = chart.series.push(new am4charts.LineSeries());
      reference.dataFields.valueX = 'value';
      reference.dataFields.valueY = 'prob';
      reference.strokeWidth = 1;
      reference.fillOpacity = 0.4;
      reference.tensionX = 0.77;

      let playerValue = chart.series.push(new am4charts.ColumnSeries());
      playerValue.dataFields.valueX = 'value';
      playerValue.dataFields.valueY = 'y';
      playerValue.sequencedInterpolation = true;
      playerValue.fillOpacity = 0;
      playerValue.strokeOpacity = 0;
      playerValue.strokeWidth = 0;
      playerValue.columns.template.width = 0.01;

      let bullet = playerValue.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 0;
      bullet.circle.radius = 0;

      let valueLabel = playerValue.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.background = new am4core.RoundedRectangle();
      valueLabel.label.background.fill = am4core.color('black');
      valueLabel.label.background.cornerRadius(5, 5, 5, 5);
      valueLabel.label.padding(2, 2, 2, 2);
      valueLabel.label.fill = am4core.color('white');
      valueLabel.label.text = '{value}';
      valueLabel.label.fontSize = 16;
      valueLabel.label.truncate = false;
      valueLabel.label.hideOversized = false;
      valueLabel.label.dy = -35;
      const dotsLegend = [];
      let differentBucket = false;

      const data = get(referenceHistogram, `data.${label}`, []);
      const sort = [...data].sort((a, b) => a.prob - b.prob);
      const maxProb = sort[data.length - 1];
      const medianProb = sort[~~(data.length / 2)];

      const yValues = [{ prob: 0 }, medianProb, maxProb];
      let i = 0;
      for (const { profile, data } of plots) {
        const { playerId } = profile;
        const reference = get(
          referenceQuantile,
          `${playerId}-${competitionId}.data.${field}`
        );
        const dot = data.find((e) => e.label === label);

        dotsLegend.push({
          y: i < 3 ? yValues[i].prob : 0.001,
          value:
            formatNumbers(dot.value) +
            `(${reference.rank + (reference?.bucket !== bucket ? '*' : '')})`,
        });
        i++;
        if (reference?.bucket !== bucket) {
          differentBucket = true;
        }
        let event = xAxis.axisRanges.create();
        event.value = formatNumbers(dot.value);
        event.endValue = formatNumbers(dot.value);
        event.grid.disabled = true;
        event.axisFill.fillOpacity = 0.1;
        let axisBullet = new am4charts.AxisBullet();

        event.bullet = axisBullet;
        let circle = event.bullet.createChild(am4core.Circle);
        circle.width = 15;
        circle.height = 15;
        const dotColor = selectedColors.find((e) => e.id === profile.playerId);
        circle.fill = am4core.color(dotColor.color);
        circle.horizontalCenter = 'middle';
      }
      if (differentBucket) {
        let label = chart.createChild(am4core.Label);
        label.text =
          '(*) : The rank was computed with respect to a different reference';
        label.fontSize = 10;
        label.align = 'right';
      }

      const sorted = [...data, ...dotsLegend].sort((a, b) => a.value - b.value);

      chart.data = sorted;
    }
    return () => {
      if (histogramRef.current) {
        histogramRef.current.dispose();
      }
    };
  }, [label, field, created, show]);

  useEffect(() => {
    const finalPlots = createFakePlot(plots);
    const data = normalizeData(finalPlots);
    const existingKits = [];

    am4core.ready(() => {
      am4core.useTheme(am4themes_animated);
      chartRef.current = am4core.create('chart', am4charts.RadarChart);
      chartRef.current.fontSize = 10;
      chartRef.current.addData(data);
      chartRef.current.cursor = new am4charts.RadarCursor();
      chartRef.current.cursor.behavior = 'none';
      chartRef.current.cursor = new am4charts.RadarCursor();
      let categoryAxis = chartRef.current.xAxes.push(
        new am4charts.CategoryAxis()
      );
      categoryAxis.renderer.labels.template.fontSize = 15;
      categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.maxWidth = 100;
      categoryAxis.dataFields.category = 'label';
      categoryAxis.title.fill = '#F00';
      let valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.autoGridCount = false;
      valueAxis.renderer.minGridDistance = 50;
      chartRef.current.zoomOutButton.disabled = true;

      const playersColors = [];

      for (const [index, plot] of finalPlots.entries()) {
        const series = chartRef.current.series.push(
          new am4charts.RadarSeries()
        );
        series.dataFields.valueY = `quantile${plot.profile.playerId}`;
        series.dataFields.categoryX = 'label';
        series.name = plot.profile.name;
        series.zIndex = 2;
        series.cursorHoverEnabled = true;
        const color =
          index !== finalPlots.length - 1
            ? getColor(plot.profile.teamKits, existingKits)
            : '#FFFFFF';
        existingKits.push(color);
        playersColors.push({
          id: plot.profile.playerId,
          color: color,
        });
        series.strokeWidth = color === '#FFFFFF' ? 3 : 0;
        if (index === finalPlots.length - 1) {
          series.strokeDasharray = '3,3';
          series.hiddenInLegend = true;
        }
        series.fill = am4core.color(color);
        series.fillOpacity = 0.5;
        if (index !== finalPlots.length - 1) {
          let bullet = new am4charts.CircleBullet();
          bullet.circle.strokeWidth = 0;
          bullet.circle.radius = 0;
          bullet.circle.fill = am4core.color(color);
          series.bullets.push(bullet);

          bullet.width = 0;
          bullet.height = 0;

          let bullethover = bullet.states.create('hover');
          bullethover.properties.scale = 1.3;
          chartRef.current.cursorTooltipEnabled = false;
          series.cursorTooltipEnabled = true;
          series.tooltip.getFillFromObject = false;
          series.tooltip.background.fill = am4core.color('#FFF');

          series.hoverOnFocus = true;
          series.hoverable = true;
          bullet.events.on('over', ({ target }) => {
            bullet.tooltipHTML = `<div id="histogram${index}" style="width:450px;height:235px"></div>`;
            setIndex(index);
            setField(target.dataItem?.dataContext?.field);
            setLabel(target.dataItem?.dataContext?.label);
            setCreated(true);
            setShow(true);
          });
        }
      }
      if (plots.length > 1) {
        chartRef.current.legend = new am4charts.Legend();
      }

      setSelectedColors([...playersColors]);
    });
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [plots]);

  return histogram === true ? (
    <div className='row'>
      <div className='col-md-3'>
        <div className='row'>
          {selectedColors.length - 1 === plots.length &&
            !referenceHistogram.isLoading &&
            Object.keys(referenceHistogram.data)
              .filter((e) => selectedRatings.includes(e))
              .map(
                (e, index) =>
                  index >= selectedRatings.length / 2 && (
                    <div
                      className='col-md-6'
                      style={{ marginBottom: '1rem' }}
                      key={index}
                    >
                      <Histogram
                        data={referenceHistogram.data[e]}
                        index={index}
                        competitionId={competitionId}
                        playerId={firstPlayer.playerId}
                        plots={plots}
                        label={e}
                        selectedColors={selectedColors}
                      ></Histogram>
                    </div>
                  )
              )}
        </div>
      </div>
      <div className='col-md-5 mx-auto'>
        <div
          id='chart'
          ref={chartRef}
          style={{ height: '600px', width: '100%' }}
        ></div>
      </div>
      <div className='col-md-3'>
        <div className='row'>
          {selectedColors.length - 1 === plots.length &&
            !referenceHistogram.isLoading &&
            Object.keys(referenceHistogram.data)
              .filter((e) => selectedRatings.includes(e))
              .map(
                (e, index) =>
                  index < selectedRatings.length / 2 && (
                    <div
                      className='col-md-6'
                      style={{ marginBottom: '1rem' }}
                      key={index}
                    >
                      <Histogram
                        data={referenceHistogram.data[e]}
                        index={index}
                        competitionId={competitionId}
                        playerId={firstPlayer.playerId}
                        plots={plots}
                        label={e}
                        selectedColors={selectedColors}
                      ></Histogram>
                    </div>
                  )
              )}
        </div>
      </div>
    </div>
  ) : (
    <div
      id='chart'
      ref={chartRef}
      style={{ height: '100%', width: '1OO%' }}
    ></div>
  );
}

Plot.propTypes = {
  plots: PropTypes.array,
  competitionId: PropTypes.string,
  histogram: PropTypes.bool,
};

function createFakePlot(plots) {
  const fakePlot = {
    data: plots[0].data.map((elem) => ({
      ...elem,
      quantile: 0.5,
    })),
    profile: {
      ...plots[0].profile,
      playerId: FAKE_PLOT,
    },
  };
  return [...plots, fakePlot];
}

export default memo(Plot, (prevProps, props) => isEqual(prevProps, props));
