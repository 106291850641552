import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { mapToDropdown } from 'utils/helpers';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.customers,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_CUSTOMERS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_CUSTOMERS, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          customers: payload,
          dropdown: mapToDropdown(payload.data, 'name'),
        },
      };
    case getActionType(actionTypes.FETCH_CUSTOMERS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.FETCH_CUSTOMER, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
        data: state.data,
      };
    case getActionType(actionTypes.FETCH_CUSTOMER, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          customer: payload,
        },
      };
    case getActionType(actionTypes.FETCH_CUSTOMER, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.DELETE_CUSTOMERS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
        data: state.data,
      };
    case getActionType(actionTypes.DELETE_CUSTOMERS, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
      };
    case getActionType(actionTypes.DELETE_CUSTOMERS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.UPDATE_CUSTOMER, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
        data: state.data,
      };
    case getActionType(actionTypes.UPDATE_CUSTOMER, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          customers: state.data.customers.map((customer) =>
            customer.id === payload.id ? payload.data : customer
          ),
          dropdown: state.data.customers.map((customer) =>
            customer.id === payload.id ? payload.data : customer
          ),
        },
      };
    case getActionType(actionTypes.UPDATE_CUSTOMER, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.ADD_CUSTOMER, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
        data: {
          ...state.data,
        },
      };
    case getActionType(actionTypes.ADD_CUSTOMER, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          customers: [...state.data.customers, payload],
          dropdown: [...state.data.customers, payload],
        },
      };
    case getActionType(actionTypes.ADD_CUSTOMER, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    default:
      return state;
  }
}
