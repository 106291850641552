import styled from '@emotion/styled';
import { MdArrowDropDown } from 'components/icons';
import { isNil } from 'lodash-es';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import cx from 'classnames';

const Holder = styled.div`
  position: relative;
  margin: 10px 0;
`;

const BoxHeader = styled.div`
  background-color: #131d37;
  color: white;
  font-family: 'SF UI Display Medium';
  height: 60px;
  /* padding: 10px; */
`;

const BoxBody = styled.div`
  position: relative;
  min-height: 0;
  max-height: 0;
  background-color: white;
  opacity: 0;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transform: translateY(-0.5rem);
  transition-timing-function: linear, ease;
  transition: all 0.1s;
  transition-delay: 0.1s;

  &.opened {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: all 0.2s;
    min-height: 1rem;
    max-height: 100%;

    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
  }
  &.closed {
    min-height: 0 !important;
    max-height: 0 !important;
    display: none;
  }
`;
const BoxFooter = styled.div`
  height: 70px;
  background-color: #e6eaf8;
  &.opened {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: all 0.2s;
    min-height: 70px;
    max-height: 100%;

    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
  }
  &.closed {
    min-height: 0 !important;
    max-height: 0 !important;
    display: none !important;
  }
`;

const Title = styled.h3`
  align-self: center;
  padding: 0 16px;
  margin: 0;
`;
const Toggler = styled.span`
  cursor: pointer;
  font-size: 46px;
  color: white;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-90deg);
  svg {
    margin-top: -10px;
  }
  &.opened {
    transform: rotate(0deg);
  }
`;

export default class BoxHolder extends PureComponent {
  state = { opened: true };

  static propTypes = {
    children: PropTypes.any,
    toggleOpened: PropTypes.func,
    opened: PropTypes.bool,
  };

  static Header = ({
    children,
    accordion,
    _toggle,
    opened,
    className = '',
    ...rest
  }) => {
    return (
      <BoxHeader
        className={`d-flex justify-content-between align-items-center ${className}`}
        {...rest}
      >
        <div className='flex-grow-1 d-flex justify-content-between align-items-center'>
          {children}
        </div>
        {accordion && (
          <Toggler className={cx({ opened: opened })} onClick={_toggle}>
            <MdArrowDropDown color={'white'} />
          </Toggler>
        )}
      </BoxHeader>
    );
  };

  static Body = ({ children, opened, className, ...rest }) => {
    return (
      <BoxBody
        className={cx(className, {
          opened: opened,
          closed: !opened,
        })}
        {...rest}
      >
        {children}
      </BoxBody>
    );
  };

  static Title = ({ children, ...rest }) => {
    return <Title {...rest}>{children}</Title>;
  };

  static Footer = ({ className, opened, children }) => {
    return (
      <BoxFooter
        className={cx(
          'd-flex justify-content-center align-items-center',
          className,
          {
            opened: opened,
            closed: !opened,
          }
        )}
      >
        {children}
      </BoxFooter>
    );
  };

  toggle = () => this.setState((prev) => ({ ...prev, opened: !prev.opened }));

  render() {
    const { children, toggleOpened, opened, ...rest } = this.props;
    const isControlled = !isNil(toggleOpened) && !isNil(opened);
    const enhancedChildren = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        opened: isControlled ? opened : this.state.opened,
        _toggle: isControlled ? toggleOpened : this.toggle,
      })
    );
    return <Holder {...rest}>{enhancedChildren}</Holder>;
  }
}
