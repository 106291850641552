import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { mapToDropdown } from 'utils/helpers';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.applications,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_ALL_APPS, STATUSES.REQUEST):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_ALL_APPS, STATUSES.SUCCESS):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
          dropdown: mapToDropdown(payload, 'name'),
        },
      };
    case getActionType(actionTypes.FETCH_ALL_APPS, STATUSES.ERROR):
      return {
        ...state,
        all: {
          ...state.all,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    default:
      return state;
  }
}
