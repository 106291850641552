import React from 'react';
import styled from '@emotion/styled';

const Container = styled.footer`
  min-height: 30em;
  background-image: url('/media/assets/footer-bg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  @media (max-width: 425px) {
    height: 55em;
    background-size: 135% 100%;
  }

  @media (max-width: 768px) and (min-width: 426px) {
    height: 50em;
  }

  .logos {
    text-transform: uppercase;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;

    .logo {
      width: 250px;
    }
  }

  .social {
    font-family: 'SF UI Display Ultralight';
    font-size: 22px;
    margin-top: 25px;
    strong {
      font-family: 'SF UI Display Bold';
    }
    img {
      width: 25px;
      height: 25px;
      margin: 10px;
      &:nth-of-type(2) {
        width: 15px;
      }
    }
  }

  .navigation {
    margin-top: 90px;
    h4 {
      font-family: 'SF UI Display Ultralight';
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        font-family: 'SF UI Display Regular';
        ul {
          list-style: none;
          padding-left: 20px;
          li {
            font-family: 'SF UI Display Ultralight';
          }
        }

        font-weight: bold;
        &.first {
          display: inline;
          padding-top: 6px;
          border-top: 1px solid white;
        }
      }
    }
  }

  .end {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5em;
    background: #132546;
    color: white;
    padding: 25px 60px;
    display: flex;
    justify-content: space-between;
    font-family: 'SF UI Display Ultralight';
    @media (max-width: 768px) and (min-width: 426px) {
      align-items: center;
      padding: 0 20px;
    }
    @media (max-width: 425px) {
      flex-direction: column-reverse;
      height: 7em;
      align-items: center;
      padding: 0;
      justify-content: center;

      .sub-menus {
        display: flex;
        align-items: center;
        flex-direction: column;
        span {
          margin: 0 !important;
        }
      }
    }

    strong {
      text-transform: uppercase;
      font-family: 'SF UI Display Bold';
    }
  }
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: start;
    img {
      width: 100% !important;
    }
  }
`;
const Title = styled.div`
  border-left: 1px solid white;
  padding-left: 20px;
  margin-left: 25px;
  text-transform: uppercase;
  font-family: 'SF UI Display Ultralight';
  strong {
    font-family: 'SF UI Display Bold';
  }
  @media (max-width: 425px) {
    margin-left: 0;
    margin-top: 10px;
    font-size: 1.5rem;
    width: 100%;
  }
`;

function Footer() {
  return (
    <Container className={'footer'}>
      <div className='container-fluid row'>
        <div className='col-md-8 col-lg-8 col-sm-12 col-xs-12 logos'>
          <Logos>
            <img className='logo' src='/media/assets/logo-white.png' />
            <Title>
              <div>
                <strong>Artificial intelligence aiding the decision making process in football</strong>
              </div>
            </Title>
          </Logos>
          {/* <div className='social md-hidden sm-hidden xs-hidden'>
            <div>
              <strong>Follow</strong> us
            </div>
            <div>
              <img src='/media/assets/twitter.png' alt='twitter' />
              <img src='/media/assets/facebook.png' alt='twitter' />
              <img src='/media/assets/linkedin.png' alt='twitter' />
            </div>
          </div> */}
        </div>
        {/* <div className='col-md-4 col-lg-4 col-sm-12  col-xs-12 navigation'>
          <h4>Navigation</h4>
          <ul>
            <li className='first'>About us</li>
            <li>
              <span>Our products</span>
              <ul>
                {MODULES.map((module) => (
                  <li key={module}>
                    <AnchorLink
                      variant={'natural'}
                      style={{ color: 'white' }}
                      to={`/products/${kebabCase(module)}`}
                    >
                      {module}
                    </AnchorLink>
                  </li>
                ))}
              </ul>
            </li>
            <li>Contact us</li>
          </ul>
        </div> */}
        {/* <div className='social lg-hidden'>
          <div>
            <strong>Follow</strong> us
          </div>
          <div>
            <img src='/media/assets/twitter.png' alt='twitter' />
            <img src='/media/assets/facebook.png' alt='twitter' />
            <img src='/media/assets/linkedin.png' alt='twitter' />
          </div>
        </div> */}
      </div>
      <section className='end'>
        <div>
          ©️ <strong>real analytics</strong> {new Date().getFullYear()}
        </div>
        <div className='sub-menus' style={{ color: '#ccc' }}>
          {/* <span style={{ marginRight: 25 }}>Privacy policy</span>
          <span>Terms & conditions</span> */}
        </div>
      </section>
    </Container>
  );
}

export default React.memo(Footer);
