import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.pages,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.FETCH_PAGES, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_PAGES, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          pages: payload,
        },
      };
    case getActionType(actionTypes.FETCH_PAGES, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.FETCH_PAGE, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.FETCH_PAGE, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          page: payload,
        },
      };
    case getActionType(actionTypes.FETCH_PAGE, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.DELETE_PAGE, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.DELETE_PAGE, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          pages: state.data.pages.filter((page) => page.id !== payload.id),
        },
      };
    case getActionType(actionTypes.DELETE_PAGE, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.UPDATE_PAGE, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.UPDATE_PAGE, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          pages: state.data.pages.map((page) =>
            page.id === payload.id ? payload.data : page
          ),
        },
      };
    case getActionType(actionTypes.UPDATE_PAGE, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.ADD_PAGE, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.ADD_PAGE, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          pages: [...state.data.pages, payload],
        },
      };
    case getActionType(actionTypes.ADD_PAGE, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    default:
      return state;
  }
}
