import React from 'react';
import styled from '@emotion/styled';
import HomeNavbar from 'components/home/HomeNavbar';

import Footer from 'components/home/Footer';
import MainProduct from 'components/products/MainProduct';
import OtherProducts from 'components/products/OtherProducts';

const Container = styled.section`
  /* min-height: 37em; */
  background-image: url('/media/assets/section-bg1.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
`;

function Products() {
  return (
    <>
      <Container>
        <HomeNavbar />
        <MainProduct />
      </Container>
      <OtherProducts />
      <Footer />
    </>
  );
}

Products.propTypes = {};

export default React.memo(Products);
