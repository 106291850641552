import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';
import { ITEM_PER_PAGE } from 'constants/env';

const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/customers';

export const fetchCustomers = (page = 1, itemPerPage = ITEM_PER_PAGE) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.FETCH_CUSTOMERS,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${page}/${itemPerPage}`),
    },
  });
};

export const fetchCustomer = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_CUSTOMER,
    xhr: {
      req: () => Axios.get(`${BASE_URL}/${id}`),
    },
  });
};

export const deleteCustomers = (ids) => (dispatch) =>
  dispatch({
    type: actionTypes.DELETE_CUSTOMERS,
    payload: ids,
    xhr: {
      req: () => Axios.delete(`${BASE_URL}/`, { data: ids }),
      mapper: (res) => ({ data: res.data, ids }),
    },
  });

export const updateCustomer = (id, payload) => (dispatch) =>
  dispatch({
    type: actionTypes.UPDATE_CUSTOMER,
    xhr: {
      req: () => Axios.put(`${BASE_URL}/${id}`, payload),
      mapper: (res) => ({ data: res.data, id }),
    },
  });

export const addCustomer = (payload) => (dispatch) =>
  dispatch({
    type: actionTypes.ADD_CUSTOMER,
    xhr: {
      req: () => Axios.post(`${BASE_URL}/`, payload),
    },
  });
