import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

/**
 * not redux
 */
export const getApplications = async () => {
  try {
    const { data } = await Axios.get('/api/v1/applications/accessible');
    return data;
  } catch (error) {
    return [];
  }
};

export const fetchAllApps = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ALL_APPS,
    xhr: {
      req: () => Axios.get('/api/v1/applications'),
    },
  });
};
