import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { MdChevronRight } from 'components/icons';
import { AnchorLink } from 'components/styled';

const Container = styled.div`
  width: 270px;
  height: 180px;
  border-radius: 5%;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > h5 {
    text-transform: uppercase;
    font-family: 'SF UI Display Bold';
    font-size: 16px;
  }
  > p {
    font-size: 10px;
    font-family: 'SF UI Display Light';
  }
  > a {
    font-family: 'SF UI Display Regular';
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 12px;
  }

  //For mobile L 425px
  @media (max-width: 425px) and (min-width: 376px) {
    width: 260px;
  }

  //For mobile M 375px
  @media (max-width: 375px) and (min-width: 321px) {
    width: 220px;
  }

  //For mobile S 320px
  @media (max-width: 320px) {
    width: 200px;
  }
`;

function ProductBox({ title, image, text, to }) {
  return (
    <Container image={'/media/assets/app_bg.png'}>
      <div>
        <img src={image} width="40px"/>
        <h5 style={{display: 'inline', marginLeft: '2px'}}>{title}</h5>
      </div>
      <p className='truncate'>{text}</p>
      <AnchorLink variant='unstyled' to={to}>
        More <MdChevronRight fontSize={20} />
      </AnchorLink>
    </Container>
  );
}

ProductBox.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
};

export default ProductBox;
