import { AxiosRepo } from './axios';
import {
  CLIENT_ID,
  CLIENT_SECRET,
  REDIRECT_URI,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  ACCESSIBLE_APPLICATIONS_KEY,
} from 'constants/env';
import storage from './storage';

const BASE_AUTH_URL = `/oauth/v2/token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${REDIRECT_URI}`;

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return AxiosRepo.getInstance()
    .get('/api/v1/users/me')
    .then((response) => response.data)
    .catch((error) => {
      logout();
      return Promise.reject(error);
    });
}

function login({ username, password }) {

  const encodedUsername = encodeURIComponent(username);
  const encodedPassword = encodeURIComponent(password);
  return AxiosRepo.getPublicInstance()
    .get(
      `${BASE_AUTH_URL}&username=${encodedUsername}&password=${encodedPassword}&grant_type=password`
    )
    .then(handleUserResponse)
    .then(getUser);
}

function refreshToken() {
  return AxiosRepo.getPublicInstance()
    .get(
      `${BASE_AUTH_URL}&refresh_token=${storage.get(
        REFRESH_TOKEN_KEY
      )}&grant_type=refresh_token`
    )
    .then(handleUserResponse);
}

function register(user) {
  return AxiosRepo.getPublicInstance()
    .post('/api/auth/register', user)
    .then(handleUserResponse);
}

function forget(form) {
  return AxiosRepo.getPublicInstance()
    .post('/api/v1/users/forgot-password', form)
    .then(({ data }) => data);
}

function reset(form, token) {
  return AxiosRepo.getPublicInstance()
    .post(`/api/v1/users/reset-password/${token}`, form)
    .then(({ data }) => data);
}

function handleUserResponse({ data: { access_token, refresh_token } }) {
  storage.set(ACCESS_TOKEN_KEY, access_token);
  storage.set(REFRESH_TOKEN_KEY, refresh_token);
  return access_token;
}

function logout() {
  storage.remove(ACCESS_TOKEN_KEY);
  storage.remove(REFRESH_TOKEN_KEY);
  storage.remove(ACCESSIBLE_APPLICATIONS_KEY);
  return Promise.resolve();
}

function getToken() {
  return storage.get(ACCESS_TOKEN_KEY);
}

export {
  login,
  register,
  forget,
  refreshToken,
  reset,
  logout,
  getToken,
  getUser,
};
