import { useState, useCallback } from 'react';

const useToggle = (state) => {
  const [value, setValue] = useState(state);

  const toggle = useCallback(
    (nextValue) => {
      if (nextValue === true || nextValue === false) {
        setValue(nextValue);
        return;
      }

      setValue((value) => !value);
    },
    [setValue]
  );

  return [value, toggle];
};

export default useToggle;
