import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import store, { history } from 'store/createStore';
import { AuthProvider } from 'context/auth-context';
import { TeamProvider } from 'context/team-context';
import { MessageProvider } from 'context/message-context';
import { AppsProvider } from 'context/apps-context';

function AppProviders({ children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
      });
    });
    return unlisten;
  }, []);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <AppsProvider>
            <TeamProvider>
              <MessageProvider>{children}</MessageProvider>
            </TeamProvider>
          </AppsProvider>
        </AuthProvider>
      </ConnectedRouter>
    </Provider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node,
};

export default AppProviders;
