import { useHistory } from 'react-router-dom';

function useQueryHistory() {
  const history = useHistory();
  return {
    ...history,
    push(pathname, ...rest) {
      if (typeof pathname === 'string') {
        history.push(
          {
            pathname,
            search: window.location.search,
          },
          ...rest
        );
      } else {
        history.push(pathname, ...rest);
      }
    },
  };
}

export default useQueryHistory;
