import initialState from 'store/initialState';
import * as actionTypes from 'store/actionTypes';
import { getActionType, STATUSES } from 'store/middlewares/asyncActions';

export default function reducer(
  state = initialState.user,
  { type, payload, error }
) {
  switch (type) {
    case getActionType(actionTypes.EDIT_USER, STATUSES.REQUEST):
      return {
        ...state,
        edit: {
          ...state.edit,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.EDIT_USER, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.IDLE_STATUS,
        edit: {
          ...state.edit,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.EDIT_USER, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.IDLE_STATUS,

        edit: {
          ...state.edit,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };
    case getActionType(actionTypes.FETCH_NOTIFICATIONS, STATUSES.REQUEST):
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: {
            ...state.notifications.list,
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(actionTypes.FETCH_NOTIFICATIONS, STATUSES.SUCCESS): {
      const { data, ...rest } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: {
            ...state.notifications.list,
            status: actionTypes.SUCCESS_STATUS,
            noMore: payload.data.length === 0,
            data: [...state.notifications.list.data, ...data],
            ...rest,
          },
        },
      };
    }
    case getActionType(actionTypes.FETCH_NOTIFICATIONS, STATUSES.ERROR):
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: {
            ...state.notifications.list,
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_RECENT_NOTIFICATIONS,
      STATUSES.REQUEST
    ):
      return {
        ...state,
        notifications: {
          ...state.notifications,
          recent: {
            ...state.notifications.recent,
            status: actionTypes.LOADING_STATUS,
            error: null,
          },
        },
      };
    case getActionType(
      actionTypes.FETCH_RECENT_NOTIFICATIONS,
      STATUSES.SUCCESS
    ):
      return {
        ...state,
        notifications: {
          ...state.notifications,
          recent: {
            ...state.notifications.recent,
            status: actionTypes.SUCCESS_STATUS,
            noMore: payload.data.length === 0,
            data: payload.data,
          },
        },
      };
    case getActionType(actionTypes.FETCH_RECENT_NOTIFICATIONS, STATUSES.ERROR):
      return {
        ...state,
        notifications: {
          ...state.notifications,
          recent: {
            ...state.notifications.recent,
            status: actionTypes.ERROR_STATUS,
            error,
          },
        },
      };
    case actionTypes.NEW_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          recent: {
            ...state.notifications.recent,
            data: [payload, ...state.notifications.recent.data],
          },
        },
      };
    case getActionType(actionTypes.FETCH_USERS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.IDLE_STATUS,
        users: {
          ...state.users,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_USERS, STATUSES.SUCCESS):
      return {
        ...state,
        users: {
          ...state.users,
          status: actionTypes.SUCCESS_STATUS,
          data: payload,
        },
      };
    case getActionType(actionTypes.FETCH_USERS, STATUSES.ERROR):
      return {
        ...state,
        users: {
          ...state.users,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };
    case getActionType(actionTypes.FETCH_USER, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
        data: state.data,
      };
    case getActionType(actionTypes.FETCH_USER, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
        data: {
          ...state.data,
          user: payload,
        },
      };
    case getActionType(actionTypes.FETCH_USER, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.DELETE_USERS, STATUSES.REQUEST):
      return {
        ...state,
        status: actionTypes.LOADING_STATUS,
        error: null,
      };
    case getActionType(actionTypes.DELETE_USERS, STATUSES.SUCCESS):
      return {
        ...state,
        status: actionTypes.SUCCESS_STATUS,
      };
    case getActionType(actionTypes.DELETE_USERS, STATUSES.ERROR):
      return {
        ...state,
        status: actionTypes.ERROR_STATUS,
        error: error,
      };
    case getActionType(actionTypes.ADD_USER, STATUSES.REQUEST):
      return {
        ...state,
        add: {
          ...state.add,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.ADD_USER, STATUSES.SUCCESS):
      return {
        ...state,
        add: {
          ...state.add,
          status: actionTypes.SUCCESS_STATUS,
        },
      };
    case actionTypes.INVITE_USER:
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          list: [{ ...payload, pending: true }, ...state.accountUsers.list],
        },
      };
    case getActionType(actionTypes.ADD_USER, STATUSES.ERROR):
      return {
        ...state,
        add: {
          ...state.add,
          status: actionTypes.ERROR_STATUS,
          error: error,
        },
      };

    case getActionType(actionTypes.FETCH_ACCOUNT_USERS, STATUSES.REQUEST):
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          status: actionTypes.LOADING_STATUS,
          error: null,
        },
      };
    case getActionType(actionTypes.FETCH_ACCOUNT_USERS, STATUSES.SUCCESS):
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          status: actionTypes.SUCCESS_STATUS,
          list: payload,
        },
      };
    case actionTypes.DELETE_ACCOUNT_USERS:
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          list: state.accountUsers.list.filter((user) => user.id !== payload),
        },
      };
    case getActionType(actionTypes.FETCH_ACCOUNT_USERS, STATUSES.ERROR):
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          status: actionTypes.ERROR_STATUS,
          error,
        },
      };

    default:
      return state;
  }
}
