import React from 'react';
import styled from '@emotion/styled';
import { MdMenu } from 'components/icons';
import Navigation from './Navigation';
import useToggle from 'hooks/use-toggle';
import { AnchorLink } from 'components/styled';

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  color: white;
  .logo {
    height: 46px;
    img {
      width: 230px;
      //For mobile L 425px
      @media screen and (max-width: 425px) {
        width: 160px;
      }
    }
  }
`;

function HomeNavbar() {
  const [opened, toggleOpened] = useToggle(false);

  return (
    <Nav>
      <div className='logo'>
        <AnchorLink variant='natural' to='/'>
          <img src='/media/assets/logo.png' />
        </AnchorLink>
      </div>
      <div className={'navigation'}>
        <span
          className='d-flex nav-button'
          style={{ cursor: 'pointer' }}
          onClick={toggleOpened}
        >
          MENU
          <MdMenu fontSize={21} />
        </span>
        <Navigation opened={opened} toggleOpened={toggleOpened} />
      </div>
    </Nav>
  );
}

export default React.memo(HomeNavbar);
