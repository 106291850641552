import { isFunction, get } from 'lodash-es';
export const STATUSES = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const getActionType = (type, status) => `${type}_${status}`;

const asyncActions = () => (next) => async (action) => {
  if (action.xhr) {
    next({ ...action, type: getActionType(action.type, STATUSES.REQUEST) });
    try {
      const response = await action.xhr.req();
      const result = next({
        ...action,
        type: getActionType(action.type, STATUSES.SUCCESS),
        payload: action.xhr.mapper
          ? action.xhr.mapper(response)
          : response.data,
      });
      if (isFunction(action.xhr.onSuccess)) {
        action.xhr.onSuccess(response);
      }
      return result;
    } catch (error) {
      console.log(error);
      const result = next({
        ...action,
        type: getActionType(action.type, STATUSES.ERROR),
        error: {
          stack: error,
          message: get(error, 'response.data') || 'Something went wrong',
        },
      });
      if (isFunction(action.xhr.onError)) {
        action.xhr.onError(error);
      }

      return result;
    }
  } else {
    next(action);
  }
};

export default asyncActions;
