/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { ContactInput, ContactTextArea, Button } from 'components/styled';
import Recaptcha from 'reaptcha';
import { RECAPTCHA_SITE_KEY } from 'constants/env';
import ErrorMessage from '../shared/ErrorMessage';
import { Spinner } from 'components/shared';

function ContactUsForm({
  onSubmit,
  recaptchaRef,
  error,
  onVerify,
  initialValues = {},
  isLoading,
}) {
  return (
    <form onSubmit={onSubmit}>
      <div className='row'>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
          <ContactInput
            id='fullName'
            type='text'
            placeholder='Full Name'
            defaultValue={initialValues.fullName}
            required
          />
        </div>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
          <ContactInput
            id='company'
            type='text'
            placeholder='Company'
            defaultValue={initialValues.company}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
          <ContactInput
            id='email'
            type='email'
            placeholder='Email'
            defaultValue={initialValues.email}
            pattern='[^@]+@[^@]+\.[a-zA-Z]{2,6}'
            required
          />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
          <ContactInput
            id='phone'
            type='text'
            placeholder='Phone'
            defaultValue={initialValues.phone}
            required
          />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
          <ContactInput
            id='country'
            type='text'
            placeholder='Country'
            defaultValue={initialValues.country}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ContactTextArea
            id='message'
            rows='10'
            placeholder='Message'
            defaultValue={initialValues.message}
            required
          ></ContactTextArea>
        </div>
      </div>
      <div className='row'>
        <div
          className='col d-flex justify-content-center mb-2'
          style={{ height: 78 }}
        >
          <Recaptcha
            ref={recaptchaRef}
            hl={'en'}
            sitekey={RECAPTCHA_SITE_KEY}
            onVerify={onVerify}
            theme='light'
          />
        </div>
      </div>
      <div className='row d-flex justify-content-center mb-2'>
        {error ? <ErrorMessage error={{ message: error }} /> : null}
      </div>

      <div className='text-center'>
        <Button disabled={isLoading} style={{ width: 100 }}>
          Send {isLoading && <Spinner />}
        </Button>
      </div>
    </form>
  );
}

ContactUsForm.propTypes = {
  onSubmit: PropTypes.func,
  onVerify: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  recaptchaRef: PropTypes.any,
  initialValues: PropTypes.object,
};

export default React.forwardRef((props, ref) => (
  <ContactUsForm recaptchaRef={ref} {...props} />
));
