import { IDLE_STATUS } from './actionTypes';
export default {
  competitions: {
    status: IDLE_STATUS,
    data: {
      competition: null,
      accessible: [],
      dropdown: [],
    },
    all: {
      status: IDLE_STATUS,
      data: [],
    },
    byCountry: {
      status: IDLE_STATUS,
      data: [],
    },
    topLeagues: {
      status: IDLE_STATUS,
      data: [],
      dropdown: [],
    },
  },
  teams: {
    squadOverview: {
      data: {},
      status: IDLE_STATUS,
    },
    summary: {
      data: {},
      status: IDLE_STATUS,
    },
    descriptiveStats: {
      data: {},
      status: IDLE_STATUS,
    },
    latestResults: {
      data: [],
      status: IDLE_STATUS,
    },
    statusWithinLeague: {
      data: [],
      status: IDLE_STATUS,
    },
    expectedLeaguePosition: {
      data: {},
      status: IDLE_STATUS,
    },
    priorityAndVulnerability: {
      data: {},
      status: IDLE_STATUS,
    },
    latestSquad: {
      data: {},
      status: IDLE_STATUS,
      statusByTeam: {},
    },
    data: {
      teams: [],
      dropdown: [],
      dropdownMulti: {},
    },
  },
  matchs: {
    scheduledMatchs: {
      data: null,
      status: IDLE_STATUS,
    },
    match: {
      data: null,
      status: IDLE_STATUS,
    },
  },
  players: {
    profile: { data: {}, status: IDLE_STATUS },
    heatmaps: { status: IDLE_STATUS, data: {} },
    potentialEvolution: { data: {}, status: IDLE_STATUS },
    basicStatistics: { data: null, status: IDLE_STATUS },
    attributeRatings: {},
    expectedPerformance: { data: {}, status: IDLE_STATUS },
    fullRatings: {
      data: {
        ratings: {},
        rankings: {},
        quantiles: {},
        availableRatings: [],
      },
    },
    referenceHistogram: { data: {}, status: IDLE_STATUS },
    referenceQuantile: { data: {}, status: IDLE_STATUS },
    advancedSearch: { data: [], status: IDLE_STATUS },
  },
  search: {
    players: {
      data: [],
      status: IDLE_STATUS,
    },
    teams: {
      data: [],
      status: IDLE_STATUS,
    },
    leagues: {
      data: [],
      status: IDLE_STATUS,
    },
  },
  comparePlayers: {
    players: [],
    profiles: {},
    basicStatistics: {},
    referenceQuantile: {},
    potentialEvolution: {},
    expectedPerformance: {},
  },
  similarPlayers: {
    status: IDLE_STATUS,
  },
  playerImpact: {
    data: {},
    status: IDLE_STATUS,
  },
  matchPrediction: {
    simulation: {
      status: IDLE_STATUS,
      data: null,
    },
    formationByTeam: {},
    selectedPlayers: {},
    playersByIds: {},
    playersByPosition: {},
    lineup: null,
  },
  user: {
    status: IDLE_STATUS,
    data: {
      user: {},
      users: [],
    },
    add: {
      status: IDLE_STATUS,
    },
    edit: {
      status: IDLE_STATUS,
    },
    users: {
      status: IDLE_STATUS,
      data: {
        data: [],
      },
    },
    view: {
      status: IDLE_STATUS,
    },
    accountUsers: {
      status: IDLE_STATUS,
      list: [],
    },
    notifications: {
      recent: {
        status: IDLE_STATUS,
        data: [],
      },
      list: {
        status: IDLE_STATUS,
        data: [],
      },
    },
  },
  resource: {
    upload: { status: IDLE_STATUS, data: {} },
    download: { status: IDLE_STATUS, data: {} },
  },
  customers: {
    data: {
      customers: {
        data: [],
      },
      dropdown: [],
      customer: {},
    },
  },
  pages: {
    data: {
      pages: {
        data: [],
      },
      page: {},
    },
  },
  applications: {
    all: {
      status: IDLE_STATUS,
      data: [],
      dropdown: [],
    },
  },
  gbePointsCalculator: {
    pdf: {
      status: IDLE_STATUS,
    },
  },
  predictions: {
    status: IDLE_STATUS,
    data: [],
    error: null,
  },
  formations: {
    status: IDLE_STATUS,
    data: [],
    normalized: {},
    normalizedPositions: {},
    error: null,
  },
  bets: {
    registration: {
      status: IDLE_STATUS,
      data: {},
      error: null,
    },
    historyByCompetition: {
      status: IDLE_STATUS,
      data: {},
      normalized: {},
      error: null,
    },
    userBets: {
      data: [],
      status: IDLE_STATUS,
      error: null,
    },
  },
  markets: {
    status: IDLE_STATUS,
    data: [],
    error: null,
  },
  navigation: {},
};
