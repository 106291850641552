import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const launchPrediction = (query) => (dispatch) => {
  const normalizedQuery = {
    ...query,
    homeTeamSquad: query.homeTeamSquad.map(e => {
      return {
        ...e,
        position: e.position.name,
        playerName: e.name
      }
    }),
    awayTeamSquad: query.awayTeamSquad.map(e => {
      return {
        ...e,
        position: e.position.name,
        playerName: e.name
      }
    }),
  }
  dispatch({
    type: actionTypes.LAUNCH_MATCH_PREDICTION,
    xhr: {
      req: () =>
        Axios.post('/api/v1/simulations/match-prediction/launch', normalizedQuery),
      mapper: (res) => ({ simulation: res.data, query }),
    },
  });
};

export const selectPlayersToPredict = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_PLAYERS_TO_PREDICT,
    payload,
  });
};
export const changePlayerPosition = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_PLAYER_POSITION,
    payload,
  });
};
export const changeFormation = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_FORMATION,
    payload,
  });
};
export const updatePlayerPosition = (payload) => (dispatch) => {

  dispatch({
    type: actionTypes.CHANGE_PLAYER_POSITION,
    payload,
  });
};

export const resetPrediction = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_PREDICTION,
  });
};
