export const FETCH_COMPETITION = 'FETCH_COMPETITION';
export const FETCH_ACCESSIBLE_COMPETITIONS = 'FETCH_ACCESSIBLE_COMPETITIONS';
export const FETCH_COMPETITIONS = 'FETCH_COMPETITIONS';
export const FETCH_COMPETITION_BY_COUNTRY = 'FETCH_COMPETITION_BY_COUNTRY';
export const FETCH_TOP_COMPETITIONS = 'FETCH_TOP_COMPETITIONS';
export const FETCH_TEAMS = 'FETCH_TEAMS';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const DELETE_CUSTOMERS = 'DELETE_CUSTOMERS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';

export const FETCH_SUMMARY = 'FETCH_SUMMARY';
export const FETCH_DESCRIPTIVE_STATS = 'FETCH_DESCRIPTIVE_STATS';
export const FETCH_TEAM_SQUAD_OVERVIEW = 'FETCH_TEAM_SQUAD_OVERVIEW';
export const FETCH_TEAM_LATEST_SQUAD = 'FETCH_TEAM_LATEST_SQUAD';

export const FETCH_LATEST_RESULTS = 'FETCH_LATEST_RESULTS';

export const FETCH_STATUS_WITHIN_LEAGUE = 'FETCH_STATUS_WITHIN_LEAGUE';

export const FETCH_EXPECTED_LEAGUE_POSITION = 'FETCH_EXPECTED_LEAGUE_POSITION';

export const FETCH_PRIORITY_AND_VULNERABILITY_AREAS =
  'FETCH_PRIORITY_AND_VULNERABILITY_AREAS';

export const SEARCH_TEAMS = 'SEARCH_TEAMS';

export const SEARCH_LEAGUES = 'SEARCH_LEAGUES';

export const SEARCH_PLAYERS = 'SEARCH_PLAYERS';

export const SEARCH_PLAYERS_BY_TEAM = 'SEARCH_PLAYERS_BY_TEAM';

// Players
export const FETCH_PLAYER_PROFILE = 'FETCH_PLAYER_PROFILE';

export const FETCH_PLAYER_STATS = 'FETCH_PLAYER_STATS';

export const UPDATE_PLAYER_STATS = 'UPDATE_PLAYER_STATS';
export const FETCH_PLAYER_HEATMAP = 'FETCH_PLAYER_HEATMAP';

export const FETCH_EXPECTED_PERFORMANCE = 'FETCH_EXPECTED_PERFORMANCE';

export const FETCH_POTENIAL_EVOLUTION = 'FETCH_POTENIAL_EVOLUTION';

export const FETCH_PLAYER_BASIC_RATINGS = 'FETCH_PLAYER_BASIC_RATINGS';

export const FETCH_PLAYER_FULL_RATINGS = 'FETCH_PLAYER_FULL_RATINGS';

export const FETCH_PLAYER_REFERENCE_HISTOGRAM =
  'FETCH_PLAYER_REFERENCE_HISTOGRAM';

export const FETCH_REFERENCES = 'FETCH_REFERENCES';
export const FETCH_ONE_REFERENCE = 'FETCH_ONE_REFERENCE';

export const ADVANCED_PLAYER_SEARCH = 'ADVANCED_PLAYER_SEARCH';

// Teams
export const FETCH_TEAM = 'FETCH_TEAM';

export const COMPARE_PLAYERS = 'COMPARE_PLAYERS';
export const FETCH_COMPARISON_OVERVIEW = 'FETCH_COMPARISON_OVERVIEW';

export const FETCH_SIMULATION = 'FETCH_SIMULATION';

export const FETCH_SCHEDULED_MATCHS = 'FETCH_SCHEDULED_MATCHS';

export const FETCH_MATCH = 'FETCH_MATCH';

export const IDLE_STATUS = 'IDLE_STATUS';
export const LOADING_STATUS = 'LOADING_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const ERROR_STATUS = 'ERROR_STATUS';

export const LAUNCH_MATCH_PREDICTION = 'LAUNCH_MATCH_PREDICTION';
export const LAUNCH_PLAYER_IMPACT = 'LAUNCH_PLAYER_IMPACT';

export const SELECT_PLAYERS_TO_PREDICT = 'SELECT_PLAYERS_TO_PREDICT';
export const CHANGE_PLAYER_POSITION = 'CHANGE_PLAYER_POSITION';
export const CHANGE_FORMATION = 'CHANGE_FORMATION';
export const RESET_PREDICTION = 'RESET_PREDICTION';

export const FETCH_USER = 'FETCH_USER';

export const EDIT_USER = 'EDIT_USER';

export const DELETE_USERS = 'DELETE_USERS';

export const ADD_USER = 'ADD_USER';
export const INVITE_USER = 'INVITE_USER';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ACCOUNT_USERS = 'FETCH_ACCOUNT_USERS';
export const DELETE_ACCOUNT_USERS = 'DELETE_ACCOUNT_USERS';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_RECENT_NOTIFICATIONS = 'FETCH_RECENT_NOTIFICATIONS';
export const MARK_AS_READ = 'MARK_AS_READ';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

export const DOWNLOAD = 'DOWNLOAD';

export const UPLOAD = 'UPLOAD';

export const FETCH_PAGES = 'FETCH_PAGES';

export const FETCH_PAGE = 'FETCH_PAGE';

export const DELETE_PAGE = 'DELETE_PAGE';

export const UPDATE_PAGE = 'UPDATE_PAGE';

export const ADD_PAGE = 'ADD_PAGE';

export const REMOVE_FILE = 'REMOVE_FILE';

export const FETCH_ALL_APPS = 'FETCH_ALL_APPS';

export const LAUNCH_SIMILAR_PLAYERS_SIMULATION =
  'LAUNCH_SIMILAR_PLAYERS_SIMULATION';

export const FETCH_GBE_POINT_RESULT = 'FETCH_GBE_POINT_RESULT';
export const SUBMIT_GBE_POINT_FORM = 'SUBMIT_GBE_POINT_FORM';

export const FETCH_USER_BETS = 'FETCH_USER_BETS';
export const DELETE_USER_BET = 'DELETE_USER_BET';
export const SUBMIT_BET_REGISTRATION = 'SUBMIT_BET_REGISTRATION';
export const FETCH_BETS_BY_COMPETITION = 'FETCH_BETS_BY_COMPETITION';
export const FETCH_MARKETS = 'FETCH_MARKETS';
export const FETCH_FORMATIONS = 'FETCH_FORMATIONS';

export const FETCH_LATEST_1X2_PREDICTIONS = 'FETCH_LATEST_1X2_PREDICTIONS';
