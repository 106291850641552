import React, { useState, useEffect } from 'react';
import useQueryString from 'hooks/use-query-string';
import { CONTINENTS } from 'constants/regions';
const TeamContext = React.createContext();
const ActionContext = React.createContext();

function TeamProvider(props) {
  const [region, setRegion] = useQueryString('region', CONTINENTS[0].value);
  const [country, setCountry] = useQueryString('country', '');
  const [module, setModule] = useQueryString('module', '');
  const [competition, setCompetetion] = useQueryString('competition', '');

  const [state, setter] = useState({
    region,
    country,
    module,
    competition,
  });
  const [actions] = useState({
    setRegion,
    setCountry,
    setModule,
    setCompetetion,
  });

  useEffect(() => {
    setter((prev) => ({ ...prev, region, module, country, competition }));
  }, [region, country, module, competition]);

  return (
    <TeamContext.Provider value={state}>
      <ActionContext.Provider value={actions} {...props} />
    </TeamContext.Provider>
  );
}

/**
 * @returns { {
 region:String,
    country:String,
    module:String,
    competition:String,
    setRegion:Function,
    setCountry:Function,
    setModule:Function,
    setCompetetion:Function
 * }} context

 */
function useTeamSelection() {
  const context = React.useContext(TeamContext);
  const actions = React.useContext(ActionContext);
  if (context === undefined) {
    throw new Error('useTeamSelection must be used within a TeamProvider');
  }
  return { ...context, ...actions };
}

export { TeamProvider, useTeamSelection };
