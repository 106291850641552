import styled from '@emotion/styled';

export const Title = styled.h1`
  color: white;
  font-family: 'SF UI Display Bold';
  text-transform: uppercase;
  padding: 35px 0px;
`;

export const Content = styled.div`
  color: white;
  font-family: 'SF UI Display Regular';
  text-align: justify;
  ul {
    margin-top: 1rem;
    list-style: none;
    padding-left: 0;
    strong {
      font-family: 'SF UI Display Bold';
    }
    li {
      padding: 10px 0;
    }
    li:before {
      content: '';
      display: inline-block;
      height: 15px;
      width: 15px;
      vertical-align: middle;
      background-size: 65% 65%;
      background-repeat: no-repeat;
      background-image: url('/media/assets/list-item.png');
    }
  }
`;

export const Sliders = styled.div`
  height: 336px;
  //For laptop 1024px
  @media (max-width: 1024px) {
    margin-top: 15px;
  }

  .slick-dots {
    top: 110% !important;
  }

  .slick-slide {
    width: 400px !important;
  }
  .slick-slider {
    width: 400px !important;
  }
  .slick-list {
    width: 400px !important;
  }
  .slick-track {
    width: max-content !important;
  }

  //For tablet 768px
  @media (max-width: 768px) {
    .slick-slide {
      width: 300px !important;
    }
    .slick-slider {
      width: 300px !important;
    }
    .slick-list {
      width: 300px !important;
    }
  }

  //For mobile L 425px
  @media (max-width: 425px) {
    .slick-slide {
      width: 250px !important;
    }
    .slick-slider {
      width: 250px !important;
    }
    .slick-list {
      width: 250px !important;
    }
  }

  .slick-dots li button:before {
    font-size: 20px;
    color: transparent;
    border: 2px solid white;
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    color: white;
    border: none;
  }
`;
