import { useEffect } from 'react';
import { on, off } from 'utils/helpers';

const useClickAway = (elements, onClickAway) => {
  useEffect(() => {
    const handler = (event) => {
      elements.every((el) => el && !el.contains(event.target)) &&
        onClickAway(event);
    };
    on(document, 'click', handler);
    return () => {
      off(document, 'click', handler);
    };
  });
};

export default useClickAway;
