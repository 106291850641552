import { AxiosRepo } from 'utils/axios';
import * as actionTypes from 'store/actionTypes';

const Axios = AxiosRepo.getInstance();

export const fetchGbePointsResult = ({ playerId }) => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_GBE_POINT_RESULT,
    xhr: {
      req: () => Axios.get(`/api/v1/gbe/get-points/${playerId}`),
    },
    playerId,
  });
};

export const submitGbePointsForm = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SUBMIT_GBE_POINT_FORM,
    xhr: {
      req: () =>
        Axios.post('/api/v1/gbe/generate-form', data, { responseType: 'blob' }),
      onSuccess: (res) => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Application Form ${data.playerName}`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      },
    },
  });
};
