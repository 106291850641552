import { useQuery } from '@tanstack/react-query';
import { AxiosRepo } from 'utils/axios';
const Axios = AxiosRepo.getInstance();
const BASE_URL = '/api/v1/players'

export const fetchFullHistograms = (playerId, competitionId) => {
    return useQuery(['fetchHistograms'], () =>
        Axios.get(`${BASE_URL}/${playerId}/reference-histogram/${competitionId}`).then(res =>
            res.data
        ));
}