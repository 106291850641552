import React from 'react';
import { useAuth } from 'context/auth-context';
import { Switch, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Products from 'pages/Products';
import { FullPageSpinner } from 'components/shared';
const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './AuthenticatedApp')
);
const UnAuthenticatedApp = React.lazy(() =>
  import(/*webpackChunkName: 'UnAuthenticatedApp'*/ './UnAuthenticatedApp')
);

function App() {
  const { user } = useAuth();
  return (
    <Switch>
      {!user && <Route path='/' component={Home} exact />}
      <Route path='/products/:product' component={Products} exact />
      <React.Suspense fallback={<FullPageSpinner />}>
        {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
      </React.Suspense>
    </Switch>
  );
}

export default App;
